import { LegalEntity } from "@/services/legalEntity/schemas/legalEntity";
import { LegalEntityState } from "@/store/legalEntity/state";
import { MutationTree } from "vuex";
import Vue from "vue";

export enum LegalEntityMutation {
  STORE_ENTITIES = "STORE_ENTITIES",
}

export const mutations: MutationTree<LegalEntityState> = {
  STORE_ENTITIES(state: LegalEntityState, entities: LegalEntity[]): void {
    const currentEntities = Array.from(state.entities);
    const newEntities = entities.map(
      (entity) => [entity.id, entity] as [string, LegalEntity],
    );

    const entitiesMap = new Map([...newEntities, ...currentEntities]);
    Vue.set(state, "entities", entitiesMap);
  },
};
