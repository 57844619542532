const NotFound = () =>
  import(/* webpackChunkName: "notfound" */ "@/views/NotFound.vue");

export enum NotFoundRoute {
  NOT_FOUND = "NotFound",
}

export default {
  component: NotFound,
  meta: {
    requiresAuth: true,
  },
  name: NotFoundRoute.NOT_FOUND,
  path: "*",
};
