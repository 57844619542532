const ManualAnalysisPanel = () =>
  import(
    /* webpackChunkName: "ManualAnalysisPanel" */ "@/views/ManualAnalysisPanel.vue"
  );

const ManualAnalysisPanelLists = () =>
  import(
    /* webpackChunkName: "ManualAnalysisPanelLists" */ "@/views/manual-analysis-panel/ManualAnalysisPanelLists.vue"
  );

export enum ManualAnalysisPanelRoute {
  LISTS = "ManualAnalysisPanelLists",
}

export default [
  {
    children: [
      {
        component: ManualAnalysisPanelLists,
        meta: { requiresAuth: true },
        name: ManualAnalysisPanelRoute.LISTS,
        path: "",
      },
    ],
    component: ManualAnalysisPanel,
    meta: { requiresAuth: true },
    path: "/manual-analysis-panel",
  },
];
