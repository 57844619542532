import actions from "@/store/onboarding/actions";
import getters from "@/store/onboarding/getters";
import mutations from "@/store/onboarding/mutations";
import state from "@/store/onboarding/state";

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
