export enum PerimeterIdentification {
  GEOGRAPHIC_COORDINATES = "GEOGRAPHIC_COORDINATES",
  FARM_NAME_IN_PUBLIC_DATABASE = "FARM_NAME_IN_PUBLIC_DATABASE",
  FARM_REGISTER_IN_PUBLIC_DATABASE = "FARM_REGISTER_IN_PUBLIC_DATABASE",
  FARM_LAT_LNG_IN_PUBLIC_DATABASE = "FARM_LAT_LNG_IN_PUBLIC_DATABASE",
  FARM_CAR = "FARM_CAR",
  LAT_LNG_INTERSECTING_CAR = "LAT_LNG_INTERSECTING_CAR",
  GEOMETRY_DRAWN_AROUND_LAT_LNG = "GEOMETRY_DRAWN_AROUND_LAT_LNG",
  DESCRIPTIVE_MEMORIAL_AZIMUTH_DISTANCE = "DESCRIPTIVE_MEMORIAL_AZIMUTH_DISTANCE",
  DESCRIPTIVE_MEMORIAL_LAT_LNG = "DESCRIPTIVE_MEMORIAL_LAT_LNG",
  SKETCH_DESCRIPTION = "SKETCH_DESCRIPTION",
  KML_GEOMETRY = "KML_GEOMETRY",
  PROPERTY_ID_IN_PRIVATE_SIGEF = "PROPERTY_ID_IN_PRIVATE_SIGEF",
  PROPERTY_ID_IN_SIGEF_SNCI = "PROPERTY_ID_IN_SIGEF_SNCI",
  EMPTY_GEOMETRY_IN_PUBLIC_DATABASE = "EMPTY_GEOMETRY_IN_PUBLIC_DATABASE",
}

export enum PerimeterIdentificationTranslate {
  GEOGRAPHIC_COORDINATES = "Coordenada geográfica",
  FARM_NAME_IN_PUBLIC_DATABASE = "Nome da fazenda no SIGEF/INCRA/CCIR",
  FARM_REGISTER_IN_PUBLIC_DATABASE = "Número da matrícula no SIGEF/INCRA/CCIR",
  FARM_LAT_LNG_IN_PUBLIC_DATABASE = "Intersecção de ponto de latitude e longitude com o SIGEF/INCRA/CCIR",
  FARM_CAR = "Código do CAR",
  LAT_LNG_INTERSECTING_CAR = "Intersecção de ponto de latitude e longitude com o CAR",
  GEOMETRY_DRAWN_AROUND_LAT_LNG = "Desenho de talhão ao redor de ponto de latitude e longitude",
  DESCRIPTIVE_MEMORIAL_AZIMUTH_DISTANCE = "Memorial descritivo na matrícula por azimutes e distâncias",
  DESCRIPTIVE_MEMORIAL_LAT_LNG = "Memorial descritivo na matrícula por pontos de latitude e longitude",
  SKETCH_DESCRIPTION = "Descrição do croqui",
  KML_GEOMETRY = "Geometria do perímetro fornecida (.kml)",
  PROPERTY_ID_IN_PRIVATE_SIGEF = "Código do Imóvel no SIGEF Privado",
  PROPERTY_ID_IN_SIGEF_SNCI = "Código do Imóvel no SIGEF SNCI",
  EMPTY_GEOMETRY_IN_PUBLIC_DATABASE = "Geometria identificada como o “vazio” dos bancos públicos (SIGEF/INCRA e CAR) e o plantio na região",
}

// Based on https://app.swaggerhub.com/apis/TerraMagna/geo-api/0.1#/PerimeterDelimitationErrorMessages
export enum GeoJsonErrors {
  SELF_INTERSECTION = "SELF_INTERSECTION",
  AREA_OUTSIDE_PERIMETER = "AREA_OUTSIDE_PERIMETER",
  MINIMUM_AREA = "MINIMUM_AREA",
  INTERSECTION = "INTERSECTION",
  INVALID_GEOMETRY = "INVALID_GEOMETRY",
}

export enum GeoJsonErrorsTranslate {
  SELF_INTERSECTION = "A geometria contém erro de auto-interseção",
  AREA_OUTSIDE_PERIMETER = "A soma das áreas delimitadas difere da área do perímetro em mais de 2ha",
  MINIMUM_AREA = "A geometria possui área menor que a permitida (0.1ha)",
  INTERSECTION = "A geometria possui intersecções",
  INVALID_GEOMETRY = "Geometria inválida",
}
