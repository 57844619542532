export enum ImageColor {
  NDVI = "NDVI",
  RGB040302 = "RGB040302",
  RGB080403 = "RGB080403",
  RGB081104 = "RGB081104",
}

export enum ImageColorTranslate {
  NDVI = "NDVI",
  RGB040302 = "S2 MSI L2A B04-B03-B02",
  RGB080403 = "S2 MSI L2A B08-B04-B03",
  RGB081104 = "S2 MSI L2A B08-B11-B04",
}
