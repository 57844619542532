import "./plugins/vuetify";
import "@/assets/styles/application.scss";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import * as Sentry from "@sentry/vue";
import App from "./App.vue";
import Axios from "axios";
import { BrowserTracing } from "@sentry/tracing";
import moment from "moment";
import router from "./router";
import store from "./store";
import Vue from "vue";

moment.locale("pt-br");

Vue.config.productionTip = false;

Vue.prototype.$http = Axios;

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "localhost/8080",
        "backoffice.terramagna.com.br",
        "backoffice-alpha.terramagna.com.br",
        "backoffice-monitoring.terramagna.com.br",
        "backoffice-cobranca.terramagna.com.br",
        "backoffice-credito.terramagna.com.br",
        /^\//,
      ],
    }),
  ],
  tracesSampleRate: 1.0,
  trackComponents: true,
});

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
