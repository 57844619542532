import { INewUser, IUser, IUserPatch } from "@/@types/user";
import { IUsersMap, RootState, UserState } from "@/@types/vuex";

import { ActionTree } from "vuex";
import { arrayToHashMap } from "@/utils/map";
import { isObjectEmpty } from "@/utils/object";
import { UserMutation } from "./mutations";
import usersService from "@/services/users";

export enum UserAction {
  CREATE_TM_USER = "createTmUser",
  DELETE_TM_USER = "deleteTmUser",
  EDIT_TM_USER = "editTmUser",
  GET_TM_USERS = "getTmUsers",
  GET_ALL_USERS = "getAllUsers",
  GET_USER_BY_ID = "getUserById",
  SYNC_USERS_STORE = "syncUsersStore",
}

const actions: ActionTree<UserState, RootState> = {
  async [UserAction.CREATE_TM_USER]({ commit }, newUser: INewUser): Promise<IUser> {
    const user = await usersService.createUser(newUser);
    commit(UserMutation.ADD_TM_USER, user);
    return user;
  },
  async [UserAction.DELETE_TM_USER]({ state, commit }, { userId }): Promise<void> {
    if (!state.tmUsers[userId]) return;
    await usersService.deleteUser(userId);
    commit(UserMutation.DELETE_USER, userId);
  },
  async [UserAction.EDIT_TM_USER](
    { state, commit },
    patch: IUserPatch,
  ): Promise<IUser | undefined> {
    if (!state.tmUsers[patch.id]) return;
    const updatedUser = await usersService.editUser(patch.id, patch);
    commit(UserMutation.ADD_TM_USER, updatedUser);
    return updatedUser;
  },
  async [UserAction.GET_ALL_USERS]({ state, dispatch }): Promise<IUsersMap> {
    if (!isObjectEmpty(state.allUsers)) return state.allUsers;
    return await dispatch(UserAction.SYNC_USERS_STORE);
  },
  async [UserAction.GET_TM_USERS]({ state, commit }): Promise<IUsersMap> {
    if (!isObjectEmpty(state.tmUsers)) return state.tmUsers;
    // TODO: use state.allUsers to get only the TM clients when we refac
    //   the login state.
    const tmUsers = arrayToHashMap("id", await usersService.getTmUsers());
    commit(UserMutation.SET_TM_USERS, tmUsers);

    return tmUsers;
  },
  async [UserAction.SYNC_USERS_STORE]({ commit }): Promise<IUsersMap> {
    const users = await usersService.getAllUsers();
    const usersMap = arrayToHashMap("id", users);
    commit(UserMutation.SET_ALL_USERS, usersMap);
    return usersMap;
  },
  async [UserAction.GET_USER_BY_ID](
    { dispatch },
    userId: string,
  ): Promise<IUser | undefined> {
    const usersMap = await dispatch(UserAction.GET_ALL_USERS);
    return usersMap[userId];
  },
};

export default actions;
