import { enumCssColors, enumCssColorsRgb } from "@/utils/color";

// https://app.swaggerhub.com/apis/TerraMagna/onboarding-service/1.1#/RequestStatus
export enum RequestStatus {
  CANCELLED = "CANCELLED",
  IN_ANALYSIS = "IN_ANALYSIS",
  DONE = "DONE",
  REFUSED = "REFUSED",
  PARTIALLY_REFUSED = "PARTIALLY_REFUSED",
  REVIEWED = "REVIEWED",
  PENDING = "PENDING",
  PRE_APPROVED = "PRE_APPROVED",
  NOT_APPROVED = "NOT_APPROVED",
  IN_PROGRESS = "IN_PROGRESS",
  APPROVED = "APPROVED",
  APPROVED_NEW = "APPROVED_NEW",
  DISREGARDED = "DISREGARDED",
  NOT_DEFINED = "NOT_DEFINED",
  TO_BE_ANALYSED = "TO_BE_ANALYSED",
  // Front-end only status
  EXPIRED = "EXPIRED",
  FARMS_NOT_FOUND = "FARMS_NOT_FOUND",
  FARMS_FOUND = "FARMS_FOUND",
  OPEN = "OPEN",
  FINISHED = "FINISHED",
}

// Front-end RequestStatus translations
export enum RequestStatusTranslate {
  CANCELLED = "Cancelada",
  IN_ANALYSIS = "Em análise",
  DONE = "Concluída",
  REFUSED = "Recusada",
  PARTIALLY_REFUSED = "Com pendência",
  REVIEWED = "Revisada",
  PENDING = "Pendente",
  PRE_APPROVED = "Elegível",
  NOT_APPROVED = "Não elegível",
  IN_PROGRESS = "Em andamento",
  APPROVED = "Elegível",
  APPROVED_NEW = "Elegível (Novo)",
  DISREGARDED = "Desconsiderado",
  DISCARDED = "Desconsiderado",
  ANALYZING_DOCS = "Analisando documentação",
  NOT_DEFINED = "Não definido",
  TO_BE_ANALYSED = "A analisar",
  // Front-end only status
  EXPIRED = "Vencido",
  FARMS_NOT_FOUND = "Sem fazendas vinculadas",
  FARMS_FOUND = "Fazenda(s) encontrada(s)",
  OPEN = "Aberta",
  FINISHED = "Finalizada",
}

export enum RequestStatusColor {
  CANCELLED = "#CCCCCC",
  IN_ANALYSIS = "#277CCA",
  DONE = "#84A855",
  REVIEWED = "#84A855",
  REFUSED = "#E55B20",
  PARTIALLY_REFUSED = "#FF8C42",
  PENDING = "#F0C324",
  PRE_APPROVED = "#84A855",
  NOT_APPROVED = "#E55B20",
  IN_PROGRESS = "#277CCA",
  APPROVED = "#84A855",
  APPROVED_NEW = "#84A855",
  DISREGARDED = "#CCCCCC",
  NOT_DEFINED = "#E55B20",
  TO_BE_ANALYSED = "#F0C324",
  // Front-end only status
  EXPIRED = "#CCCCCC",
  FARMS_NOT_FOUND = "#F0C324",
  FARMS_FOUND = "#CCCCCC",
  OPEN = "#84A855",
  FINISHED = "#e57373",
}

export const CSS_STYLE_REQUEST_STATUS_COLORS: string =
  enumCssColors(RequestStatus, RequestStatusColor, "request-status") +
  enumCssColorsRgb(RequestStatus, RequestStatusColor, "request-status");
