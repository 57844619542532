export enum RiskType {
  DEVELOPMENT_FAILURE = "DEVELOPMENT_FAILURE",
  FLOODED_AREA = "FLOODED_AREA",
  GREEN_AREA = "GREEN_AREA",
  FIRES = "FIRES",
  FROST = "FROST",
}

export enum RiskTypeTranslate {
  DEVELOPMENT_FAILURE = "Falha de Desenvolvimento",
  FLOODED_AREA = "Área Alagada",
  GREEN_AREA = "Colheita Verde",
  FIRES = "Queimada",
  FROST = "Geada",
}
