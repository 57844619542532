import {
  Client,
  IUpdateClientOptionsPayload,
  IUpdateClientPayload,
} from "@/@types/client";
import clientsService, { ClientOptions } from "@/services/clients";
import { ClientState, IClientsMap, RootState } from "@/@types/vuex";

import { ActionTree } from "vuex";
import { arrayToHashMap } from "@/utils/map";
import { ClientMutation } from "./mutations";

export enum ClientAction {
  ACTIVATE_CLIENT = "activateClient",
  CREATE_CLIENT = "createClient",
  DEACTIVATE_CLIENT = "deactivateClient",
  GET_CLIENTS = "getClients",
  GET_CLIENT_BY_DATA = "getClientByData",
  GET_CLIENT_BY_ID = "getClientById",
  GET_CLIENT_BY_NAME = "getClientByName",
  SYNC_CLIENTS_STORE = "syncClientsStore",
}

const actions: ActionTree<ClientState, RootState> = {
  activateClient(_, payload: string): Promise<boolean> {
    return clientsService.activateClient(payload);
  },
  async createClient(
    { state: { clients }, dispatch, commit },
    payload: Client,
  ): Promise<Client> {
    const client = await clientsService.createClient(payload);
    const { cnpj, id, pretty_name } = client;
    await clientsService.createClientInRegistry({ cnpj, id, name: pretty_name });
    const clientMap = arrayToHashMap("id", [client]);
    const clientsMap = { ...clients, ...clientMap };
    commit(ClientMutation.SET_CLIENTS, clientsMap);
    await dispatch(ClientAction.GET_CLIENTS);
    return client;
  },
  deactivateClient(_, payload: string): Promise<boolean> {
    return clientsService.deactivateClient(payload);
  },
  async getClientByData(
    { dispatch },
    { clientId, clientName }: { clientId?: string; clientName?: string },
  ): Promise<Client | undefined> {
    if (clientId) return await dispatch(ClientAction.GET_CLIENT_BY_ID, clientId);
    if (clientName) return await dispatch(ClientAction.GET_CLIENT_BY_NAME, clientName);
    return undefined;
  },
  async getClientById(
    { state: { clients }, dispatch },
    clientId: string,
  ): Promise<Client | undefined> {
    if (clients[clientId]) return clients[clientId];
    const clientsMap: IClientsMap = await dispatch(ClientAction.GET_CLIENTS);
    return clientsMap[clientId];
  },
  async getClientByName(
    { state: { clients }, dispatch },
    clientName: string,
  ): Promise<Client | undefined> {
    const clientByName = ({ name }: Client) => name === clientName;

    let clientsList = Object.values(clients);
    const foundClient = clientsList.find(clientByName);
    if (foundClient) return foundClient;

    const clientsMap = await dispatch(ClientAction.GET_CLIENTS);
    clientsList = Object.values(clientsMap);
    return clientsList.find(clientByName);
  },
  async getClientOptions(_, clientId: string): Promise<ClientOptions> {
    return clientsService.getClientOptions(clientId);
  },
  getClientToken(_, clientId: string): Promise<string> {
    return clientsService.getClientToken(clientId);
  },
  async getClients({ state, dispatch }): Promise<IClientsMap> {
    if (Object.keys(state.clients).length) return state.clients;
    return await dispatch(ClientAction.SYNC_CLIENTS_STORE);
  },
  async syncClientsStore({ commit }): Promise<IClientsMap> {
    const clients = await clientsService.getClients();
    const clientsMap = arrayToHashMap("id", clients);
    commit(ClientMutation.SET_CLIENTS, clientsMap);
    return clientsMap;
  },
  async updateClient(
    { state: { clients }, dispatch, commit },
    payload: IUpdateClientPayload,
  ): Promise<void> {
    const client = await clientsService.updateClient(payload.clientId, {
      cnpj: payload.cnpj,
      name: payload.name,
    });
    const clientMap = arrayToHashMap("id", [client]);
    const clientsMap = { ...clients, ...clientMap };
    await commit(ClientMutation.SET_CLIENTS, clientsMap);
    await dispatch(ClientAction.GET_CLIENTS);
  },
  async updateClientOptions(_, payload: IUpdateClientOptionsPayload): Promise<void> {
    await clientsService.updateClientOptions(payload.clientId, payload.options);
  },
};

export default actions;
