import { IUsersMap, UserState } from "@/@types/vuex";
import { IUser } from "@/@types/user";

import { MutationTree } from "vuex";
import Vue from "vue";

export enum UserMutation {
  ADD_TM_USER = "ADD_TM_USER",
  DELETE_USER = "DELETE_USER",
  SET_ALL_USERS = "SET_ALL_USERS",
  SET_TM_USERS = "SET_TM_USERS",
}

const mutations: MutationTree<UserState> = {
  [UserMutation.ADD_TM_USER](state: UserState, user: IUser) {
    Vue.set(state.allUsers, user.id, user);
    Vue.set(state.tmUsers, user.id, user);
  },
  [UserMutation.DELETE_USER](state: UserState, userId: string) {
    if (state.allUsers[userId]) Vue.delete(state.allUsers, userId);
    if (state.tmUsers[userId]) Vue.delete(state.tmUsers, userId);
  },
  [UserMutation.SET_ALL_USERS](state: UserState, users: IUsersMap) {
    state.allUsers = users;
  },
  [UserMutation.SET_TM_USERS](state: UserState, users: IUsersMap) {
    state.tmUsers = users;
  },
};

export default mutations;
