import * as turf from "@turf/turf";
import axios from "axios";
import { FeatureCollection } from "@/@types/geoJson";
import { getImagesDateRange } from "@/utils/date";
import { getImageServiceEndpoint } from "@/utils/endpoint";
import { ImageColor } from "@/constants/manual-analysis/image";
import { ISeason } from "@/@types/onboarding/shared";
import { ITaskImages } from "@/@types/manualAnalysis/analysis";

const imageService = {
  async fetchImages(
    geojson: FeatureCollection,
    season: ISeason,
  ): Promise<ITaskImages[]> {
    const bbox = turf.bbox(geojson);
    const datesList = getImagesDateRange(season);

    const endpoint = getImageServiceEndpoint(bbox, datesList);

    const response = await axios.get<{ images: ITaskImages[] }>(endpoint);

    // TODO US-7612: enable NDVI again
    const adaptedResponse = response.data.images.filter(
      (resp) => resp.composition !== ImageColor.NDVI,
    );

    return adaptedResponse;
  },
};

export default imageService;
