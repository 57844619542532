import { ClientState, IClientsMap } from "@/@types/vuex";
import { MutationTree } from "vuex";
import Vue from "vue";

export enum ClientMutation {
  SET_CLIENTS = "SET_CLIENTS",
}

const mutations: MutationTree<ClientState> = {
  SET_CLIENTS(state: ClientState, clientsMap: IClientsMap) {
    Vue.set(state, "clients", clientsMap);
  },
};

export default mutations;
