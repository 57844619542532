const ManualAnalysis = () =>
  import(/* webpackChunkName: "ManualAnalysis" */ "@/views/ManualAnalysis.vue");

const ManualAnalysisResultsPage = () =>
  import(
    /* webpackChunkName: "ManualAnalysisResultsPage" */ "@/views/manual-analysis/ManualAnalysisPage.vue"
  );

const ManualAnalysisDelimitationPage = () =>
  import(
    /* webpackChunkName: "ManualAnalysisDelimitationPage" */ "@/views/manual-analysis/ManualAnalysisDelimitationPage.vue"
  );

export enum ManualAnalysisRoute {
  RESULTS = "ManualAnalysisResultsPage",
  DELIMITATION = "ManualAnalysisDelimitationPage",
  BASE = "ManualAnalysis",
}

export default [
  {
    children: [
      {
        component: ManualAnalysisResultsPage,
        meta: { requiresAuth: true },
        name: ManualAnalysisRoute.RESULTS,
        path: "results/:taskId?",
      },
      {
        component: ManualAnalysisDelimitationPage,
        meta: { requiresAuth: true },
        name: ManualAnalysisRoute.DELIMITATION,
        path: "delimitation/:taskId?",
      },
    ],
    component: ManualAnalysis,
    meta: { requiresAuth: true },
    name: ManualAnalysisRoute.BASE,
    path: "/manual-analysis",
  },
];
