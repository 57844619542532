import { GetterTree } from "vuex";
import { IPreFinRequestFormatted } from "@/@types/onboarding/preFinancing";
import { PrefinState } from "@/store/prefin/state";
import { RootState } from "@/@types/vuex";

export const getters: GetterTree<PrefinState, RootState> = {
  getAnalysisRequests: (state: PrefinState): IPreFinRequestFormatted[] =>
    state.requests,
  getRequestById:
    (state: PrefinState) =>
    (requestId: string): IPreFinRequestFormatted | undefined => {
      return state.requests.find(({ id }) => id == requestId);
    },
  getSelectedRequest: (state: PrefinState): IPreFinRequestFormatted | undefined =>
    state.selectedRequest,
  getTotal: (state: PrefinState): number => state.total,
};
