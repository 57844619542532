import { IOperationsMap, OperationState, RootState } from "@/@types/vuex";

import { ActionTree } from "vuex";
import { arrayToHashMap } from "@/utils/map";
import { IMonitoringOperation } from "@/@types/operation";
import { IOperation } from "@/@types/onboarding/operation";
import { isObjectEmpty } from "@/utils/object";
import onboardingService from "@/services/onboarding";
import { OperationMutation } from "./mutations";
import operationsService from "@/services/operations";

const actions: ActionTree<OperationState, RootState> = {
  async getMonitoringOperationById(
    _,
    operationId: number,
  ): Promise<IMonitoringOperation> {
    return await operationsService.getOperationById(operationId);
  },
  async getOperationById({ dispatch, state }, id: string): Promise<IOperation> {
    return state.operations[id] || (await dispatch("getOperations", [id]))[id];
  },
  async getOperations(
    { commit, state },
    operationIds?: string[],
  ): Promise<IOperationsMap> {
    const idsToGet = operationIds?.filter((id) => !state.operations[id]);
    if (idsToGet?.length || isObjectEmpty(state.operations)) {
      const { operations } = await onboardingService.getOperations({ ids: idsToGet });
      commit(OperationMutation.SET_OPERATIONS, arrayToHashMap("id", operations));
    }

    if (operationIds?.length) {
      const operationsMap: IOperationsMap = {};
      for (const id of operationIds) {
        operationsMap[id] = state.operations[id];
      }
      return operationsMap;
    }

    return state.operations;
  },
  async getOperationsByDocumentNumber(
    { state },
    requestedDocumentNumber: string,
  ): Promise<IOperation[]> {
    const stateOperations = Object.values(state.operations);
    const foundOperations = stateOperations.filter(
      ({ documentNumber }) => documentNumber === requestedDocumentNumber,
    );

    if (foundOperations.length) return foundOperations;

    const { operations } = await onboardingService.getOperations({
      documentNumber: requestedDocumentNumber,
    });

    return operations;
  },
  async updateOperationById({ commit, state }, partialOperation: Partial<IOperation>) {
    commit(OperationMutation.SET_OPERATION, {
      ...state.operations[partialOperation.id!],
      ...partialOperation,
    });
  },
};

export default actions;
