import { IFarm, IFarmGeometries } from "@/@types/monitoring";
import axios from "axios";
import { Geometry } from "@/@types/geoJson";
import { IMonitoringOperation } from "@/@types/operation";

const API = process.env.VUE_APP_MONITORING_SERVICE_API;
/// https://app.swaggerhub.com/apis/TerraMagna/monitoring-service/v3.2#/
export interface IFarmGeometryPayload {
  id: string;
  cartography_id: number;
  perimeter: Geometry;
}

const monitoringService = {
  // https://app.swaggerhub.com/apis/TerraMagna/monitoring-service/v3.2#/Territories/get_territories__territory_
  async getFarmById(farmId: number): Promise<IFarm> {
    const response = await axios.get<IFarm>(`${API}/internal/territories/${farmId}`);

    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/monitoring-service/v3.2#/Territories/geometries
  async getGeometriesByFarmId(farmId: number): Promise<IFarmGeometries> {
    const endpoint = `${API}/internal/territories/${farmId}/geometries`;
    const response = await axios.get<IFarmGeometries>(endpoint);

    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/monitoring-service/v3.2#/Operations/getOperation
  async getOperationById(operationId: number): Promise<IMonitoringOperation> {
    const endpoint = `${API}/internal/operations/${operationId}`;
    const response = await axios.get<IMonitoringOperation>(endpoint);

    return response.data;
  },
};

export default monitoringService;
