import actions from "@/store/operation/actions";
import mutations from "@/store/operation/mutations";
import state from "@/store/operation/state";

export default {
  actions,
  mutations,
  namespaced: true,
  state,
};
