import axiosRetry, {
  exponentialDelay,
  isNetworkError,
  isSafeRequestError,
} from "axios-retry";
import axios from "axios";
import store from "@/store";

export function requestInterceptor() {
  axiosRetry(axios, {
    retries: 5,
    retryCondition: (err) => isSafeRequestError(err) || isNetworkError(err),
    retryDelay: exponentialDelay,
  });

  axios.interceptors.request.use(
    (config) => {
      store.commit("LOADING", null, { root: true });
      const token = store.getters["login/token"];
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      return config;
    },
    (err) => {
      store.commit("LOADED", null, { root: true });
      return Promise.reject(err);
    },
  );
}

export function responseInterceptor(notAuthorizedCallback: () => void) {
  axios.interceptors.response.use(
    (response) => {
      store.commit("LOADED", null, { root: true });
      return response;
    },
    (error) => {
      store.commit("LOADED", null, { root: true });
      if (error.response.status === 401 && error.config) {
        notAuthorizedCallback();
      }
      return Promise.reject(error);
    },
  );
}
