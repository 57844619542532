import {
  FileMetadataPayload,
  FilesMetadataPayload,
  IFile,
  IFileDownload,
} from "@/@types/files";

import axios from "axios";
import { getFulfilledPromiseValues } from "@/utils/promise";

const API_URL = `${process.env.VUE_APP_FILES_API}`;

// TODO: Redesign the implementations to reuse code when dealing with and without clientId
const fileService = {
  async downloadFile(file: IFile): Promise<IFileDownload> {
    const { id: fileId, name: filename } = file;
    const fileDownload: IFileDownload = { fileId, filename, link: "" };

    try {
      const blob = await this.downloadFileBlobByClientId(file);
      fileDownload.link = window.URL.createObjectURL(blob);
    } finally {
      return fileDownload;
    }
  },

  async downloadFileBlobByClientId(file: IFile, clientId?: string): Promise<Blob> {
    const baseUrl = clientId ? `${API_URL}/internal/${clientId}` : API_URL;
    const endpoint = `${baseUrl}/files/${file.id}:download`;
    const response = await axios.get<Blob>(endpoint, {
      responseType: "blob",
    });

    return response.data;
  },

  async downloadFileByClientId(file: IFile, clientId: string): Promise<IFileDownload> {
    const { id: fileId, name: filename } = file;
    const fileDownload: IFileDownload = { fileId, filename, link: "" };

    try {
      const blob = await this.downloadFileBlobByClientId(file, clientId);
      fileDownload.link = window.URL.createObjectURL(blob);
    } finally {
      return fileDownload;
    }
  },

  async downloadFilesZip(
    filename: string,
    filesId: string[],
    clientId?: string,
  ): Promise<IFileDownload> {
    const endpoint = clientId
      ? `${API_URL}/internal/${clientId}/files:zip`
      : `${API_URL}/files:zip`;

    return this.downloadZip(endpoint, filesId, filename);
  },

  async downloadZip(
    endpoint: string,
    filesId: string[],
    filename: string,
  ): Promise<IFileDownload> {
    const fileDownload: IFileDownload = {
      fileId: filename,
      filename,
      link: "",
    };
    const payload = {
      files: filesId,
      name: `${filename}`,
    };

    const { data } = await axios.post<Blob>(endpoint, payload, {
      responseType: "arraybuffer",
    });
    try {
      fileDownload.link = window.URL.createObjectURL(
        new Blob([data], { type: "application/zip" }),
      );

      return fileDownload;
    } catch (_) {
      return Promise.reject();
    }
  },

  async getFileInfo(fileId: string): Promise<IFile> {
    const response = await axios.get<IFile>(`${API_URL}/files/${fileId}`);
    return response.data;
  },

  async getFileInfoByClientId(
    fileMetadataPayload: FileMetadataPayload,
  ): Promise<IFile> {
    const { clientId, fileId } = fileMetadataPayload;
    const endpoint = `${API_URL}/internal/${clientId}/files/${fileId}`;
    const response = await axios.get<IFile>(endpoint);

    return response.data;
  },

  async getFilesInfo(fileIds: string[]): Promise<IFile[]> {
    if (!fileIds.length) return [];

    const filesMetadataPromises = fileIds.map(this.getFileInfo);

    const settledPromises = await Promise.allSettled(filesMetadataPromises);
    const filesMetadata: IFile[] = getFulfilledPromiseValues(settledPromises);

    return filesMetadata.filter(Boolean);
  },

  async getFilesInfoByClientId(
    fileMetadataPayload: FilesMetadataPayload,
  ): Promise<IFile[]> {
    const { clientId, fileIds } = fileMetadataPayload;
    if (!fileIds.length) return [];

    const filesMetadataPromises = fileIds.map((fileId) =>
      this.getFileInfoByClientId({ clientId, fileId }),
    );

    const settledPromises = await Promise.allSettled(filesMetadataPromises);
    const filesMetadata: IFile[] = getFulfilledPromiseValues(settledPromises);

    return filesMetadata.filter(Boolean);
  },
};

export default fileService;
