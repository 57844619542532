import { IPreFinRequestFormatted } from "@/@types/onboarding/preFinancing";

export type PrefinState = {
  requests: IPreFinRequestFormatted[];
  selectedRequest?: IPreFinRequestFormatted;
  total: number;
};

export const state: PrefinState = {
  requests: [],
  selectedRequest: undefined,
  total: 0,
};
