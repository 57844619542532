import axios, { AxiosError, AxiosResponse } from "axios";
import {
  ICreateUpdateDeleteFarmRequestsPayload,
  IFarmRequest,
  IFarmRequestPage,
  IFarmRequestResponse,
  IGetOperationArgsProps,
  IOperationPage,
  IOperationRegister,
  IOperationRegisterCreatePayload,
  IOperationRegisterParams,
} from "@/@types/onboarding/operation";
import {
  IGeometryReuseFilteredFarms,
  IGeometryReuseSearchInput,
  ISearchValueData,
} from "@/@types/manualAnalysis/geometryReuse";
import {
  IMunicipalities,
  IRefusalPage,
  IRequestCancellations,
  IRequestPage,
  IRequestsViewPage,
  OnboardingRegister,
  OnboardingRequest,
  StandResponse,
} from "@/@types/onboarding/shared";
import {
  OperationCancelReason,
  PreFinCancelReason,
} from "@/constants/shared/cancelReason";
import { HTTP_STATUS_CODE } from "@/@types/service/http";
import { IPreFinRequest } from "@/@types/onboarding/preFinancing";
import { RequestStatus } from "@/constants/shared/requestStatus";
import { RequestType } from "@/constants/onboarding/requests";
import { State } from "@/constants/shared/location";

const { VUE_APP_ONBOARDING_API } = process.env;

const onboardingService = {
  // https://app.swaggerhub.com/apis/TerraMagna/onboarding-service/1.7#/Internal/cancelRequest
  async cancelRequest(
    requestId: string,
    reasons: (OperationCancelReason | PreFinCancelReason)[],
  ): Promise<IOperationRegister | IPreFinRequest> {
    const endpoint = `${VUE_APP_ONBOARDING_API}/internal/requests/${requestId}:cancel`;
    const response = await axios.post<IOperationRegister | IPreFinRequest>(endpoint, {
      reasons,
    });

    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/onboarding-service/1.7#/Internal/registerRequest
  async createOperationRegister(
    requestId: string,
    register: IOperationRegisterCreatePayload,
  ): Promise<IOperationRegister> {
    const endpoint = `${VUE_APP_ONBOARDING_API}/internal/requests/${requestId}`;
    const response = await axios.post<IOperationRegister>(endpoint, register);

    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/onboarding-service/1.7#/Requests/createUpdateDeleteFarmRequests
  async createUpdateDeleteFarmRequests(
    operationId: string,
    payload: ICreateUpdateDeleteFarmRequestsPayload,
  ): Promise<IFarmRequestResponse> {
    const endpoint = `${VUE_APP_ONBOARDING_API}/internal/${operationId}/farmRequests`;
    const response = await axios.post<IFarmRequestResponse>(endpoint, payload);

    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/onboarding-service/1.7#/Operations/updateOperation
  async editOperationRegister(
    operationId: string,
    register: IOperationRegisterCreatePayload,
  ): Promise<IOperationRegister> {
    const endpoint = `${VUE_APP_ONBOARDING_API}/internal/operations/${operationId}`;
    const response = await axios.put<IOperationRegister>(endpoint, register);
    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/onboarding-service/1.7#/Requests/getFarmRequests
  async getFarmRequests(requestId: string): Promise<IFarmRequestPage> {
    const endpoint = `${VUE_APP_ONBOARDING_API}/internal/requests/${requestId}/farmRequests`;
    const response = await axios.get<IFarmRequestPage>(endpoint);
    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/onboarding-service/1.7#/Farm%20Request/getFarmRequestById
  async getFarmRequestsById(farmRequestId: string): Promise<IFarmRequest> {
    const endpoint = `${VUE_APP_ONBOARDING_API}/internal/farmRequests/${farmRequestId}`;
    const response = await axios.get<IFarmRequest>(endpoint);

    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/onboarding-service/1.7#/Farm/listFarmStands
  async getFarmStands(farmId: string, date?: Date): Promise<StandResponse> {
    const endpoint = new URL(`${VUE_APP_ONBOARDING_API}/farms/${farmId}/stands`);

    if (date) endpoint.searchParams.set("deletedAfter", date.toISOString());
    const response = await axios.get<StandResponse>(endpoint.href);

    return response.data;
  },

  async getFilteredFarms(
    params: ISearchValueData,
  ): Promise<IGeometryReuseSearchInput[]> {
    try {
      const endpoint = `${VUE_APP_ONBOARDING_API}/views/existentFarms:search`;
      const response: AxiosResponse<IGeometryReuseFilteredFarms> = await axios.get(
        endpoint,
        { params },
      );

      return response.data.farms;
    } catch (error) {
      if ((error as AxiosError).response?.status === HTTP_STATUS_CODE.NOT_FOUND) {
        return [];
      }

      throw error;
    }
  },

  // https://app.swaggerhub.com/apis/TerraMagna/onboarding-service/1.7#/Municipalities/getMunicipalities
  async getMunicipalities(state: State): Promise<IMunicipalities> {
    const endpoint = `${VUE_APP_ONBOARDING_API}/municipalities`;
    const response = await axios.get<IMunicipalities>(endpoint, {
      params: {
        state,
      },
    });

    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/onboarding-service/1.7#/Requests/getInternalCancellations
  async getOperationCancelReasons(requestId: string): Promise<IRequestCancellations> {
    const endpoint = `${VUE_APP_ONBOARDING_API}/internal/requests/${requestId}/cancellations`;
    const response = await axios.get<IRequestCancellations>(endpoint);

    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/onboarding-service/2.5#/Operations/listRequest
  async getOperations({
    ids,
    clientId,
    documentNumber: number,
  }: IOperationRegisterParams): Promise<IOperationPage> {
    const endpoint = `${VUE_APP_ONBOARDING_API}/internal/operations`;
    const data = {
      ...(ids?.length && { ids: ids?.join(",") }),
      ...(!!clientId && { clientId }),
      ...(!!number && { number }),
    };
    const response = await axios.post<IOperationPage>(endpoint, data);

    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/onboarding-service/1.7#/Internal/getRequestByIdInternal
  async getRequest(requestId: string): Promise<OnboardingRequest | OnboardingRegister> {
    const endpoint = `${VUE_APP_ONBOARDING_API}/requests/${requestId}`;
    const response = await axios.get<OnboardingRequest | OnboardingRegister>(endpoint);

    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/onboarding-service/2.2#/Requests/getRefusals
  async getRequestRefusals(requestId: string): Promise<IRefusalPage> {
    const endpoint = `${VUE_APP_ONBOARDING_API}/requests/${requestId}/refusals`;
    const response = await axios.get<IRefusalPage>(endpoint);

    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/onboarding-service/1.7#/Internal/listAllRequests
  async getRequests(params: {
    type: RequestType;
    status?: RequestStatus[];
  }): Promise<IRequestPage> {
    const endpoint = `${VUE_APP_ONBOARDING_API}/internal/requests`;
    const { data } = await axios.get<IRequestPage>(endpoint, {
      params: {
        status: params.status?.join(",") || null,
        type: params.type,
      },
    });

    return data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/onboarding-service/2.7#/Internal/requestsPageView
  async getRequestsViewPage(data: IGetOperationArgsProps): Promise<IRequestsViewPage> {
    const params = setParams(data);
    const { url } = params;

    const callRequestsAction = async (
      url: string,
      params?: IGetOperationArgsProps,
    ): Promise<AxiosResponse<IRequestsViewPage>> => {
      const endpoint = `${VUE_APP_ONBOARDING_API}${url}`;
      return await axios.get<IRequestsViewPage>(endpoint, { params });
    };

    const response = url
      ? await callRequestsAction(url)
      : await callRequestsAction("/views/requestsPage", params);

    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/onboarding-service/1.7#/Internal/patchRequest
  async patchRequest(
    requestId: string,
    data: { pipefyId?: string; attachments?: string[] },
  ): Promise<OnboardingRegister> {
    const endpoint = `${VUE_APP_ONBOARDING_API}/internal/requests/${requestId}`;
    const response = await axios.patch<OnboardingRegister>(endpoint, data);

    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/onboarding-service/1.7#/Internal/setFarmRequestAsCancelled
  async setFarmRequestAsCancelled(farmRequestId: string): Promise<IFarmRequest> {
    const endpoint = `${VUE_APP_ONBOARDING_API}/internal/farmRequests/${farmRequestId}:cancel`;
    const response = await axios.post<IFarmRequest>(endpoint);

    return response.data;
  },
};

const setParams = (data: IGetOperationArgsProps): IGetOperationArgsProps => {
  let params: IGetOperationArgsProps = {
    from: data.from,
    until: data.until,
  };

  if (data) {
    const { rowsPerPage } = data;

    params = {
      ...data,
      size: rowsPerPage,
    };
  }

  return params;
};

export default onboardingService;
