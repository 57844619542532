import { SnackbarPayload } from "@/@types/snackbar";
import Vue from "vue";

export default new Vue({
  methods: {
    // eslint-disable-next-line vue/no-unused-properties
    showErrorSnackbar(text: string, timeout?: number) {
      this.$emit("SHOW_ERROR_SNACKBAR", text, timeout);
    },
    // eslint-disable-next-line vue/no-unused-properties
    showInfoSnackbar(text: string, timeout?: number) {
      this.$emit("SHOW_INFO_SNACKBAR", text, timeout);
    },
    // eslint-disable-next-line vue/no-unused-properties
    showSnackbar(payload: SnackbarPayload) {
      this.$emit("SHOW_SNACKBAR", payload);
    },
    // eslint-disable-next-line vue/no-unused-properties
    showSuccessSnackbar(text: string, timeout?: number) {
      this.$emit("SHOW_SUCCESS_SNACKBAR", text, timeout);
    },
    // eslint-disable-next-line vue/no-unused-properties
    showWarningSnackbar(text: string, timeout?: number) {
      this.$emit("SHOW_WARNING_SNACKBAR", text, timeout);
    },
  },
});
