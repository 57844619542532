import axios, { AxiosError } from "axios";
import { HTTP_METHODS, HTTP_STATUS_CODE } from "@/@types/service/http";
import {
  IChartContent,
  IChartDescriptions,
  IDelimitationConclusion,
  IDelimitationGeoJsonErrors,
  IExecutionTaskPayload,
  INewRevision,
  IRefusalPayload,
  ITask,
  ITaskDetails,
} from "@/@types/manualAnalysis/analysis";

export interface ChartContentPayload {
  taskId: string;
  chartIdx: number;
}

type ExecuteTaskReturn = boolean | IDelimitationGeoJsonErrors | undefined;

const API = process.env.VUE_APP_MANUAL_ANALYSIS_API;

const manualAnalysisService = {
  // https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.7#/Tasks/createDelimitationReview
  async createTaskRevision(payload: INewRevision): Promise<void> {
    await axios({
      data: payload,
      method: HTTP_METHODS.POST,
      url: `${API}/tasks:delimitationReview`,
    });
  },

  // https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.11#/Tasks/deleteTask
  async deleteTask(taskId: string): Promise<void> {
    await axios({
      method: HTTP_METHODS.DELETE,
      url: `${API}/tasks/${taskId}`,
    });
  },

  // https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.7#/Execution/concludeTask
  async executeTask(
    data: IExecutionTaskPayload | IDelimitationConclusion,
  ): Promise<ExecuteTaskReturn> {
    try {
      await axios({
        data,
        method: HTTP_METHODS.POST,
        url: `${API}/tasks/current`,
      });
    } catch (error) {
      switch ((error as AxiosError).response?.status) {
        case HTTP_STATUS_CODE.UNPROCESSABLE_ENTITY:
          if ((data as IDelimitationConclusion).identificationSources) {
            return (error as AxiosError<{ validation: ExecuteTaskReturn }>).response
              ?.data.validation;
          }
          throw error;
        default:
          throw error;
      }
    }
    return true;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.7#/Execution/getChartById
  async getChartContent(payload: ChartContentPayload): Promise<IChartContent> {
    const endpoint = `${API}/tasks/${payload.taskId}/charts/${payload.chartIdx}`;
    const response = await axios.get<IChartContent>(endpoint);

    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.7#/Tasks/getCurrentTask
  async getCurrentTask(): Promise<ITask | null> {
    try {
      const endpoint = `${API}/tasks/current`;
      const response = await axios.get<ITask | null>(endpoint);

      return response.data;
    } catch (error) {
      switch ((error as AxiosError).response?.status) {
        case HTTP_STATUS_CODE.NOT_FOUND:
          return null;
        default:
          throw error;
      }
    }
  },

  // https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.16#/Tasks/getLatestReview
  async getLatestReview(taskId: string): Promise<ITask | null> {
    try {
      const endpoint = `${API}/tasks/${taskId}/latestReview`;
      const response = await axios.get<ITask | null>(endpoint);

      return response.data;
    } catch (error) {
      switch ((error as AxiosError).response?.status) {
        case HTTP_STATUS_CODE.NOT_FOUND:
          return null;
        default:
          throw error;
      }
    }
  },

  // https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.7#/Execution/getResultsByStandId
  async getStandResults(
    payload: ChartContentPayload,
  ): Promise<Omit<IChartContent, "timeseries">> {
    const endpoint = `${API}/tasks/${payload.taskId}/results/${payload.chartIdx}`;
    const response = await axios.get(endpoint);

    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.7#/Tasks/getTaskById
  async getTaskById(taskId: string): Promise<ITask> {
    const endpoint = `${API}/tasks/${taskId}`;
    const response = await axios.get<ITask>(endpoint);

    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.7#/Execution/post_me_next_start
  async getTaskCharts(taskId: string): Promise<IChartDescriptions> {
    const endpoint = `${API}/tasks/${taskId}/charts`;
    const response = await axios.get<IChartDescriptions>(endpoint);

    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.11#/Tasks/taskDetails
  async getTaskDetails(taskId: string): Promise<ITaskDetails> {
    const endpoint = `${API}/tasks/${taskId}/details`;
    const response = await axios.get<ITaskDetails>(endpoint);

    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.7#/Tasks/refuseTask
  async refuseTask(taskId: string, payload: IRefusalPayload): Promise<void> {
    await axios({
      data: payload,
      method: HTTP_METHODS.POST,
      url: `${API}/tasks/${taskId}:refuse`,
    });
  },

  // https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.8#/Tasks/skipCurrentTask
  async skipCurrentTask(): Promise<void> {
    await axios({
      method: HTTP_METHODS.POST,
      url: `${API}/tasks/current:skip`,
    });
  },

  // https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.7#/Tasks/startCurrentTask
  async startCurrentTask(): Promise<boolean> {
    try {
      await axios({
        method: HTTP_METHODS.POST,
        url: `${API}/tasks/current:start`,
      });
    } catch (error) {
      switch ((error as AxiosError).response?.status) {
        // In this case we want to suppress the error because it's a known edge case;
        case HTTP_STATUS_CODE.CONFLICT:
          break;
        default:
          throw error;
      }
    }
    return true;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.11#/Tasks/reviewTask
  async startReview(taskId: string): Promise<ITask> {
    const endpoint = `${API}/tasks/${taskId}:review`;
    const response = await axios.post<ITask>(endpoint);

    return response.data;
  },

  // https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.7#/Tasks/taskTake
  async takeTask(taskId: string): Promise<ITask> {
    const endpoint = `${API}/tasks/${taskId}:take`;
    const response = await axios.post<ITask>(endpoint);

    return response.data;
  },
};

export default manualAnalysisService;
