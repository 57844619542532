import { IOperationsMap, OperationState } from "@/@types/vuex";
import { IOperation } from "@/@types/onboarding/operation";
import { isObjectEmpty } from "@/utils/object";
import { MutationTree } from "vuex";
import Vue from "vue";

export enum OperationMutation {
  SET_OPERATIONS = "SET_OPERATIONS",
  SET_OPERATION = "SET_OPERATION",
}

const mutations: MutationTree<OperationState> = {
  SET_OPERATION: (state: OperationState, operation: IOperation) => {
    state.operations[operation.id] = operation;
  },
  SET_OPERATIONS: (state: OperationState, operationsMap: IOperationsMap) => {
    if (isObjectEmpty(state.operations)) {
      Vue.set(state, "operations", operationsMap);
      return;
    }

    for (const operationId in operationsMap) {
      Vue.set(state.operations, operationId, operationsMap[operationId]);
    }
  },
};

export default mutations;
