import { FarmRegistrationState } from "@/store/farmRegistration/state";
import { FarmRegistrationTask } from "@/services/farms/schemas";
import { MutationTree } from "vuex";

export enum FarmRegistrationMutations {
  SET_PENDING_TASKS = "SET_PENDING_TASKS",
  SET_REGISTERED_TASKS = "SET_REGISTERED_TASKS",
}

export const mutations: MutationTree<FarmRegistrationState> = {
  [FarmRegistrationMutations.SET_PENDING_TASKS](
    state,
    tasks: Map<string, FarmRegistrationTask>,
  ) {
    state.tasks.PENDING = tasks;
  },
  [FarmRegistrationMutations.SET_REGISTERED_TASKS](
    state,
    tasks: Map<string, FarmRegistrationTask>,
  ) {
    state.tasks.REGISTERED = tasks;
  },
};
