export enum ButtonType {
  PLAY = "PLAY",
  REPLAY = "REPLAY",
  EDIT = "EDIT",
  VIEW = "VIEW",
  OPEN = "OPEN",
  DELETE = "DELETE",
  ATTACHMENT = "ATTACHMENT",
  HELP = "HELP",
  UPDATE = "UPDATE",
  SAVE = "SAVE",
  CANCEL = "CANCEL",
  CUSTOM = "CUSTOM",
}

export enum ButtonTypeTooltipTranslation {
  PLAY = "Começar",
  REPLAY = "Continuar",
  EDIT = "Editar",
  VIEW = "Visualizar",
  OPEN = "Abrir",
  DELETE = "Excluir",
  SAVE = "Salvar",
  ATTACHMENT = "Anexo",
  HELP = "Ajuda",
  UPDATE = "Atualizar",
  CANCEL = "Cancelar",
  CUSTOM = "",
}

export enum ButtonTypeIconTranslation {
  SAVE = "save_alt",
  PLAY = "play_arrow",
  REPLAY = "replay",
  EDIT = "edit",
  VIEW = "visibility",
  OPEN = "launch",
  DELETE = "delete",
  HELP = "help",
  UPDATE = "update",
  ATTACHMENT = "attachment",
  CANCEL = "cancel",
  CUSTOM = "",
}
