const Onboarding = () =>
  import(/* webpackChunkName: "Onboarding" */ "@/views/Onboarding.vue");

const OperationRequests = () =>
  import(
    /* webpackChunkName: "OperationRequests" */ "@/views/onboarding/operation/RequestsList.vue"
  );

const OperationRequest = () =>
  import(
    /* webpackChunkName: "OperationRequest" */ "@/views/onboarding/operation/OperationRequest.vue"
  );

const EditOperationRequest = () =>
  import(
    /* webpackChunkName: "EditOperationRequest" */ "@/views/onboarding/operation/EditOperationRequest.vue"
  );

const PrefinRequestDetailsPage = () =>
  import(
    /* webpackChunkName: "PrefinRequestDetailsPage" */ "@/views/onboarding/preFinancing/RequestDetails.vue"
  );
const PrefinRequestsListPage = () =>
  import(
    /* webpackChunkName: "PrefinRequestListPage" */ "@/views/onboarding/preFinancing/RequestsList.vue"
  );

export enum OnboardingRoute {
  PRE_FINANCING_REQUESTS = "PreFinancingRequests",
  PRE_FINANCING_REQUEST = "PreFinancingRequest",
  OPERATION_REQUESTS = "OperationRequests",
  OPERATION_REQUEST = "OperationRequest",
  EDIT_OPERATION_REQUEST = "EditOperationRequest",
}

export default [
  {
    children: [
      {
        component: PrefinRequestsListPage,
        meta: { requiresAuth: true },
        name: OnboardingRoute.PRE_FINANCING_REQUESTS,
        path: "pre-financing",
      },
      {
        component: PrefinRequestDetailsPage,
        meta: { requiresAuth: true },
        name: OnboardingRoute.PRE_FINANCING_REQUEST,
        path: "pre-financing/:requestId",
      },
      {
        component: OperationRequests,
        meta: { requiresAuth: true },
        name: OnboardingRoute.OPERATION_REQUESTS,
        path: "operation",
      },
      {
        component: OperationRequest,
        meta: { requiresAuth: true },
        name: OnboardingRoute.OPERATION_REQUEST,
        path: "operation/:requestId",
      },
      {
        component: EditOperationRequest,
        meta: { requiresAuth: true },
        name: OnboardingRoute.EDIT_OPERATION_REQUEST,
        path: "operation/edit/:requestId",
      },
    ],
    component: Onboarding,
    meta: { requiresAuth: true },
    path: "/onboarding",
  },
];
