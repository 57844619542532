import {
  cropTypeTranslate,
  taskPriorityTranslate,
  taskStatusTranslate,
  taskTypeTranslate,
} from "@/utils/translations";
import {
  dateOptionToDateValue,
  dateStringToDateValue,
  isoDateToBrazilianDate,
  isoDateToBrazilianDateTime,
} from "@/utils/date";
import {
  IFormattedTaskView,
  ITaskSummaryView,
  ITaskView,
  ITaskViewsList,
  ITaskViewsParams,
  ITaskViewURLParams,
} from "@/@types/manualAnalysis/panel";
import { ManualAnalysisPanelState, RootState } from "@/@types/vuex";

import { ActionTree } from "vuex";
import { Client } from "@/@types/client";
import { DateOption } from "@/constants/manual-analysis-panel/filterParams";
import { IAnalyst } from "@/@types/analyst";
import { IUser } from "@/@types/user";
import { LegalEntity } from "@/services/legalEntity/schemas/legalEntity";
import manualAnalysisPanelService from "@/services/manualAnalysisPanel";
import { NULL_STRING } from "@/constants/shared/strings";
import { seasonStringToSeason } from "@/utils/string";
import { TaskPrioritySortable } from "@/constants/manual-analysis/shared";

const actions: ActionTree<ManualAnalysisPanelState, RootState> = {
  async formatTaskView(
    { rootGetters },
    tasks: ITaskViewsList,
  ): Promise<ITaskViewsList> {
    const clientById = rootGetters["client/clientById"];
    const analystById = rootGetters["analyst/analystById"];
    const userById = rootGetters["user/userById"];
    const entityById = rootGetters["legalEntity/getEntityById"];

    const formatTasks = (tasks: ITaskView[]): IFormattedTaskView[] =>
      tasks.map((task) =>
        _formatTask(task, clientById, userById, analystById, entityById),
      );

    return {
      DOING: formatTasks(tasks.DOING),
      DONE: formatTasks(tasks.DONE),
      REVISIONS: formatTasks(tasks.REVISIONS),
      TODO: formatTasks(tasks.TODO),
    };
  },
  async getTaskViews(
    { dispatch },
    payload: ITaskViewURLParams,
  ): Promise<ITaskSummaryView> {
    const getDateFilters = (dateType = DateOption.THIS_WEEK, dateValue?: string) => {
      if (dateType !== DateOption.SPECIFIC_DATES) {
        return dateOptionToDateValue(dateType);
      }
      if (dateValue) return dateStringToDateValue(dateValue);
      return dateOptionToDateValue(DateOption.THIS_WEEK);
    };

    const { dueDateValue, dueDateType, season, ...rest } = payload;
    const dates = getDateFilters(dueDateType as DateOption, dueDateValue);
    const seasonCrop = seasonStringToSeason(season || "");
    const params = { ...dates, ...seasonCrop, ...rest } as ITaskViewsParams;

    const { tasks, summary } = await manualAnalysisPanelService.getTaskViews(params);

    const taskViews = {
      summary,
      tasks: await dispatch("formatTaskView", tasks),
    };

    return Object.freeze(taskViews);
  },
};

const _formatTask = (
  task: ITaskView,
  clientById: Function,
  userById: Function,
  analystById: Function,
  entityById: Function,
): IFormattedTaskView => {
  const {
    analystId,
    clientId,
    creationTime,
    completionTime,
    startTime,
    dueDate,
    financedCrop,
    season,
    taskStatus,
    taskType,
    serialId,
    priority,
    municipality,
    state,
    producerId,
  } = task;

  const client: Client | undefined = clientById(clientId);
  const analyst: IAnalyst | undefined = analystById(analystId);
  const analystUser: IUser | undefined = userById(analyst?.userId);
  const producer: LegalEntity | undefined = producerId
    ? entityById(producerId)
    : undefined;

  const formattedTaskView = {
    ...task,
    analystName: analystUser?.name || NULL_STRING,
    clientName: client?.pretty_name || client?.name || NULL_STRING,
    producerName: producer?.name ?? "Desconhecido",
    serialId: serialId || NULL_STRING,
    sortablePriority: TaskPrioritySortable[priority],
    translatedCompletionTime: isoDateToBrazilianDateTime(completionTime),
    translatedCreationTime: isoDateToBrazilianDateTime(creationTime),
    translatedDueDate: isoDateToBrazilianDate(dueDate),
    translatedFinancedCrop: cropTypeTranslate(financedCrop),
    translatedLocation: `${municipality} - ${state}`,
    translatedPriority: taskPriorityTranslate(priority),
    translatedSeason: `${season.crop} ${season.year}`,
    translatedStartTime: isoDateToBrazilianDateTime(startTime),
    translatedTaskStatus: taskStatusTranslate(taskStatus),
    translatedTaskType: taskTypeTranslate(taskType),
  };

  return formattedTaskView;
};

export default actions;
