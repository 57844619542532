import { ManualAnalysisState, RootState } from "@/@types/vuex";

import { ActionTree } from "vuex";
import { FeatureCollection } from "@/@types/geoJson";
import imageService from "@/services/images";
import { ISeason } from "@/@types/onboarding/shared";
import { ITaskImages } from "@/@types/manualAnalysis/analysis";

const actions: ActionTree<ManualAnalysisState, RootState> = {
  async fetchImages(
    _,
    request: { geometry: FeatureCollection; season: ISeason },
  ): Promise<ITaskImages[]> {
    const { geometry, season } = request;
    return await imageService.fetchImages(geometry, season);
  },
};

export default actions;
