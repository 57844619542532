import axios from "axios";
import { IAnalystPage } from "@/@types/analyst";

const API = process.env.VUE_APP_MANUAL_ANALYSIS_API;

const analystService = {
  // https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.7#/Analysts/getAnalysts
  async getAnalysts(): Promise<IAnalystPage> {
    const response = await axios.get<IAnalystPage>(`${API}/analysts`);
    return response.data;
  },
};

export default analystService;
