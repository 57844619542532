import { AnalystState, RootState } from "@/@types/vuex";
import { GetterTree } from "vuex";
import { IAnalyst } from "@/@types/analyst";

const getters: GetterTree<AnalystState, RootState> = {
  analystById:
    ({ analysts }: AnalystState) =>
    (analystId: string): IAnalyst | undefined => {
      return analysts[analystId];
    },
  analystByUserId:
    ({ analysts }: AnalystState) =>
    (requestedUserId: string): IAnalyst | undefined => {
      return Object.values(analysts).find(({ userId }) => userId === requestedUserId);
    },
  analysts: ({ analysts }: AnalystState): IAnalyst[] => Object.values(analysts),
};

export default getters;
