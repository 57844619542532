import { actions } from "@/store/legalEntity/actions";
import { getters } from "@/store/legalEntity/getters";
import { mutations } from "@/store/legalEntity/mutations";
import { state } from "@/store/legalEntity/state";

export const legalEntity = {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
