import { DateOption } from "@/constants/manual-analysis-panel/filterParams";
import { IDueDateValue } from "@/@types/manualAnalysis/panel";
import { ISeason } from "@/@types/onboarding/shared";
import moment from "moment";
import { NULL_STRING } from "@/constants/shared/strings";
import { SeasonCropType } from "@/constants/shared/financial";

export enum DateType {
  DATE = "DATE",
  DATE_TIME = "DATETIME",
}

export enum Locale {
  PT_BR = "pt-BR",
}

export const enum DATE_FORMAT {
  BR = "DD/MM/YYYY",
  ISO = "YYYY-MM-DD",
}

export const isoDateToBrazilianDate = (isoDate?: string): string =>
  isoDateToFormat(isoDate, DateType.DATE) || NULL_STRING;

export const isoDateToBrazilianDateTime = (isoDate?: string): string =>
  isoDateToFormat(isoDate, DateType.DATE_TIME) || NULL_STRING;

export const isoDateToLocaleDateString = (isoDate: string): string =>
  moment(isoDate, DATE_FORMAT.ISO).format(DATE_FORMAT.BR);

export const brazilianDateToIsoDate = (brazilianDate: string): string =>
  moment(brazilianDate, DATE_FORMAT.BR).format(DATE_FORMAT.ISO);

export const isoDateToFormat = (
  isoDate?: string,
  dateType: DateType = DateType.DATE,
  locale: Locale = Locale.PT_BR,
): string => {
  if (!isoDate) return "";
  const date = moment(isoDate).locale(locale);
  switch (dateType) {
    case DateType.DATE:
      return date.format("L");
    case DateType.DATE_TIME:
      return `${date.format("L")} ${date.format("LT")}`;
    default:
      return "";
  }
};
export const convertDateFormat = (
  date: string,
  formatFrom: string,
  formatTo: string,
): string => {
  if (!date || !formatFrom || !formatTo) {
    throw new Error("ERROR_TO_CONVERT_DATE");
  }
  if (!isDateValid(date, formatFrom)) {
    throw new Error("BAD_DATE_FORMAT");
  }
  return moment(date, formatFrom).format(formatTo);
};

export const isDateValid = (date: string, format: string): boolean => {
  if (!date || !format || date.length !== format.length) return false;
  return moment(date, format).isValid();
};

// This function is only used on field validation rules
export const isDateFieldValid = (
  date: string,
  format: string = DATE_FORMAT.BR,
): string | boolean => {
  if (!date) return true;

  if (!isDateValid(date, format)) return "Data inválida";

  return true;
};

export const formatDate = (date: string): string => {
  return moment(date).format(DATE_FORMAT.BR);
};

export const dateStringToTimestamp = (
  date: string,
  format: string = DATE_FORMAT.ISO,
): number | string => {
  if (!date || !isDateValid(date, format)) return "Data inválida";

  return moment(date, format).toDate().valueOf();
};

export const today = (format = DATE_FORMAT.ISO): string => {
  return moment().format(format);
};

export const weekEnd = (format = DATE_FORMAT.ISO): string => {
  return moment().weekday(7).format(format);
};

export const weekStart = (format = DATE_FORMAT.ISO): string => {
  return moment().weekday(-1).format(format);
};

export const yesterday = (format = DATE_FORMAT.ISO): string => {
  return moment().subtract(1, "day").format(format);
};

export const dateOptionToDateValue = (dateOption: DateOption): IDueDateValue => {
  switch (dateOption) {
    case DateOption.THIS_WEEK:
      return { end: weekEnd(), start: weekStart() };
    case DateOption.TODAY:
      return { end: today(), start: today() };
    case DateOption.YESTERDAY:
      return { end: yesterday(), start: yesterday() };
    default:
      return { end: "", start: "" };
  }
};

export const dateOptionToDateString = (dateOption: DateOption): string => {
  return dateValueToDateString(dateOptionToDateValue(dateOption));
};

export const dateValueToDateString = ({ start, end }: IDueDateValue): string => {
  return `${start};${end}`;
};

export const dateValueToDateOption = ({ start, end }: IDueDateValue): DateOption => {
  if (start === end) {
    if (start === yesterday()) return DateOption.YESTERDAY;
    if (start === today()) return DateOption.TODAY;
  }

  if (start === weekStart() && end === weekEnd()) return DateOption.THIS_WEEK;
  return DateOption.SPECIFIC_DATES;
};

export const dateStringToDateOption = (date: string): DateOption => {
  return dateValueToDateOption(dateStringToDateValue(date));
};

export const dateStringToDateValue = (dateValue: string): IDueDateValue => {
  const [start, end] = dateValue.split(";");
  return { end, start };
};

export const subtractDatesToDays = (date1: Date, date2: Date): number => {
  const IN_DAYS = 24 * 3600 * 1000;
  const diffTime = date1.getTime() - date2.getTime();

  return diffTime / IN_DAYS;
};

export const getImagesDateRange = (season: ISeason): string[] => {
  // We need to add 60 days of images before the season crop period.
  const DATE_PADDING = 60;
  const datesList = [];
  // We are creating the month of the season crop. 8 means September and 0 january.
  const month = season.crop === SeasonCropType.SAFRA ? 8 : 0;
  const initialDate = new Date(season.year || new Date().getFullYear(), month);
  initialDate.setDate(initialDate.getDate() - DATE_PADDING);

  for (; initialDate < new Date(); initialDate.setDate(initialDate.getDate() + 1)) {
    datesList.push(initialDate.toISOString().split("T")[0]);
  }
  return datesList;
};

export const addDays = (date: string, days: number): string => {
  return new Date(new Date(date).getTime() + 1000 * 60 * 60 * 24 * days)
    .toISOString()
    .slice(0, 10);
};

export const subtractDays = (date: string, days: number): string => {
  return addDays(date, -days);
};

export const dateStringToDateTime = (date: string): Date => {
  return /[\d]{2}:[\d]{2}:[\d]{2}/.test(date)
    ? new Date(date)
    : new Date(`${date} 00:00:00`);
};
