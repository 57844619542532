import { LoginState, RootState } from "@/@types/vuex";

import { GetterTree } from "vuex";

const getters: GetterTree<LoginState, RootState> = {
  isLoggedIn: (state: LoginState) => !!state.token,
  roles: (state: LoginState) => state.roles,
  token: (state: LoginState) => state.token,
};

export default getters;
