var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-container" },
    [
      _c("app-navbar", {
        attrs: { loading: _vm.isLoading },
        scopedSlots: _vm._u(
          [
            _vm.isLoggedIn
              ? {
                  key: "logout",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "", color: "grey" },
                          on: { click: _vm.logout },
                        },
                        [
                          _c("span", [_vm._v("SAIR")]),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("exit_to_app"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("router-view", { staticClass: "home-container__router-view" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }