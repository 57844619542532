import { TaskStatus } from "@/constants/manual-analysis/shared";

export const isTaskDoneOrReviewed = (taskStatus: TaskStatus) => {
  return isTaskDone(taskStatus) || isTaskReviewed(taskStatus);
};

export const isTaskDone = (taskStatus: TaskStatus) => {
  return taskStatus === TaskStatus.DONE;
};

export const isTaskReviewed = (taskStatus: TaskStatus) => {
  return taskStatus === TaskStatus.REVIEWED;
};

export const isTaskToDo = (taskStatus: TaskStatus) => {
  return taskStatus === TaskStatus.UNASSIGNED || taskStatus === TaskStatus.ASSIGNED;
};

export const isTaskStarted = (taskStatus: TaskStatus) => {
  return taskStatus === TaskStatus.STARTED;
};
