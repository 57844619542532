import { AnalystState, IAnalystMap } from "@/@types/vuex";
import { MutationTree } from "vuex";
import Vue from "vue";

export enum AnalystMutation {
  SET_ANALYSTS = "SET_ANALYSTS",
}

const mutations: MutationTree<AnalystState> = {
  [AnalystMutation.SET_ANALYSTS](state: AnalystState, analystsMap: IAnalystMap) {
    Vue.set(state, "analysts", analystsMap);
  },
};

export default mutations;
