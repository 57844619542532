import { IFarmGeometriesWithStands } from "@/@types/monitoring";
import { MonitoringState } from "@/@types/vuex";
import { MutationTree } from "vuex";
import Vue from "vue";

export enum MonitoringMutation {
  SET_GEOMETRIES = "SET_GEOMETRIES",
}

const mutations: MutationTree<MonitoringState> = {
  SET_GEOMETRIES: (
    state: MonitoringState,
    farmGeometries: IFarmGeometriesWithStands,
  ) => {
    Vue.set(state, "farmGeometries", farmGeometries);
  },
};

export default mutations;
