import { NULL_STRING } from "@/constants/shared/strings";

export const INVALID_CPF_NUMBERS = (): string[] => [
  "00000000000",
  "11111111111",
  "22222222222",
  "33333333333",
  "44444444444",
  "55555555555",
  "66666666666",
  "77777777777",
  "88888888888",
  "99999999999",
];

export const INVALID_CNPJ_NUMBERS = (): string[] => [
  "00000000000000",
  "11111111111111",
  "22222222222222",
  "33333333333333",
  "44444444444444",
  "55555555555555",
  "66666666666666",
  "77777777777777",
  "88888888888888",
  "99999999999999",
];

export const enum IdentityNumberLength {
  RAW_CPF = 11,
  RAW_CNPJ = 14,
  MASKED_CPF = 14,
  MASKED_CNPJ = 18,
}

export const enum InvalidCpfCnpjMessage {
  INVALID_CPF = "CPF inválido",
  INVALID_CPF_LIST = "Documentos inválidos: {CONTENT}",
  INVALID_CNPJ = "CNPJ inválido",
  INVALID_CNPJ_LIST = "Documentos inválidos: {CONTENT}",
  INVALID_CPF_OR_CNPJ = "CPF ou CNPJ inválido",
  INVALID_CPF_OR_CNPJ_LIST = "Documentos inválidos: {CONTENT}",
}

export const enum InvalidCpfCnpjError {
  BAD_CPF = "BAD_CPF_FORMAT",
  BAD_CNPJ = "BAD_CNPJ_FORMAT",
  BAD_CPF_OR_CNPJ = "BAD_CPF_OR_CNPJ_FORMAT",
}

export const clearCpfOrCnpj = (value: string): string => {
  if (!value) return "";

  return value.replace(/[.\-/]/g, "");
};

export const formatCpfOrCnpj = (value: string): string => {
  const cleanValue: string = clearCpfOrCnpj(value);

  switch (cleanValue.length) {
    case 11:
      return formatCpf(cleanValue);
    case 14:
      return formatCnpj(cleanValue);
    default:
      throw new Error(InvalidCpfCnpjError.BAD_CPF_OR_CNPJ);
  }
};

export const formatCpf = (value: string): string => {
  const cleanValue: string = clearCpfOrCnpj(value);

  if (!value || cleanValue.length !== 11) throw new Error(InvalidCpfCnpjError.BAD_CPF);

  return cleanValue.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

export const formatCnpj = (value: string): string => {
  const cleanValue: string = clearCpfOrCnpj(value);

  if (!value || cleanValue.length !== 14) throw new Error(InvalidCpfCnpjError.BAD_CNPJ);

  return cleanValue.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
};

export const simpleFormatCnpj = (value: string): string => {
  if (!value) return NULL_STRING;

  return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
};

// Ref: https://www.devmedia.com.br/validar-cpf-com-javascript/23916
// CPF Validation based on official validation from government
// http://www.receita.fazenda.gov.br/aplicacoes/atcta/cpf/funcoes.js
export const validateCpfValue = (cpf: string): boolean => {
  const cleanCpf: string = clearCpfOrCnpj(cpf);

  if (!cpf || INVALID_CPF_NUMBERS().includes(cleanCpf) || cleanCpf.length != 11)
    return false;

  for (let i: number = 0; i <= 1; i++) {
    let sum: number = 0;
    let rest: number;

    const checkDigit: number = parseInt(cleanCpf.substring(9 + i, 10 + i));

    for (let j: number = 1; j <= 9 + i; j++) {
      sum += parseInt(cleanCpf.substring(j - 1, j)) * (11 + i - j);
    }
    rest = (sum * 10) % 11;

    if (rest == 10) rest = 0;
    if (rest != checkDigit) return false;
  }

  return true;
};

// Ref: https://gist.github.com/roneigebert/10d788a07e2ffff88eb0f1931fb7bb49
export const validateCnpjValue = (cnpj: string): boolean => {
  const cleanCnpj: string = clearCpfOrCnpj(cnpj);

  if (!cnpj || INVALID_CNPJ_NUMBERS().includes(cleanCnpj) || cleanCnpj.length != 14)
    return false;

  const cnpjLength: number = cleanCnpj.length - 2;
  const digits: string = cleanCnpj.substring(cnpjLength);

  for (let i: number = 0; i <= 1; i++) {
    const length: number = cnpjLength + i;
    const numbers: string = cleanCnpj.substring(0, length);
    let sum: number = 0;
    let pos: number = length - 7;

    for (let j: number = length; j >= 1; j--) {
      const num: number = parseInt(numbers.charAt(length - j), 10);
      sum += num * pos--;
      if (pos < 2) pos = 9;
    }

    const rest = sum % 11;
    const result = rest < 2 ? 0 : 11 - rest;
    const checkDigit = parseInt(digits.charAt(i), 10);

    if (result !== checkDigit) return false;
  }

  return true;
};

export const validCpfOrCnpjValueRule = (value: string): true | string => {
  const cleanValue = clearCpfOrCnpj(value);

  switch (cleanValue.length) {
    case 11:
      return validCpfValueRule(cleanValue);
    case 14:
      return validCnpjValueRule(cleanValue);
    default:
      return InvalidCpfCnpjMessage.INVALID_CPF_OR_CNPJ;
  }
};

export const validCpfValueRule = (value: string): true | string => {
  return validateCpfValue(value) || InvalidCpfCnpjMessage.INVALID_CPF;
};

export const validCnpjValueRule = (value: string): true | string => {
  return validateCnpjValue(value) || InvalidCpfCnpjMessage.INVALID_CNPJ;
};

export const validateIdentityNumberList = (
  value: string,
  acceptedIdentityNumberType: IdentityNumberType,
): true | string => {
  if (!value) return true;

  let validationFunction: Function;
  let invalidMessage: string;
  switch (acceptedIdentityNumberType) {
    case IdentityNumberType.CPF:
      validationFunction = validCpfValueRule;
      invalidMessage = InvalidCpfCnpjMessage.INVALID_CPF_LIST;
      break;
    case IdentityNumberType.CNPJ:
      validationFunction = validCnpjValueRule;
      invalidMessage = InvalidCpfCnpjMessage.INVALID_CNPJ_LIST;
      break;
    default:
      validationFunction = validCpfOrCnpjValueRule;
      invalidMessage = InvalidCpfCnpjMessage.INVALID_CPF_OR_CNPJ_LIST;
  }

  const identityNumberList: string[] = identityNumbersAsList(value);
  if (!identityNumberList.length) return "";

  const invalidIdentityNumberList = identityNumberList.filter(
    (number) => number && validationFunction(number) !== true,
  );

  invalidMessage = invalidMessage.replace(
    "{CONTENT}",
    invalidIdentityNumberList.join(", "),
  );

  return !invalidIdentityNumberList.length || invalidMessage;
};

export const identityNumbersAsList = (identityNumberList: string): string[] =>
  identityNumberList.replace(/\s+/g, "").split(",").filter(Boolean);

export enum IdentityNumberType {
  CPF = "CPF",
  CNPJ = "CNPJ",
  CPF_CNPJ = "CPF_CNPJ",
}

export const validCpfOrCnpjListRule = (value: string): true | string => {
  return validateIdentityNumberList(value, IdentityNumberType.CPF_CNPJ);
};
