const Clients = () => import(/* webpackChunkName: "Clients" */ "@/views/Clients.vue");

const ClientsLists = () =>
  import(/* webpackChunkName: "ClientsLists" */ "@/views/clients/ClientsLists.vue");

export enum ClientsRoute {
  CLIENTS = "ClientsLists",
}

export default [
  {
    children: [
      {
        component: ClientsLists,
        meta: { requiresAuth: true },
        name: ClientsRoute.CLIENTS,
        path: "",
      },
    ],
    component: Clients,
    meta: { requiresAuth: true },
    path: "/clients",
  },
];
