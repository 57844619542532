import { IPreFinRequestFormatted } from "@/@types/onboarding/preFinancing";
import { MutationTree } from "vuex";
import { PrefinState } from "@/store/prefin/state";

export enum PrefinMutation {
  SET_REQUESTS = "SET_REQUESTS",
  SET_SELECTED_REQUEST = "SET_SELECTED_REQUEST",
}

export const mutations: MutationTree<PrefinState> = {
  SET_REQUESTS(state: PrefinState, requests: IPreFinRequestFormatted[]): void {
    state.requests = requests;
    state.total = requests.length;
  },
  SET_SELECTED_REQUEST(state: PrefinState, request: IPreFinRequestFormatted): void {
    state.selectedRequest = request;
  },
};
