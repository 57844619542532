import { AnalysisRequest } from "@/services/prefin/schemas/analysisRequest";
import { AnalysisRequestStatus } from "@/services/prefin/types/status";
import axios from "axios";
import { RefusalReason } from "@/services/prefin/types/refusalReason";

const API_URL = process.env.VUE_APP_API_GTW;

class PrefinService {
  private apiURL: string;

  public constructor() {
    if (!API_URL) {
      throw new Error("API_URL is not defined");
    }

    this.apiURL = `${API_URL}/prefin/internal`;
  }

  public async fetchAllRequestsByStatus(
    status = [AnalysisRequestStatus.PENDING],
    after?: string,
    before?: string,
  ): Promise<AnalysisRequest[]> {
    const endpoint = `${this.apiURL}/requests`;
    const params = {
      after,
      before,
      status: status.join(","),
    };
    const response = await axios.get<{ requests: AnalysisRequest[] }>(endpoint, {
      params,
    });

    return response.data.requests;
  }

  public async cancelRequestById(id: string): Promise<void> {
    const endpoint = `${this.apiURL}/requests/${id}/cancel`;

    await axios.post(endpoint);
  }

  public async refuseRequestById(id: string, reasons: RefusalReason[]): Promise<void> {
    const endpoint = `${this.apiURL}/requests/${id}/refuse`;

    await axios.post(endpoint, {
      reasons,
    });
  }

  public async updateRequestPipefyId(id: string, pipefyId: string): Promise<void> {
    const endpoint = `${this.apiURL}/requests/${id}`;

    await axios.patch(endpoint, {
      pipefyId,
    });
  }
}

export const prefinService = new PrefinService();
