import "material-design-icons-iconfont/dist/material-design-icons.css";
import "vuetify/dist/vuetify.min.css";
import { theme } from "@/constants/shared/theme";
import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify, {
  iconfont: "md",
  options: {
    customProperties: true,
  },
  theme,
});
