import { LoginState, RootState } from "@/@types/vuex";
import { ActionTree } from "vuex";
import { ICredentials } from "@/@types/login";
import { LoginMutation } from "./mutations";
import loginService from "@/services/login";

const actions: ActionTree<LoginState, RootState> = {
  async login({ commit }, credential: ICredentials): Promise<void> {
    try {
      const {
        token,
        email,
        roles,
        name,
        client_id: clientId,
      } = await loginService.signIn(credential);
      const userData = JSON.stringify({ clientId, email, name, roles });
      localStorage.setItem("token", token);
      localStorage.setItem("user_data", userData);
      localStorage.setItem("roles", JSON.stringify(roles));
      const user = { email };
      commit(LoginMutation.LOGIN_SUCCESS, { roles, token, user });
    } catch (error) {
      localStorage.removeItem("token");
      throw error;
    }
  },
  async logout({ commit }): Promise<void> {
    commit(LoginMutation.LOGOUT);
    localStorage.removeItem("token");
    localStorage.removeItem("user_data");
  },
};

export default actions;
