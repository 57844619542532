







import {
  CSS_STYLE_CROP_COLORS,
  CSS_STYLE_CROP_STATUS_COLORS,
} from "@/constants/shared/crop";
import { requestInterceptor, responseInterceptor } from "@/services/httpInterceptor";

import AmplitudeService from "@/services/amplitude";
import AppSnackbar from "@/components/AppSnackbar.vue";
import { CSS_STYLE_ANALYSIS_RESULT_TYPE_COLORS } from "@/constants/shared/resultsType";
import { CSS_STYLE_CHART_WARNING_COLORS } from "@/constants/shared/chartjs";
import { CSS_STYLE_REQUEST_STATUS_COLORS } from "@/constants/shared/requestStatus";
import { CurrentUser } from "@/business/CurrentUser";
import { LoginRoute } from "@/router";
import { mapActions } from "vuex";
import Vue from "vue";

export default Vue.extend({
  components: {
    AppSnackbar,
  },
  computed: {
    cssColorVariables: () =>
      CSS_STYLE_CHART_WARNING_COLORS +
      CSS_STYLE_CROP_COLORS +
      CSS_STYLE_CROP_STATUS_COLORS +
      CSS_STYLE_ANALYSIS_RESULT_TYPE_COLORS +
      CSS_STYLE_REQUEST_STATUS_COLORS,
  },
  created() {
    requestInterceptor();
    responseInterceptor(this.logout);
  },
  methods: {
    ...mapActions({
      logoutUser: "login/logout",
    }),
    async logout() {
      await this.logoutUser();
      this.$router.push({ name: LoginRoute.LOGIN });
    },
  },
  mounted() {
    try {
      const { name, email } = CurrentUser.load();
      AmplitudeService.setUserProperties({ email, name });
    } catch (error) {
      AmplitudeService.clearUserProperties();
    }
  },
  name: "App",
});
