import { actions } from "@/store/prefin/actions";
import { getters } from "@/store/prefin/getters";
import { mutations } from "@/store/prefin/mutations";
import { state } from "@/store/prefin/state";

export const prefin = {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
