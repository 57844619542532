const Users = () => import(/* webpackChunkName: "Users" */ "@/views/Users.vue");

export default [
  {
    component: Users,
    meta: { requiresAuth: true },
    name: "Users",
    path: "/users",
  },
];
