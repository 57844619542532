import { LoginState } from "@/@types/vuex";
import { MutationTree } from "vuex";

export enum LoginMutation {
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGOUT = "LOGOUT",
}
const mutations: MutationTree<LoginState> = {
  LOGIN_SUCCESS(state: LoginState, { token, user, roles }) {
    state.token = token;
    state.user = user;
    state.roles = roles;
    state.isLoggedIn = !!token;
  },
  LOGOUT(state: LoginState) {
    state.token = "";
    state.user = { email: "" };
  },
};

export default mutations;
