// https://app.swaggerhub.com/apis/TerraMagna/iam-service/2.1#/Roles
export enum Role {
  MANAGER = "MANAGER",
  ANALYST = "ANALYST",
  OPERATION_REQUESTER = "OPERATION_REQUESTER",
  OPERATION_REGISTER = "OPERATION_REGISTER",
  SALESMAN = "SALESMAN",
  SALES_ADMIN = "SALES_ADMIN",
  CARTOGRAPHY_REVIEWER = "CARTOGRAPHY_REVIEWER",
  CARTOGRAPHY_ANALYST = "CARTOGRAPHY_ANALYST",
  CREDIT_ANALYST = "CREDIT_ANALYST",
  CREDIT_MANAGER = "CREDIT_MANAGER",
  CUSTOMER_SUCCESS = "CUSTOMER_SUCCESS",
  CUSTOMER_SUCCESS_REGISTER = "CUSTOMER_SUCCESS_REGISTER",
  CREDIT_RISK_ANALYST = "CREDIT_RISK_ANALYST",
  CREDIT_LEGAL_RISK_ANALYST = "CREDIT_LEGAL_RISK_ANALYST",
  CREDIT_RISK_ANALYSIS_REQUESTER = "CREDIT_RISK_ANALYSIS_REQUESTER",
  BUSINESS_GROUPS_MANAGER = "BUSINESS_GROUPS_MANAGER",
  CREDIT_ENTITLEMENTS_DESPATCHER = "CREDIT_ENTITLEMENTS_DESPATCHER",
  CREDIT_ENTITLEMENTS_MANAGER = "CREDIT_ENTITLEMENTS_MANAGER",
  CREDIT_PORTFOLIOS_VIEWER = "CREDIT_PORTFOLIOS_VIEWER",
  CREDIT_PORTFOLIOS_MANAGER = "CREDIT_PORTFOLIOS_MANAGER",
  CREDIT_PORTFOLIOS_APPROVER = "CREDIT_PORTFOLIOS_APPROVER",
  PROFILE_VIEWER = "PROFILE_VIEWER",
  PROFILE_MANAGER = "PROFILE_MANAGER",
  SERPRO_VIEWER = "SERPRO_VIEWER",
  SERPRO_SUBMITTER = "SERPRO_SUBMITTER",
  PARTNER_ACE = "PARTNER_ACE",
  FARMER_ANALYSIS_MANAGER = "FARMER_ANALYSIS_MANAGER",
}

export enum RoleTranslate {
  MANAGER = "Gestor de usuários",
  ANALYST = "Analista de cobrança",
  OPERATION_REQUESTER = "Gestor de operações",
  OPERATION_REGISTER = "Cadastro de operações",
  SALESMAN = "Executivo de vendas",
  SALES_ADMIN = "Gerente de vendas",
  CARTOGRAPHY_REVIEWER = "Revisor de geometria",
  CARTOGRAPHY_ANALYST = "Analista de cartografia",
  CREDIT_ANALYST = "Analista de crédito",
  CREDIT_MANAGER = "Gerente de crédito",
  CUSTOMER_SUCCESS = "Analista de CS",
  CUSTOMER_SUCCESS_REGISTER = "Analista de cadastro de CS",
  CREDIT_RISK_ANALYST = "Analista de risco (crédito)",
  CREDIT_LEGAL_RISK_ANALYST = "Analista de risco jurídico (crédito)",
  CREDIT_RISK_ANALYSIS_REQUESTER = "Solicitante de análises de crédito",
  BUSINESS_GROUPS_MANAGER = "Gestor de grupos econômicos",
  CREDIT_ENTITLEMENTS_DESPATCHER = "Remetente de títulos de crédito",
  CREDIT_ENTITLEMENTS_MANAGER = "Gestor de títulos de crédito",
  CREDIT_PORTFOLIOS_VIEWER = "Visualizador de portfólios de crédito",
  CREDIT_PORTFOLIOS_MANAGER = "Gestor de portfólios de crédito",
  PROFILE_VIEWER = "Visualizador de cadastro",
  PROFILE_MANAGER = "Gestor de cadastro",
  SERPRO_VIEWER = "Visualizador requisições SERPRO",
  SERPRO_SUBMITTER = "Gestor requisições SERPRO",
  PARTNER_ACE = "Parceiro ACE",
  FARMER_ANALYSIS_MANAGER = "Gestor de análises do produtor",
  CREDIT_PORTFOLIOS_APPROVER = "Aprovador de portfólios de crédito",
}

export enum RoleDescription {
  MANAGER = "Permite adicionar e excluir usuários.",
  ANALYST = "Permite visualizar as operações que estão em monitoramento.",
  OPERATION_REQUESTER = "Permite pedir novas operações.",
  OPERATION_REGISTER = "Permite cadastrar operações no sistema.",
  SALESMAN = "Permite criar novos clientes.",
  SALES_ADMIN = "Permite ativar ou desativar clientes.",
  CARTOGRAPHY_REVIEWER = "Permite pedir revisões de geometrias nas fazendas em monitoramento.",
  CARTOGRAPHY_ANALYST = "Permite realizar tarefas da cartografia.",
  CREDIT_ANALYST = "Permite simular análises de crédito para o produtor e consultar restrições no CPF/CNPJ.",
  CREDIT_MANAGER = "Permite enviar configurações para análises de crédito.",
  CUSTOMER_SUCCESS = "Permite criar clientes, editar operações e análises de crédito",
  CUSTOMER_SUCCESS_REGISTER = "Permite criar clientes e editar operações de monitoramento",
  CREDIT_RISK_ANALYST = "Permite realizar tarefas de análise de risco de crédito",
  CREDIT_LEGAL_RISK_ANALYST = "Permite realizar tarefas de análise de risco jurídico",
  CREDIT_RISK_ANALYSIS_REQUESTER = "Permite solicitar análises de crédito para indivíduos/empresas",
  BUSINESS_GROUPS_MANAGER = "Permite criar e editar grupos econômicos",
  CREDIT_ENTITLEMENTS_DESPATCHER = "Permite enviar títulos de crédito (XMLs)",
  CREDIT_ENTITLEMENTS_MANAGER = "Permite gerenciar e inspecionar lotes de títulos de crédito (XMLs",
  CREDIT_PORTFOLIOS_VIEWER = "Permite visualizar portfólios de crédito",
  CREDIT_PORTFOLIOS_MANAGER = "Permite criar e editar portfólios de crédito",
  PROFILE_VIEWER = "Permite visualizar informações de cadastro de entidades legais",
  PROFILE_MANAGER = "Permite editar instrumentos financeiros e bloqueios de uma entidade legal",
  SERPRO_VIEWER = "Permite visualizar requisições para SERPRO",
  SERPRO_SUBMITTER = "Permite fazer requisições para SERPRO",
  PARTNER_ACE = "Permite acessar o conteúdo para ACE na aplicação de parceiros",
  FARMER_ANALYSIS_MANAGER = "Permite visualizar e criar novas análises do produtor",
  CREDIT_PORTFOLIOS_APPROVER = "Permite aprovar portfólios de crédito",
}
