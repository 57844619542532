import { FarmRegistrationTask, TaskStatus } from "@/services/farms/schemas";

export interface FarmRegistrationState {
  tasks: Record<TaskStatus, Map<string, FarmRegistrationTask>>;
}

export const state: FarmRegistrationState = {
  tasks: {
    PENDING: new Map(),
    REGISTERED: new Map(),
  },
};
