// https://app.swaggerhub.com/apis/TerraMagna/onboarding-service/2.3#/RefusalType
export enum RefusalType {
  FARM_MISSING_GEOGRAPHIC_INFORMATION = "FARM_MISSING_GEOGRAPHIC_INFORMATION",
  FARM_MISSING_DOCUMENT_NUMBER = "FARM_MISSING_DOCUMENT_NUMBER",
  FARM_MISSING_REFERENCE_POINT = "FARM_MISSING_REFERENCE_POINT",
  FARM_MISSING_GEOREFERENCED_DOCUMENT = "FARM_MISSING_GEOREFERENCED_DOCUMENT",
  FARM_INCONSISTENCY_INFORMATION = "FARM_INCONSISTENCY_INFORMATION",
}

export enum RefusalTypeTranslate {
  FARM_MISSING_GEOGRAPHIC_INFORMATION = "Matrícula não possui informação geográfica.",
  FARM_MISSING_DOCUMENT_NUMBER = "Número do SIGEF/INCRA/CCIR não encontrado.",
  FARM_MISSING_REFERENCE_POINT = "Croqui não possui referência de localização. Precisamos de um ponto de latitude e longitude.",
  FARM_MISSING_GEOREFERENCED_DOCUMENT = "Não foram fornecidos documentos de georreferenciamento.",
  FARM_INCONSISTENCY_INFORMATION = "Existe discordância entre insumos enviados.",
}

export enum GeoJsonFileName {
  OTHER_USAGES = "other_usage",
  PERIMETER = "perimeter",
  PRESERVATION = "preservation",
  STANDS = "stands",
}

export enum FarmGeometryKeys {
  OTHER_USAGES = "otherUses",
  PERIMETER = "perimeter",
  PRESERVATION = "preservation",
  STANDS = "stands",
}
