import { fillObject } from "@/utils/object";
import { ITaskViewURLParams } from "@/@types/manualAnalysis/panel";

// This order should be maintained to preserve the FilterChips.vue order
export enum FilterParams {
  DUE_DATE_VALUE = "dueDateValue",
  DUE_DATE_TYPE = "dueDateType",
  TYPE = "type",
  CLIENTS = "clients",
  SEASON = "season",
  ANALYST = "analyst",
  COMPLETION_TIME_VALUE = "byCompletionTime",
}

// This order should be maintained to preserve the DueDateFilter.vue order
export enum DateOption {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  THIS_WEEK = "THIS_WEEK",
  SPECIFIC_DATES = "SPECIFIC_DATES",
}

export enum DateOptionTranslate {
  TODAY = "Hoje",
  YESTERDAY = "Ontem",
  THIS_WEEK = "Essa semana",
  SPECIFIC_DATES = "Data específica",
}

export const FILTER_PARAMS = (): FilterParams[] => Object.values(FilterParams);

export const DefaultFilterParams = (): ITaskViewURLParams =>
  fillObject(Object.values(FilterParams), "");
