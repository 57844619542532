import {
  ICreateUpdateOperationRegister,
  IEnrichedOperationRegisterFormatted,
  IOperationRequestFormatted,
} from "@/@types/onboarding/operation";
import { IRefusalsMap, OnboardingState, RootState } from "@/@types/vuex";
import { GetterTree } from "vuex";
import { IPreFinRequestFormatted } from "@/@types/onboarding/preFinancing";

const getters: GetterTree<OnboardingState, RootState> = {
  operationCancelledRequests: (
    state: OnboardingState,
  ): IOperationRequestFormatted[] => {
    return state.operation.requestsByTab.CANCELLED;
  },
  operationPendingRequests: (state: OnboardingState): IOperationRequestFormatted[] => {
    return state.operation.requestsByTab.PENDING;
  },
  operationRefusals: (state: OnboardingState): IRefusalsMap => state.operation.refusals,
  operationRegisterById:
    (state: OnboardingState) =>
    (
      registerId: string,
    ): IEnrichedOperationRegisterFormatted | IOperationRequestFormatted | undefined => {
      const { REGISTERED, CANCELLED } = state.operation.requestsByTab;
      return (
        REGISTERED.find(({ id }) => id === registerId) ||
        CANCELLED.find(({ id }) => id === registerId)
      );
    },
  operationRegisteredRequests: (
    state: OnboardingState,
  ): IEnrichedOperationRegisterFormatted[] => {
    return state.operation.requestsByTab.REGISTERED;
  },
  operationRequestById:
    (state: OnboardingState) =>
    (requestId: string): IOperationRequestFormatted | undefined => {
      const { PENDING, CANCELLED } = state.operation.requestsByTab;
      return (
        PENDING.find(({ id }) => id === requestId) ||
        CANCELLED.find(({ id }) => id === requestId)
      );
    },
  operationRequestRegisterStore: (
    state: OnboardingState,
  ): ICreateUpdateOperationRegister => {
    return state.operation.requestRegisterStore;
  },
  operationSelectedRegister: (
    state: OnboardingState,
  ): IEnrichedOperationRegisterFormatted => {
    return state.operation.selectedRegister;
  },
  preFinRequestById:
    (state: OnboardingState) =>
    (requestId: string): IPreFinRequestFormatted | undefined => {
      return state.preFinancing.requests.find(({ id }) => id === requestId);
    },
  preFinSelectedRequest: (state: OnboardingState): IPreFinRequestFormatted => {
    return state.preFinancing.selectedRequest;
  },
};
export default getters;
