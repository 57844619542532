export enum OperationCancelReason {
  MISSING_DOCUMENTS = "MISSING_DOCUMENTS",
  DUPLICATED_REQUEST = "DUPLICATED_REQUEST",
  REGISTERED_IN_FUTURE_SEASON = "REGISTERED_IN_FUTURE_SEASON",
  CUSTOMER_SOLICITATION = "CUSTOMER_SOLICITATION",
  ALL_FARMS_HAVE_BEEN_CANCELLED = "ALL_FARMS_HAVE_BEEN_CANCELLED",
}

export enum OperationCancelReasonTranslate {
  MISSING_DOCUMENTS = "A operação não contém documentos",
  DUPLICATED_REQUEST = "Operação duplicada",
  REGISTERED_IN_FUTURE_SEASON = "Operação cadastrada de Safra futura",
  CUSTOMER_SOLICITATION = "Cliente não quer mais monitorar a operação",
  ALL_FARMS_HAVE_BEEN_CANCELLED = "Todas as fazendas foram canceladas",
}

export enum PreFinCancelReason {
  REGISTRATION_ERROR = "REGISTRATION_ERROR",
  DUPLICATED_REQUEST = "DUPLICATED_REQUEST",
  UNIFIED_REQUEST = "UNIFIED_REQUEST",
  CUSTOMER_SOLICITATION = "CUSTOMER_SOLICITATION",
}

export enum PreFinCancelReasonTranslate {
  REGISTRATION_ERROR = "Pedido cadastrado errado",
  DUPLICATED_REQUEST = "Pedido duplicado",
  UNIFIED_REQUEST = "Pedido unificado a outro pedido",
  CUSTOMER_SOLICITATION = "Exclusão solicitada pelo cliente",
}
