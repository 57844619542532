import { Geometry, GeometryType } from "@/@types/geoJson";
import {
  IAnalysisResults,
  IDelimitationConclusion,
  IDelimitationGeoJsonErrors,
} from "@/@types/manualAnalysis/analysis";
import { ManualAnalysisDelimitationState, ManualAnalysisState } from "@/@types/vuex";

import { DEFAULT_CHART_LIST_ITEM_SIZE } from "@/constants/manual-analysis/manualAnalysisChartList";

const changedAnalyses: IAnalysisResults[] = [];
const emptyGeometry: Geometry = { coordinates: [], type: GeometryType.MULTI_POLYGON };

export const analysis: IDelimitationConclusion = {
  identificationSources: [],
  otherUsages: undefined,
  perimeter: emptyGeometry,
  preservation: undefined,
  stands: undefined,
};

export const errors: IDelimitationGeoJsonErrors = {
  otherUsages: [],
  perimeter: [],
  preservation: [],
  stands: [],
};

const emptyDelimitationState: ManualAnalysisDelimitationState = {
  analysis,
  errors,
};

export default {
  changedAnalyses,
  chartListItemsSize: DEFAULT_CHART_LIST_ITEM_SIZE,
  delimitation: emptyDelimitationState,
  unchangedCharts: [],
} as ManualAnalysisState;
