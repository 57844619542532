import { AnalystState, IAnalystMap, RootState } from "@/@types/vuex";
import { ActionTree } from "vuex";
import { AnalystMutation } from "./mutations";
import analystService from "@/services/analyst";
import { arrayToHashMap } from "@/utils/map";
import { isObjectEmpty } from "@/utils/object";

export enum AnalystAction {
  GET_ANALYSTS = "getAnalysts",
  SYNC_ANALYSTS_STORE = "syncAnalystsStore",
}

const actions: ActionTree<AnalystState, RootState> = {
  async [AnalystAction.GET_ANALYSTS]({ dispatch, state }): Promise<IAnalystMap> {
    if (!isObjectEmpty(state.analysts)) return state.analysts;
    return await dispatch(AnalystAction.SYNC_ANALYSTS_STORE);
  },
  async [AnalystAction.SYNC_ANALYSTS_STORE]({ commit }): Promise<IAnalystMap> {
    const { analysts } = await analystService.getAnalysts();
    const analystsMap = arrayToHashMap("id", analysts);
    commit(AnalystMutation.SET_ANALYSTS, analystsMap);
    return analystsMap;
  },
};

export default actions;
