import { DEFAULT_EMPTY_SUMMARY, RequestType } from "@/constants/onboarding/requests";
import {
  emptyOperationFarmRequest,
  emptyRequestRegisterStore,
  emptySelectedRegister,
} from "./state";
import {
  ICreateUpdateDeleteFarmRequestsPayload,
  ICreateUpdateOperationRegister,
  IEnrichedOperationRegisterFormatted,
  IFarmRequest,
  IGetOperationArgsProps,
  IOperation,
  IOperationRegister,
  IOperationRegisterCreatePayload,
  IOperationRegisterFormatted,
  IOperationRegisterUpdatePayload,
  IOperationRequest,
  IOperationRequestFormatted,
  IOperationRequests,
} from "@/@types/onboarding/operation";
import {
  IMunicipality,
  IPatchRequest,
  IRequestsViewPageSummary,
  OnboardingRegister,
  OnboardingRegisterFormatted,
  OnboardingRequest,
  OnboardingRequestFormatted,
} from "@/@types/onboarding/shared";
import { IOperationsMap, OnboardingState, RootState } from "@/@types/vuex";
import {
  IPreFinRegisterFormatted,
  IPreFinRequest,
  IPreFinRequestFormatted,
} from "@/@types/onboarding/preFinancing";

import {
  OperationCancelReason,
  PreFinCancelReason,
} from "@/constants/shared/cancelReason";
import { ActionTree } from "vuex";
import { ISearchValueData } from "@/@types/manualAnalysis/geometryReuse";
import { isoDateToBrazilianDateTime } from "@/utils/date";
import { isOperationRegister } from "@/utils/onboardingRequest";
import { NULL_STRING } from "@/constants/shared/strings";
import { OnboardingMutation } from "./mutations";
import onboardingService from "@/services/onboarding";
import { RequestStatus } from "@/constants/shared/requestStatus";
import { requestStatusTranslate } from "@/utils/translations";
import { State } from "@/constants/shared/location";
import { wordCompare } from "@/utils/string";

const actions: ActionTree<OnboardingState, RootState> = {
  async cancelRequest(
    _,
    payload: {
      requestId: string;
      cancelReasons: (OperationCancelReason | PreFinCancelReason)[];
    },
  ): Promise<IOperationRegister | IPreFinRequest> {
    const { requestId, cancelReasons } = payload;
    const cancelledRequest = await onboardingService.cancelRequest(
      requestId,
      cancelReasons,
    );
    return cancelledRequest;
  },
  cleanFarmRequest({ commit }) {
    commit(OnboardingMutation.SET_OPERATION_FARM_REQUEST, emptyOperationFarmRequest);
  },
  async cleanRequestRegister({ commit, dispatch }) {
    commit(
      OnboardingMutation.SET_OPERATION_REQUEST_REGISTER_STORE,
      emptyRequestRegisterStore,
    );
    commit(OnboardingMutation.SET_OPERATION_FARM_REQUESTS, []);
    await dispatch("cleanSelectedRequest");
  },
  cleanSelectedRequest({ commit }) {
    commit(OnboardingMutation.SET_OPERATION_SELECTED_REGISTER, emptySelectedRegister);
  },
  async createOperationRegister(
    _,
    payload: { requestId: string; register: IOperationRegisterCreatePayload },
  ): Promise<IOperationRegister> {
    const { requestId, register } = payload;
    const createdRegister = await onboardingService.createOperationRegister(
      requestId,
      register,
    );
    return createdRegister;
  },
  async createUpdateDeleteFarmRequests(
    _,
    payload: {
      operationId: string;
      data: ICreateUpdateDeleteFarmRequestsPayload;
    },
  ): Promise<IFarmRequest[]> {
    const { operationId, data } = payload;
    const { farmRequests } = await onboardingService.createUpdateDeleteFarmRequests(
      operationId,
      data,
    );

    return farmRequests;
  },
  async editOperationRequest(
    _,
    payload: { operationId: string; register: IOperationRegisterCreatePayload },
  ) {
    const { operationId, register } = payload;
    return await onboardingService.editOperationRegister(operationId, register);
  },
  async getFarmRequests({ commit }, requestId: string): Promise<IFarmRequest[]> {
    const { farmRequests } = await onboardingService.getFarmRequests(requestId);
    commit(OnboardingMutation.SET_OPERATION_FARM_REQUESTS, farmRequests);
    return farmRequests;
  },
  async getMunicipalities(_, state: State): Promise<IMunicipality[]> {
    const { municipalities } = await onboardingService.getMunicipalities(state);
    return municipalities.sort((a: IMunicipality, b: IMunicipality) =>
      wordCompare(a.name, b.name),
    );
  },
  async getOperationCancelReasons(
    _,
    requestId: string,
  ): Promise<OperationCancelReason[]> {
    return (await onboardingService.getOperationCancelReasons(requestId)).reasons;
  },
  async getPaginatedOperationRequests(
    { commit, dispatch, rootGetters },
    params: IGetOperationArgsProps,
  ): Promise<IOperationRequests> {
    const clientById = rootGetters["client/clientById"];
    const userById = rootGetters["user/userById"];

    const formatSummary = (
      summary: IRequestsViewPageSummary = DEFAULT_EMPTY_SUMMARY,
    ): IRequestsViewPageSummary => ({
      byTab: {
        CANCELLED: summary.byTab.CANCELLED || 0,
        PENDING: summary.byTab.PENDING || 0,
        REGISTERED: summary.byTab.REGISTERED || 0,
      },
      total: summary.total,
    });

    const getOperationIds = (requests: (IOperationRequest | IOperationRegister)[]) => {
      const operationIds = [];
      for (const request of requests) {
        isOperationRegister(request) && operationIds.push(request.operationId);
      }
      return operationIds;
    };

    const page = await onboardingService.getRequestsViewPage(params);
    const operationIds = getOperationIds(page.requests);
    const operationsMap: IOperationsMap = await dispatch(
      "operation/getOperations",
      operationIds,
      { root: true },
    );

    const toFormattedRequest = (
      request: IOperationRequest | IOperationRegister,
    ): IOperationRequestFormatted | IEnrichedOperationRegisterFormatted => {
      const formattedRequest = _formatRequest(request, clientById, userById) as
        | IOperationRequestFormatted
        | IOperationRegisterFormatted;

      if (!isOperationRegister(formattedRequest)) return formattedRequest;
      const operation = operationsMap[formattedRequest.operationId];

      return _mergeRegisterToOperation(operation, formattedRequest);
    };

    const formattedRequests: IOperationRequests = {
      request: page.requests.map(toFormattedRequest),
      summary: formatSummary(page.summary),
    };

    commit(OnboardingMutation.SET_OPERATION_REQUEST, formattedRequests);

    return { ...formattedRequests, links: page.links };
  },
  async getPreFinRequests(
    { commit, rootGetters },
    status: RequestStatus[],
  ): Promise<(IPreFinRequestFormatted | IPreFinRegisterFormatted)[]> {
    const clientById = rootGetters["client/clientById"];
    const userById = rootGetters["user/userById"];
    const { requests } = await onboardingService.getRequests({
      status,
      type: RequestType.PRE_FIN,
    });

    const formattedRequests = requests.map((request) =>
      _formatRequest(request, clientById, userById),
    ) as (IPreFinRequestFormatted | IPreFinRegisterFormatted)[];

    commit(OnboardingMutation.SET_PRE_FIN_REQUESTS, formattedRequests);
    return formattedRequests;
  },
  async getRequest(
    { dispatch, rootGetters },
    requestId: string,
  ): Promise<OnboardingRequestFormatted | OnboardingRegisterFormatted> {
    const clientById = rootGetters["client/clientById"];
    const userById = rootGetters["user/userById"];

    const request = await onboardingService.getRequest(requestId);
    const formattedRequest = _formatRequest(request, clientById, userById);

    if (isOperationRegister(formattedRequest)) {
      const operation = await dispatch(
        "operation/getOperationById",
        formattedRequest.operationId,
        { root: true },
      );

      if (operation) {
        return _mergeRegisterToOperation(operation, formattedRequest);
      }
    }

    return formattedRequest;
  },
  async getRequestRefusals({ commit }, requestId: string) {
    const { refusals } = await onboardingService.getRequestRefusals(requestId);
    commit(OnboardingMutation.SET_OPERATION_SELECTED_REGISTER_REFUSALS, refusals);
  },
  selectPreFinRequest({ commit }, request: IPreFinRequestFormatted) {
    commit(OnboardingMutation.SET_PRE_FIN_SELECTED_REQUEST, request);
  },
  selectRegisterRequest({ dispatch, commit }, request: IOperationRegister) {
    commit(OnboardingMutation.SET_OPERATION_SELECTED_REGISTER, request);
    dispatch("setRequestRegisterStore", request);
  },
  async setFarmRequest({ commit }, farmRequestId: string): Promise<IFarmRequest> {
    const farmRequest = await onboardingService.getFarmRequestsById(farmRequestId);
    const formattedFarmRequest = _formatFarmRequest(farmRequest);
    commit(OnboardingMutation.SET_OPERATION_FARM_REQUEST, formattedFarmRequest);

    return formattedFarmRequest;
  },
  async setFarmRequestAsCancelled({ state, commit }, farmRequestId: string) {
    const canceled = await onboardingService.setFarmRequestAsCancelled(farmRequestId);
    const formattedFarmRequest = _formatFarmRequest(canceled);
    const requestRegisterStore = { ...state.operation.requestRegisterStore };
    const { farmRequests } = requestRegisterStore;
    const index = farmRequests.findIndex(({ id }) => id === farmRequestId);
    requestRegisterStore.farmRequests[index] = formattedFarmRequest;
    commit(
      OnboardingMutation.SET_OPERATION_REQUEST_REGISTER_STORE,
      requestRegisterStore,
    );
    return formattedFarmRequest;
  },
  async setPipefyId(_, payload: IPatchRequest): Promise<IPreFinRequest> {
    const { pipefyId, requestId } = payload;
    const preFinRequest = await onboardingService.patchRequest(requestId, {
      pipefyId,
    });
    return preFinRequest as IPreFinRequest;
  },
  setRequestRegisterStore({ commit }, request: IEnrichedOperationRegisterFormatted) {
    const requestRegisterStore: ICreateUpdateOperationRegister = {
      farmRequests: [],
      farmRequestsToCreate: [],
      farmRequestsToDelete: [],
      farmRequestsToEdit: [],
      requestId: request.id,
      ...request,
    };
    commit(
      OnboardingMutation.SET_OPERATION_REQUEST_REGISTER_STORE,
      requestRegisterStore,
    );
  },
  async setTerms(_, searchData: ISearchValueData) {
    return onboardingService.getFilteredFarms(searchData);
  },
  async updateOperationRegister({ commit }, register: IOperationRegisterUpdatePayload) {
    commit(OnboardingMutation.SET_OPERATION_REQUEST_REGISTER_STORE, register);
  },
  async updateRequestAttachments(
    _,
    payload: IPatchRequest,
  ): Promise<IOperationRegister> {
    const { attachments, requestId } = payload;
    const updatedRequest = (await onboardingService.patchRequest(requestId, {
      attachments,
    })) as IOperationRegister;
    return updatedRequest;
  },
};

const _mergeRegisterToOperation = (
  operation: IOperation,
  register: IOperationRegisterFormatted,
) => {
  return {
    ...operation,
    ...register,
  };
};

const _formatFarmRequest = (farmRequest: IFarmRequest): IFarmRequest => {
  return {
    ...farmRequest,
    car: farmRequest.car || NULL_STRING,
    sigef: farmRequest.sigef || NULL_STRING,
  };
};

const _formatRequest = (
  request: OnboardingRequest | OnboardingRegister,
  clientById: Function,
  userById: Function,
): OnboardingRequestFormatted | OnboardingRegisterFormatted => {
  const {
    clientId,
    creatorId,
    updateTime,
    externalId,
    keyAccountManagerId,
    registrationTime,
    creationTime,
    status,
  } = request;

  const client = clientById(clientId);
  const user = userById(creatorId);
  const keyAccountManager = userById(keyAccountManagerId);

  return {
    ...request,
    clientName: client?.pretty_name || NULL_STRING,
    creatorEmail: user?.email || NULL_STRING,
    creatorName: user?.name || NULL_STRING,
    externalId: externalId || NULL_STRING,
    formattedRequestType: NULL_STRING,
    formattedSeasonCrop: NULL_STRING,
    keyAccountManagerName: keyAccountManager?.name || NULL_STRING,
    translatedDate: isoDateToBrazilianDateTime(creationTime),
    translatedRegistrationTime: isoDateToBrazilianDateTime(registrationTime),
    translatedStatus: requestStatusTranslate(status as RequestStatus),
    translatedUpdateTime: isoDateToBrazilianDateTime(updateTime),
  };
};

export default actions;
