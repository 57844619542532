import { requestStatusTranslate, seasonCropTypeTranslate } from "@/utils/translations";
import { ActionTree } from "vuex";
import { AnalysisRequest } from "@/services/prefin/schemas/analysisRequest";
import { AnalysisRequestStatus } from "@/services/prefin/types/status";
import { AnalysisTypeTranslate } from "@/services/prefin/types/analysisType";
import { Crop } from "@/services/prefin/types/crop";
import { formatCpfOrCnpj } from "@/utils/cpfCnpj";
import { IPreFinRequestFormatted } from "@/@types/onboarding/preFinancing";
import { isoDateToBrazilianDateTime } from "@/utils/date";
import { NULL_STRING } from "@/constants/shared/strings";
import { PreFinCancelReason } from "@/constants/shared/cancelReason";
import { PrefinMutation } from "@/store/prefin/mutations";
import { prefinService } from "@/services/prefin";
import { PrefinState } from "@/store/prefin/state";
import { RefusalReason } from "@/services/prefin/types/refusalReason";
import { RequestStatus } from "@/constants/shared/requestStatus";
import { RequestType } from "@/constants/onboarding/requests";
import { RootState } from "@/@types/vuex";
import { SeasonCropType } from "@/constants/shared/financial";

type RefuseRequestPayload = {
  id: string;
  reasons: (RefusalReason | PreFinCancelReason)[];
};

type UpdateRequestPipefyIdPayload = {
  id: string;
  pipefyId: string;
};

export const actions: ActionTree<PrefinState, RootState> = {
  async cancelRequestById({ dispatch }, id: string): Promise<void> {
    await prefinService.cancelRequestById(id);

    await dispatch("fetchAllRequestsByStatus");
  },

  async fetchAllRequestsByStatus(
    { commit, rootGetters, dispatch },
    params?: {
      after?: string;
      before?: string;
      status?: AnalysisRequestStatus[];
    },
  ): Promise<IPreFinRequestFormatted[]> {
    const clientById = rootGetters["client/clientById"];
    const userById = rootGetters["user/userById"];
    const legalEntity = rootGetters["legalEntity/getEntityById"];
    const requests = await prefinService.fetchAllRequestsByStatus(
      params?.status,
      params?.after,
      params?.before,
    );

    await dispatch("legalEntity/fetchAllLegalEntities", null, {
      root: true,
    });

    const formattedRequests = requests.map(
      (request): IPreFinRequestFormatted =>
        _formatRequest(request, clientById, userById, legalEntity),
    ) as IPreFinRequestFormatted[];
    commit(PrefinMutation.SET_REQUESTS, formattedRequests);
    return formattedRequests;
  },

  async refuseRequestById(_, { id, reasons }: RefuseRequestPayload): Promise<void> {
    if (reasons.includes(PreFinCancelReason.CUSTOMER_SOLICITATION)) {
      await prefinService.cancelRequestById(id);
    }
    const reasonsToRefuse = _reasonsToRefuse(reasons);
    if (reasonsToRefuse.length > 0) {
      await prefinService.refuseRequestById(id, reasonsToRefuse);
    }
  },

  selectRequest({ commit }, request: IPreFinRequestFormatted) {
    commit(PrefinMutation.SET_SELECTED_REQUEST, request);
  },

  async updateRequestPipefyId(
    _,
    { id, pipefyId }: UpdateRequestPipefyIdPayload,
  ): Promise<void> {
    await prefinService.updateRequestPipefyId(id, pipefyId);
  },
};

const _reasonsToRefuse = (
  reasons: (RefusalReason | PreFinCancelReason)[],
): RefusalReason[] =>
  reasons.filter((reason) =>
    Object.keys(RefusalReason).includes(reason),
  ) as RefusalReason[];

const _formatRequest = (
  request: AnalysisRequest,
  clientById: Function,
  userById: Function,
  legalEntityById: Function,
): IPreFinRequestFormatted => {
  const {
    group: {
      clientLegalEntityId,
      creatorId,
      externalId,
      creationTime,
      season,
      analysisType,
      comments,
      producerLegalEntityId,
      producerName,
    },
    status,
    internalRegistrationTime,
    serialId,
    updateTime,
    id,
    internalPipefyId,
    attachments,
    pledgeArea,
  } = request;

  const producer = legalEntityById(producerLegalEntityId);
  const client = clientById(clientLegalEntityId);
  const user = userById(creatorId);

  return {
    clientId: client?.id,
    clientName: client?.pretty_name || NULL_STRING,
    creationTime: creationTime as unknown as string,
    creatorEmail: user?.email || NULL_STRING,
    creatorId: user?.id,
    creatorName: user?.name || NULL_STRING,
    externalId: externalId || NULL_STRING,
    farmsAttachments: attachments,
    farmsComment: comments,
    formattedNationalRegistration: producer?.nationalRegistration
      ? formatCpfOrCnpj(producer.nationalRegistration)
      : NULL_STRING,
    formattedRequestType: AnalysisTypeTranslate[analysisType],
    formattedSeasonCrop: _seasonTranslator(season),
    id,
    keyAccountManagerId: NULL_STRING,
    keyAccountManagerName: NULL_STRING,
    pipefyId: `${internalPipefyId || NULL_STRING}`,
    pledgeArea: pledgeArea,
    producerName: producerName || producer?.name || NULL_STRING,
    registrationUserName: producerName || producer?.name || NULL_STRING,
    serialId: serialId || 0,
    sortableRegistrationTime: internalRegistrationTime,
    status: status as unknown as RequestStatus,
    translatedDate: isoDateToBrazilianDateTime(creationTime.toLocaleString()),
    translatedRegistrationTime: isoDateToBrazilianDateTime(
      internalRegistrationTime?.toLocaleString(),
    ),
    translatedStatus: requestStatusTranslate(status as unknown as RequestStatus),
    translatedUpdateTime: isoDateToBrazilianDateTime(updateTime?.toLocaleString()),
    type: RequestType.OPERATION,
  };
};

const _seasonTranslator = (season?: { crop: Crop; year: number }): string => {
  return season
    ? `${seasonCropTypeTranslate(season.crop as unknown as SeasonCropType)} ${
        season.year
      }`
    : NULL_STRING;
};
