import actions from "@/store/files/actions";
import getters from "@/store/files/getters";
import mutations from "@/store/files/mutations";
import state from "@/store/files/state";

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
