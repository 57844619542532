





































































import { CurrentUser } from "@/business/CurrentUser";
import { mapGetters } from "vuex";
import { Role } from "@/constants/shared/role";
import Vue from "vue";

interface NavigationMenuItem {
  color: string;
  name: string;
  icon: string;
  to: string;
  roles?: Role[];
  dataTestId: string;
}

export default Vue.extend({
  computed: {
    ...mapGetters({ userRoles: "login/roles" }),
    filteredItemsByUserRoles(): NavigationMenuItem[] {
      const user = CurrentUser.load();
      const byRoles = ({ roles }: NavigationMenuItem) => user.isAllowed(roles || []);

      return this.items.filter(byRoles);
    },
    isEnvDev(): boolean {
      return process.env.NODE_ENV === "development";
    },
    lightTitle(): string {
      return "BACKOFFICE" + (this.isEnvDev ? " HOMOLOGAÇÃO" : "");
    },
  },
  created() {
    window.addEventListener("click", this.handleRetractDrawer);
  },
  data() {
    return {
      iconColor: "#000",
      items: [
        {
          dataTestId: "activities-panel-option",
          icon: "assignment",
          name: "Painel de Atividades",
          to: "/manual-analysis-panel",
        },
        {
          dataTestId: "clients-option",
          icon: "people",
          name: "Clientes",
          to: "/clients",
        },
        {
          dataTestId: "pre-fin-option",
          icon: "monetization_on",
          name: "Pré-financiamento",
          to: "/onboarding/pre-financing",
        },
        {
          dataTestId: "operation-register-option",
          icon: "post_add",
          name: "Cadastros de Operações",
          to: "/onboarding/operation",
        },
        {
          icon: "playlist_add",
          name: "Cadastros de Fazendas",
          to: "/farms/orders",
        },
        {
          dataTestId: "users-option",
          icon: "people",
          name: "Gestão de Usuários",
          to: "/users",
        },
      ] as NavigationMenuItem[],
      mini: true,
    };
  },
  methods: {
    getItemStyle({ color }: NavigationMenuItem): string {
      return color ? `color: ${color}` : "";
    },
    handleRetractDrawer() {
      this.mini = true;
    },
    handleToggleDrawer() {
      this.mini = !this.mini;
    },
  },
  name: "TheNavbar",
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },
});
