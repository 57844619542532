import { GetterTree } from "vuex";
import { LegalEntity } from "@/services/legalEntity/schemas/legalEntity";
import { LegalEntityState } from "@/store/legalEntity/state";
import { RootState } from "@/@types/vuex";

export const getters: GetterTree<LegalEntityState, RootState> = {
  getEntities: (state: LegalEntityState) => {
    return (): Map<string, LegalEntity> => state.entities;
  },
  getEntityById: (state: LegalEntityState) => {
    return (id: string): LegalEntity | undefined => state.entities.get(id);
  },
};
