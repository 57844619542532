import actions from "@/store/manual-analysis/actions";
import getters from "@/store/manual-analysis/getters";
import mutations from "@/store/manual-analysis/mutations";
import state from "@/store/manual-analysis/state";

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
