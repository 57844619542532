import { ITaskSummaryView, ITaskViewsParams } from "@/@types/manualAnalysis/panel";
import axios from "axios";
import { ServiceError } from "@/@types/service/service";

const API = process.env.VUE_APP_MANUAL_ANALYSIS_API;

const manualAnalysisPanelService = {
  // https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.7#/Tasks/getTaskViews
  async getTaskViews(payload: ITaskViewsParams): Promise<ITaskSummaryView> {
    if (!payload.start || !payload.end) {
      throw new Error(ServiceError.MISSING_REQUIRED_PARAMS);
    }

    const endpoint = `${API}/views/queuePage`;
    const response = await axios.get<ITaskSummaryView>(endpoint, { params: payload });

    return response.data;
  },
};

export default manualAnalysisPanelService;
