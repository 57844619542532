var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { style: _vm.cssColorVariables },
    [_c("router-view"), _c("AppSnackbar")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }