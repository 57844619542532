import clientsRoutes, { ClientsRoute } from "./clients";
import loginRoutes, { LoginRoute } from "./login";
import farmsRoutes from "@/router/farmsRegister";
import Home from "@/views/Home.vue";
import manualAnalysisPanelRoutes from "./manualAnalysisPanel";
import manualAnalysisRoutes from "./manualAnalysis";
import notFoundRoute from "@/router/notFound";
import onboardingRoutes from "./onboarding";
import store from "@/store";
import usersRoutes from "./users";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const INITIAL_ROUTE = ClientsRoute.CLIENTS;

export enum HomeRoute {
  HOME = "home",
}

const routes = [
  ...loginRoutes,
  {
    children: [
      ...onboardingRoutes,
      ...clientsRoutes,
      ...manualAnalysisRoutes,
      ...manualAnalysisPanelRoutes,
      ...usersRoutes,
      ...farmsRoutes,
      notFoundRoute,
    ],
    component: Home,
    meta: {
      requiresAuth: true,
    },
    name: HomeRoute.HOME,
    path: "/",
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const isUserLoggedIn = store.getters["login/isLoggedIn"];

  // Redirect to initial page if user is already logged in
  // We need to use path == "/" here because is a root thing
  if (isUserLoggedIn && (to.path === "/" || to.name === LoginRoute.LOGIN)) {
    return next({
      name: INITIAL_ROUTE,
      query: from.params,
    });
  }

  // Redirect to login if user is not logged in
  if (to.meta?.requiresAuth && !isUserLoggedIn) {
    // if user is not logged in, redirect to login.
    return next({
      name: LoginRoute.LOGIN,
      query: { redirect: to.fullPath },
    });
  }

  if (from.fullPath !== to.fullPath) next();
});

export default router;

export { ManualAnalysisRoute } from "./manualAnalysis";
export { ManualAnalysisPanelRoute } from "./manualAnalysisPanel";
export { LoginRoute } from "./login";
export { OnboardingRoute } from "./onboarding";
