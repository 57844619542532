import {
  ICreateUpdateOperationRegister,
  IEnrichedOperationRegisterFormatted,
  IFarmRequest,
  IOperationRegisterCreatePayload,
  IOperationRequestsByTabs,
} from "@/@types/onboarding/operation";
import { IProducer, IRefusal } from "@/@types/onboarding/shared";

import { arrayToHashMap } from "@/utils/map";
import { IPreFinRequestFormatted } from "@/@types/onboarding/preFinancing";
import { MutationTree } from "vuex";
import { OnboardingState } from "@/@types/vuex";
import Vue from "vue";

export enum OnboardingMutation {
  SET_OPERATION_FARM_REQUEST = "SET_OPERATION_FARM_REQUEST",
  SET_OPERATION_FARM_REQUESTS = "SET_OPERATION_FARM_REQUESTS",
  SET_OPERATION_PRODUCER = "SET_OPERATION_PRODUCER",
  SET_OPERATION_REQUEST = "SET_OPERATION_REQUEST",
  SET_OPERATION_REQUEST_REGISTER_STORE = "SET_OPERATION_REQUEST_REGISTER_STORE",
  SET_OPERATION_SELECTED_REGISTER = "SET_OPERATION_SELECTED_REGISTER",
  SET_OPERATION_SELECTED_REGISTER_REFUSALS = "SET_OPERATION_SELECTED_REGISTER_REFUSALS",
  SET_PRE_FIN_REQUESTS = "SET_PRE_FIN_REQUESTS",
  SET_PRE_FIN_SELECTED_REQUEST = "SET_PRE_FIN_SELECTED_REQUEST",
}

const mutations: MutationTree<OnboardingState> = {
  SET_OPERATION_FARM_REQUEST: (state: OnboardingState, farmRequest: IFarmRequest) => {
    Vue.set(state.operation, "farmRequest", farmRequest);
  },
  SET_OPERATION_FARM_REQUESTS: (
    state: OnboardingState,
    farmRequests: IFarmRequest[],
  ) => {
    Vue.set(state.operation, "farmRequests", farmRequests);
  },
  SET_OPERATION_PRODUCER: (state: OnboardingState, producer: IProducer) => {
    Vue.set(state.operation, "producer", producer);
  },
  SET_OPERATION_REQUEST: (
    state: OnboardingState,
    payload: IOperationRequestsByTabs,
  ) => {
    Vue.set(state.operation, "requestsByTab", payload);
  },
  SET_OPERATION_REQUEST_REGISTER_STORE: (
    state: OnboardingState,
    requestRegisterStore:
      | ICreateUpdateOperationRegister
      | IOperationRegisterCreatePayload,
  ) => {
    Vue.set(state.operation, "requestRegisterStore", requestRegisterStore);
  },
  SET_OPERATION_SELECTED_REGISTER: (
    state: OnboardingState,
    register: IEnrichedOperationRegisterFormatted,
  ) => {
    Vue.set(state.operation, "selectedRegister", register);
  },
  SET_OPERATION_SELECTED_REGISTER_REFUSALS: (
    state: OnboardingState,
    refusals: IRefusal[],
  ) => {
    const refusalsMap = arrayToHashMap("farmRequestId", refusals);
    Vue.set(state.operation, "refusals", refusalsMap);
  },
  SET_PRE_FIN_REQUESTS: (
    state: OnboardingState,
    requests: IPreFinRequestFormatted[],
  ) => {
    Vue.set(state.preFinancing, "requests", requests);
  },
  SET_PRE_FIN_SELECTED_REQUEST: (
    state: OnboardingState,
    request: IPreFinRequestFormatted,
  ) => {
    Vue.set(state.preFinancing, "selectedRequest", request);
  },
};

export default mutations;
