import { Client, ClientBase, ClientModel, IUpdateClient } from "@/@types/client";
import axios from "axios";
import { HTTP_METHODS } from "@/@types/service/http";

const API_URL = `${process.env.VUE_APP_IAM_API}`;
const ONBOARDING_API_URL = `${process.env.VUE_APP_ONBOARDING_API}`;

const clientsService = {
  /**
   * Activates a client, allowing its users to login in the FIDES platform again.
   *
   * @param clientId: the identifier of the client to activate.
   */
  async activateClient(clientId: string): Promise<boolean> {
    await axios.post(`${API_URL}/clients/${clientId}:activate`);
    return true;
  },

  /**
   * Creates a new client in IAM service.
   *
   * @param client: the client to create.
   *
   * @returns the created client.
   */
  async createClient(client: Client): Promise<ClientModel> {
    const endpoint = `${API_URL}/clients`;
    const params = {
      cnpj: client.cnpj,
      name: client.pretty_name,
    };
    const response = await axios.post<ClientModel>(endpoint, null, { params });

    return response.data;
  },

  /**
   * Create a new client in the registry database.
   *
   * Should be called after calling `clientsService.createClient`.
   *
   * @param client: The client to create in the registry database.
   */
  async createClientInRegistry(client: ClientBase): Promise<void> {
    await axios.post(`${ONBOARDING_API_URL}/internal/clients`, client);
  },

  /**
   * Deactivates a specific client.
   *
   * After this, no user from this client will be able to login in the FIDES platform.
   *
   * @param clientId: the identifier of the client to disable.
   */
  async deactivateClient(clientId: string): Promise<boolean> {
    await axios.delete(`${API_URL}/clients/${clientId}`);
    return true;
  },

  /**
   * Get the options for a specific client.
   *
   * @param clientId: the client to fetch the options for.
   *
   * @returns the client's options.
   */
  async getClientOptions(clientId: string): Promise<ClientOptions> {
    const endpoint = `${API_URL}/internal/clients/${clientId}/options`;
    const response = await axios.get<Record<string, boolean>>(endpoint);

    return new ClientOptions(response.data);
  },

  /**
   * Gets a token for the internal user of a specific client.
   *
   * @param clientId: the client to fetch the token for.
   *
   * @returns the client's internal user token.
   */
  async getClientToken(clientId: string): Promise<string> {
    const endpoint = `${API_URL}/clients/${clientId}/token`;
    const response = await axios.get<{ token: string }>(endpoint);

    return response.data.token;
  },

  /**
   * Get all the clients.
   *
   * @returns all the created clients.
   */
  async getClients(): Promise<Client[]> {
    const endpoint = `${API_URL}/clients`;
    const response = await axios.get<{ clients: Client[] }>(endpoint);
    return response.data.clients;
  },

  /**
   * Get the options for the current logged client.
   *
   * @returns the client's options.
   */
  async getCurrentClientOptions(): Promise<ClientOptions> {
    const endpoint = `${API_URL}/clients/options`;
    const response = await axios.get<Record<string, boolean>>(endpoint);

    return new ClientOptions(response.data);
  },

  /**
   * Update the client's name and/or cnpj.
   *
   * @param clientId: the identifier of the client to update the options for.
   * @param options: the new options for the client.
   */
  async updateClient(clientId: string, options: IUpdateClient): Promise<ClientModel> {
    const body = {};

    if (options.cnpj) {
      Object.assign(body, { cnpj: options.cnpj });
    }

    if (options.name) {
      Object.assign(body, { name: options.name });
    }

    const res = await axios({
      data: body,
      method: HTTP_METHODS.PATCH,
      url: `${API_URL}/internal/clients/${clientId}`,
    });

    return res.data;
  },

  /**
   * Update the client's options.
   *
   * @param clientId: the identifier of the client to update the options for.
   * @param options: the new options for the client.
   */
  async updateClientOptions(clientId: string, options: ClientOptions): Promise<void> {
    await axios({
      data: options.patch,
      method: HTTP_METHODS.PATCH,
      url: `${API_URL}/internal/clients/${clientId}/options`,
    });

    options.clearPatch();
  },
};

export default clientsService;

/**
 * Possible values for a client options.
 */
export type ClientOptionValue = string | number | boolean | ClientOptionValue[];

/**
 * A client option, parametrized by its expected type.
 *
 * A client option is a generic way of storing information about a client.
 *
 * Each option should follow the following format:
 *
 * - They should start with `com` for proprietary options (e.g. `com.tm` for TerraMagna options);
 * - They should start with `org` for opensource options (e.g. `org.otel` for OpenTelemetry options);
 * - All segments should be in `camelCase`.
 */
export class ClientOption<T extends ClientOptionValue> {
  /**
   * Create an options that expects it's value to be a boolean.
   *
   * @param key: The option key.
   */
  public static boolean(key: string): ClientOption<boolean> {
    return new ClientOption(key, (val): val is boolean => typeof val == "boolean");
  }

  /**
   * Create an options that expects it's value to be a string.
   *
   * @param key: The option key.
   */
  public static string(key: string): ClientOption<string> {
    return new ClientOption(key, (val): val is string => typeof val == "string");
  }

  /**
   * Create an options that expects it's value to be a number.
   *
   * @param key: The option key.
   */
  public static number(key: string): ClientOption<number> {
    return new ClientOption(key, (val): val is number => typeof val == "number");
  }

  /**
   * Create an options that expects it's value to be a boolean array.
   *
   * @param key: The option key.
   */
  public static booleanArray(key: string): ClientOption<boolean[]> {
    return new ClientOption(
      key,
      (val): val is boolean[] =>
        val instanceof Array && val.every((v) => typeof v == "boolean"),
    );
  }

  /**
   * Create an options that expects it's value to be a string array.
   *
   * @param key: The option key.
   */
  public static stringArray(key: string): ClientOption<string[]> {
    return new ClientOption(
      key,
      (val): val is string[] =>
        val instanceof Array && val.every((v) => typeof v == "string"),
    );
  }

  /**
   * Create an options that expects it's value to be a number array.
   *
   * @param key: The option key.
   */
  public static numberArray(key: string): ClientOption<number[]> {
    return new ClientOption(
      key,
      (val): val is number[] =>
        val instanceof Array && val.every((v) => typeof v == "number"),
    );
  }

  private static KEY_REGEX: RegExp = /(?:org|com)(?:.[a-zA-Z0-9]+)+/;

  private constructor(
    private _key: string,
    private validation: (val: any) => val is T,
  ) {
    if (!ClientOption.KEY_REGEX.test(_key)) {
      throw new Error(`invalid option key: ${_key}`);
    }
  }

  /**
   * Validate that the value satisfies the option constraints.
   *
   * @param value: value to be validated.
   *
   * @returns if the value is of the expected type.
   */
  public validate(value: ClientOptionValue): value is T {
    try {
      return this.validation(value);
    } catch {
      return false;
    }
  }

  /**
   * Get the option value from an object containing options key-value pairs.
   *
   * This is an implementation detail of the integration of this class with `ClientOptions`
   * and should not be used.
   *
   * @param fields: recording containing the client options.
   *
   * @returns the value of the option if present and it is of the expected type,
   *   returns `undefined` otherwise.
   */
  public getFrom(fields: Record<string, ClientOptionValue>): T | undefined {
    const val = fields[this._key];

    if (val && this.validate(val)) {
      return val;
    } else {
      return undefined;
    }
  }

  /**
   * Convert this class to a string.
   */
  public toString(): string {
    return this._key;
  }

  protected toJSON(): any {
    return this.toString();
  }
}

/**
 * A set of client options key-value pairs.
 */
export class ClientOptions {
  private _patch: Record<string, ClientOptionValue | null> = {};

  /**
   * Get the stored options patch.
   *
   * This is an implementation detail of the integration with `clientsService.patchClientOptions`
   * and should not be called outside of it.
   */
  get patch(): typeof ClientOptions.prototype._patch {
    return this._patch;
  }

  constructor(private fields: Record<string, ClientOptionValue>) {}

  /**
   * Get a specific option from the stored set.
   *
   * @param option: the option to get the value for.
   *
   * @returns the option value if it is present and it is of the expected type
   *   and `undefined` otherwise.
   */
  public get<T extends ClientOptionValue>(option: ClientOption<T>): T | undefined {
    return option.getFrom(this.fields);
  }

  /**
   * Removes a specific option from the stored set.
   *
   * The change is NOT sent to the IAM service, remember to call `clientsService.patchClientOptions`
   * after all changes in the set were made.
   *
   * @param option: option to remove.
   */
  public remove(option: ClientOption<ClientOptionValue>) {
    delete this.fields[option.toString()];
    this._patch[option.toString()] = null;
  }

  /**
   * Replaces or add a new option in the stored set.
   *
   * The change is NOT sent to the IAM service, remember to call `clientsService.patchClientOptions`
   * after all changes in the set were made.
   *
   * @param option: option to replace/add.
   * @param value: the new option value.
   */
  public replace<T extends ClientOptionValue>(option: ClientOption<T>, value: T) {
    this.fields[option.toString()] = value;
    this._patch[option.toString()] = value;
  }

  /**
   * Clears the stored options patch.
   *
   * Should be used after updating the client options in the backend.
   *
   * This is an implementation detail of the integration with `clientsService.patchClientOptions`
   * and should not be called outside of it.
   */
  public clearPatch() {
    this._patch = {};
  }
}
