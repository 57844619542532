import { ClientState, RootState } from "@/@types/vuex";

import { Client } from "@/@types/client";
import { GetterTree } from "vuex";

const getters: GetterTree<ClientState, RootState> = {
  clientById:
    ({ clients }: ClientState) =>
    (clientId: string): Client | undefined => {
      return clients[clientId];
    },
  // Getter needed to locate client pretty_name (as we only have the name), on route
  // https://app.swaggerhub.com/apis/TerraMagna/monitoring-service/v3.2#/Operations/get_operations__operation_
  clientByName:
    ({ clients }: ClientState) =>
    (clientName: string) => {
      return Object.values(clients).find(({ name }) => name === clientName);
    },
  clients: ({ clients }: ClientState): Client[] => Object.values(clients),
};

export default getters;
