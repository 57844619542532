import turfHelpers from "@turf/helpers";

export const makeUploadFilesEndpoint = (clientId?: string) => {
  if (!clientId) return `${process.env.VUE_APP_FILES_API}/files`;
  return `${process.env.VUE_APP_FILES_API}/internal/${clientId}/files`;
};

export const getImageServiceEndpoint = (
  bbox: turfHelpers.BBox,
  datesList: string[],
): string => {
  const endpoint = `${process.env.VUE_APP_IMAGE_SERVICE_API}/images`;
  const bboxParams = `minX=${bbox[0]}&minY=${bbox[1]}&maxX=${bbox[2]}&maxY=${bbox[3]}`;

  return `${endpoint}?${bboxParams}&dates=${datesList}`;
};
