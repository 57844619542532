import {
  CurrencyType,
  DocumentType,
  SeasonCropType,
} from "@/constants/shared/financial";
import {
  ICreateUpdateOperationRegister,
  IEnrichedOperationRegisterFormatted,
  IFarmRequest,
  IOperation,
  IOperationRegisterFormatted,
  IOperationRequestFormatted,
} from "@/@types/onboarding/operation";
import { CropType } from "@/constants/shared/crop";
import { IPreFinRequestFormatted } from "@/@types/onboarding/preFinancing";
import { IProducer } from "@/@types/onboarding/shared";
import { OnboardingState } from "@/@types/vuex";
import { RequestStatus } from "@/constants/shared/requestStatus";
import { RequestType } from "@/constants/onboarding/requests";
import { State } from "@/constants/shared/location";

const season = {
  crop: SeasonCropType.SAFRA,
  year: 2020,
};

const receivable = {
  amount: 0,
  currency: CurrencyType.ARROBA,
};

const selectedPreFinRequest: IPreFinRequestFormatted = {
  clientId: "",
  clientName: "",
  creationTime: "",
  creatorEmail: "",
  creatorId: "",
  creatorName: "",
  deleterId: "",
  deleterName: "",
  deletionTime: "",
  externalId: "",
  farmsAttachments: [],
  farmsComment: "",
  formattedNationalRegistration: "",
  formattedRequestType: "",
  formattedSeasonCrop: "",
  id: "",
  keyAccountManagerId: "",
  keyAccountManagerName: "",
  producerName: "",
  registrationTime: "",
  registrationUserId: "",
  registrationUserName: "",
  serialId: 0,
  status: RequestStatus.PENDING,
  translatedDate: "",
  translatedStatus: "",
  type: RequestType.PRE_FIN,
};

const preFinancing: OnboardingState["preFinancing"] = {
  requests: [],
  selectedRequest: selectedPreFinRequest,
};

export const emptySelectedRequest: IOperationRequestFormatted = {
  attachments: [],
  clientId: "",
  clientName: "",
  comments: "",
  creationTime: "",
  creatorEmail: "",
  creatorId: "",
  creatorName: "",
  deleterId: "",
  deleterName: "",
  deletionTime: "",
  externalId: "",
  id: "",
  keyAccountManagerId: "",
  keyAccountManagerName: "",
  producerName: "",
  registrationTime: "",
  registrationUserId: "",
  registrationUserName: "",
  serialId: 0,
  status: RequestStatus.IN_ANALYSIS,
  translatedDate: "",
  translatedStatus: "",
  type: RequestType.OPERATION,
};

export const emptyOperationRegister: IOperationRegisterFormatted = {
  ...emptySelectedRequest,
  attachments: [],
  documentType: DocumentType.CPR,
  operationId: "",
  type: RequestType.OPERATION,
};

export const emptyOperation: IOperation = {
  creationTime: "",
  creatorId: "",
  cropType: CropType.ONION,
  documentNumber: "",
  documentType: DocumentType.CPR,
  dueDate: "",
  financedArea: 0,
  id: "",
  producerId: "",
  receivable: {
    amount: 0,
    currency: CurrencyType.ARROBA,
  },
  season: {
    crop: SeasonCropType.SAFRA,
    year: null,
  },
};

export const emptySelectedRegister: IEnrichedOperationRegisterFormatted = {
  ...emptyOperationRegister,
  ...emptyOperation,
};

export const emptyRequestRegisterStore: ICreateUpdateOperationRegister = {
  creationTime: "",
  creatorId: "",
  cropType: CropType.BEAN,
  deleterId: "",
  deletionTime: "",
  documentNumber: "",
  documentType: DocumentType.CPR,
  dueDate: "",
  farmRequests: [],
  farmRequestsToCreate: [],
  farmRequestsToDelete: [],
  farmRequestsToEdit: [],
  financedArea: 0,
  id: "",
  producerId: "",
  producerIdentityNumber: "",
  receivable,
  requestId: "",
  season,
  updateTime: "",
};

const producer: IProducer = {
  creationTime: "",
  creatorId: "",
  id: "",
  identityNumber: "",
  name: "",
};

export const emptyOperationFarmRequest: IFarmRequest = {
  attachments: [],
  creationTime: "",
  creatorId: "",
  id: "",
  municipality: "",
  name: "",
  plantingArea: 0,
  registry: "",
  requestId: "",
  state: State.AC,
  status: RequestStatus.PENDING,
};

const operation: OnboardingState["operation"] = {
  farmRequest: emptyOperationFarmRequest,
  farmRequests: [],
  producer,
  refusals: {},
  requestRegisterStore: emptyRequestRegisterStore,
  requestsByTab: {
    CANCELLED: [],
    PENDING: [],
    REGISTERED: [],
  },
  selectedRegister: emptySelectedRegister,
};

export default {
  operation,
  preFinancing,
  searchTerms: "",
} as OnboardingState;
