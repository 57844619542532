import { RootState, UserState } from "@/@types/vuex";
import { GetterTree } from "vuex";
import { IUser } from "@/@types/user";

const getters: GetterTree<UserState, RootState> = {
  userById:
    (state: UserState) =>
    (userId: string): IUser | undefined => {
      return state.allUsers[userId] ?? state.tmUsers[userId];
    },
};

export default getters;
