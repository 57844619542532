import { FileState, IFileInfo } from "@/@types/vuex";
import { IFile } from "@/@types/files";

import { MutationTree } from "vuex";
import Vue from "vue";

export enum FileMutation {
  ADD_FILES_INFO = "ADD_FILES_INFO",
}

const mutations: MutationTree<FileState> = {
  ADD_FILES_INFO: (state: FileState, filesInfo: IFile[]) => {
    const newFilesInfo: IFileInfo = {};
    for (const fileInfo of filesInfo) {
      newFilesInfo[fileInfo.id] = fileInfo;
    }

    const newFileState = { ...state.filesInfo, ...newFilesInfo };
    Vue.set(state, "filesInfo", newFileState);
  },
};

export default mutations;
