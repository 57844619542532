import {
  IAnalysisResults,
  IChartContent,
  IChartDescriptions,
  IDelimitationConclusion,
  IDelimitationGeoJsonErrors,
  IExecutionTaskPayload,
  IFormattedTask,
  IMonitoringTask,
  INewRevision,
  IPreAnalysisTask,
  IRefusalPayload,
  IRevisionTask,
  ITask,
  ITaskDetails,
} from "@/@types/manualAnalysis/analysis";
import { isoDateToBrazilianDate, isoDateToBrazilianDateTime } from "@/utils/date";
import manualAnalysisService, { ChartContentPayload } from "@/services/manualAnalysis";
import { ManualAnalysisState, RootState } from "@/@types/vuex";
import { taskStatusTranslate, taskTypeTranslate } from "@/utils/translations";

import { ActionTree } from "vuex";
import { IAnalyst } from "@/@types/analyst";
import { IFarm } from "@/@types/monitoring";
import { IFarmRequest } from "@/@types/onboarding/operation";
import { IMonitoringOperation } from "@/@types/operation";
import { isTaskDoneOrReviewed } from "@/utils/task";
import { IUser } from "@/@types/user";
import { ManualAnalysisMutation } from "@/store/manual-analysis/mutations";
import { NULL_STRING } from "@/constants/shared/strings";
import { PaginationItemsSize } from "@/constants/pagination";
import { State } from "@/constants/shared/location";
import { TaskType } from "@/constants/manual-analysis/shared";

const NO_REVIEW_TASK_FOUND_ERROR = "No review task was found to delete";

const actions: ActionTree<ManualAnalysisState, RootState> = {
  changeListItemsSize({ commit }, density: PaginationItemsSize) {
    commit(ManualAnalysisMutation.CHANGE_LIST_ITEMS_SIZE, density);
  },
  cleanChangedAnalysisState({ commit }) {
    commit(ManualAnalysisMutation.CLEAN_CHANGED_ANALYSIS);
  },
  cleanDelimitationState({ commit }) {
    commit(ManualAnalysisMutation.CLEAN_DELIMITATION_STATE);
  },
  async createTaskRevision(_, payload: INewRevision): Promise<void> {
    await manualAnalysisService.createTaskRevision(payload);
  },
  async deleteReviewTask(
    { dispatch },
    task: IPreAnalysisTask | IRevisionTask | IMonitoringTask,
  ): Promise<void> {
    const deleteTask = task.taskReviewedId ? task : await dispatch("getCurrentTask");
    if (!deleteTask?.taskReviewedId) throw new Error(NO_REVIEW_TASK_FOUND_ERROR);
    await dispatch("deleteTask", deleteTask.id);
  },
  async deleteTask(_, taskId: string): Promise<void> {
    await manualAnalysisService.deleteTask(taskId);
  },
  async executeTask(
    { commit },
    payload: IExecutionTaskPayload | IDelimitationConclusion,
  ): Promise<boolean> {
    const serviceResponse = await manualAnalysisService.executeTask(payload);
    if (typeof serviceResponse === "object") {
      commit(ManualAnalysisMutation.SET_DELIMITATION_ERRORS, serviceResponse);
      return false;
    }
    return true;
  },
  async getChartContent(_, payload: ChartContentPayload): Promise<IChartContent> {
    return await manualAnalysisService.getChartContent(payload);
  },
  async getCurrentTask(): Promise<ITask | null> {
    return await manualAnalysisService.getCurrentTask();
  },
  async getDelimitationReviewTaskInfo(
    { dispatch },
    task: IRevisionTask,
  ): Promise<IFarmRequest> {
    const { farmRequestId, farmId, operationId } = task;

    await dispatch("monitoring/getGeometriesByFarmId", farmId, { root: true });
    if (farmRequestId) {
      return dispatch("onboarding/setFarmRequest", farmRequestId, {
        root: true,
      });
    }

    const { client_id: clientId, client_name: clientName }: IMonitoringOperation =
      await dispatch("monitoring/getOperationById", operationId, {
        root: true,
      });

    const {
      id,
      name,
      pretty_name,
      location: { state, municipality },
      areas: { funded_area: plantingArea },
      created_at: creationTime,
      registry,
    }: IFarm = await dispatch("monitoring/getFarmById", farmId, { root: true });

    return _makeLegacyFarmRequest({
      clientId,
      clientName,
      creationTime,
      id: `${id}`,
      municipality,
      name: pretty_name || name,
      plantingArea,
      registry,
      state,
    });
  },
  async getDelimitationTaskInfo(
    { dispatch },
    task: IPreAnalysisTask | IRevisionTask,
  ): Promise<IFarmRequest> {
    const { type, farmRequestId } = task;

    if (type === TaskType.DELIMITATION_REVIEW) {
      return dispatch("getDelimitationReviewTaskInfo", task);
    }

    return dispatch("onboarding/setFarmRequest", farmRequestId, {
      root: true,
    });
  },
  async getLatestReview(
    { rootGetters },
    taskId: string,
  ): Promise<IFormattedTask | null> {
    const analystById = rootGetters["analyst/analystById"];
    const userById = rootGetters["user/userById"];
    const task = await manualAnalysisService.getLatestReview(taskId);
    return task ? await _formatTask(task, userById, analystById) : null;
  },
  async getReviewedOrDoneTaskByIdOrCurrentTask(
    { dispatch },
    taskId?: string,
  ): Promise<ITask | null> {
    if (taskId) {
      const taskById = await dispatch("getTaskById", taskId);
      if (isTaskDoneOrReviewed(taskById.status)) return taskById;
    }
    return await dispatch("getCurrentTask");
  },
  async getStandResults(
    _,
    payload: ChartContentPayload,
  ): Promise<Omit<IChartContent, "timeseries">> {
    return await manualAnalysisService.getStandResults(payload);
  },
  async getTaskById({ rootGetters }, taskId: string): Promise<IFormattedTask> {
    const analystById = rootGetters["analyst/analystById"];
    const userById = rootGetters["user/userById"];
    const task = await manualAnalysisService.getTaskById(taskId);
    return await _formatTask(task, userById, analystById);
  },
  async getTaskCharts(_, taskId: string): Promise<IChartDescriptions> {
    return await manualAnalysisService.getTaskCharts(taskId);
  },

  async getTaskDetails(_, taskId: string): Promise<ITaskDetails> {
    return await manualAnalysisService.getTaskDetails(taskId);
  },

  async refuseTask(
    _,
    { taskId, payload }: { taskId: string; payload: IRefusalPayload },
  ): Promise<void> {
    await manualAnalysisService.refuseTask(taskId, payload);
  },
  removeUnchangedChart({ commit }, chartIdx: number) {
    commit(ManualAnalysisMutation.REMOVE_UNCHANGED_CHART, chartIdx);
  },
  setDelimitation({ commit }, payload: IDelimitationConclusion) {
    commit(ManualAnalysisMutation.SET_DELIMITATION, payload);
  },
  setDelimitationErrors({ commit }, payload: IDelimitationGeoJsonErrors) {
    commit(ManualAnalysisMutation.SET_DELIMITATION_ERRORS, payload);
  },
  setUnchangedCharts({ commit }, chartIdxs: number[]) {
    commit(ManualAnalysisMutation.SET_UNCHANGED_CHARTS, chartIdxs);
  },
  async skipCurrentTask(): Promise<void> {
    return await manualAnalysisService.skipCurrentTask();
  },
  async startCurrentTask(): Promise<boolean> {
    return await manualAnalysisService.startCurrentTask();
  },
  async startReview(_, taskId: string): Promise<ITask> {
    return await manualAnalysisService.startReview(taskId);
  },
  async takeTask(_, taskId: string): Promise<ITask> {
    return await manualAnalysisService.takeTask(taskId);
  },
  updateChangedAnalysis({ commit }, analysisResult: IAnalysisResults) {
    commit(ManualAnalysisMutation.UPDATE_ANALYSIS_RESULT, analysisResult);
  },
};

const _makeLegacyFarmRequest = (legacy: Partial<IFarmRequest>) => {
  return {
    attachments: legacy.attachments || [],
    car: legacy.car || undefined,
    clientId: legacy.clientId || undefined,
    clientName: legacy.clientName || undefined,
    comments: legacy.comments || undefined,
    creationTime: legacy.creationTime || "",
    creatorId: legacy.creatorId || "",
    deleterId: legacy.deleterId || undefined,
    deletionTime: legacy.deletionTime || undefined,
    id: legacy.id || "",
    municipality: legacy.municipality || "",
    name: legacy.name || "",
    plantingArea: legacy.plantingArea || "",
    referencePoint: legacy.referencePoint || undefined,
    registry: legacy.registry || "",
    requestId: legacy.requestId || "",
    sigef: legacy.sigef || undefined,
    state: legacy.state || State.AC,
    status: legacy.status || "",
  } as IFarmRequest;
};

const _formatTask = (
  task: ITask,
  userById: Function,
  analystById: Function,
): IFormattedTask => {
  const {
    completionTime,
    creationTime,
    deletionTime,
    type,
    dueDate,
    analystId,
    status,
    startTime,
  } = task;

  const analyst: IAnalyst | undefined = analystById(analystId);
  const analystUser: IUser | undefined = userById(analyst?.userId);

  const formattedTaskView = {
    ...task,
    analystName: analystUser?.name || NULL_STRING,
    translatedCompletionTime: isoDateToBrazilianDateTime(completionTime),
    translatedCreationTime: isoDateToBrazilianDateTime(creationTime),
    translatedDeletionTime: isoDateToBrazilianDateTime(deletionTime),
    translatedDueDate: isoDateToBrazilianDate(dueDate),
    translatedStartTime: isoDateToBrazilianDateTime(startTime),
    translatedTaskStatus: taskStatusTranslate(status),
    translatedTaskType: taskTypeTranslate(type),
  };

  return formattedTaskView;
};

export default actions;
