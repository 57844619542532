import { analysis, errors } from "./state";
import {
  IAnalysisResults,
  IDelimitationConclusion,
  IDelimitationGeoJsonErrors,
} from "@/@types/manualAnalysis/analysis";

import { ManualAnalysisState } from "@/@types/vuex";
import { MutationTree } from "vuex";
import { PaginationItemsSize } from "@/constants/pagination";
import Vue from "vue";

export enum ManualAnalysisMutation {
  UPDATE_ANALYSIS_RESULT = "UPDATE_ANALYSIS_RESULT",
  SET_UNCHANGED_CHARTS = "SET_UNCHANGED_CHARTS",
  REMOVE_UNCHANGED_CHART = "REMOVE_UNCHANGED_CHART",
  CHANGE_LIST_ITEMS_SIZE = "CHANGE_LIST_ITEMS_SIZE",
  CLEAN_CHANGED_ANALYSIS = "CLEAN_CHANGED_ANALYSIS",
  CLEAN_DELIMITATION_STATE = "CLEAN_DELIMITATION_STATE",
  SET_DELIMITATION = "SET_DELIMITATION",
  SET_DELIMITATION_ERRORS = "SET_DELIMITATION_ERRORS",
}

const mutations: MutationTree<ManualAnalysisState> = {
  CHANGE_LIST_ITEMS_SIZE(state: ManualAnalysisState, density: PaginationItemsSize) {
    state.chartListItemsSize = density;

    localStorage.setItem("chartListItemsSize", density);
  },
  CLEAN_CHANGED_ANALYSIS(state: ManualAnalysisState) {
    Vue.set(state, "changedAnalyses", []);
  },
  CLEAN_DELIMITATION_STATE(state: ManualAnalysisState) {
    Vue.set(state, "delimitation", { analysis, errors });
  },
  REMOVE_UNCHANGED_CHART(state: ManualAnalysisState, chartIdx: number) {
    _removeUnchangedChart(state, chartIdx);
  },
  SET_DELIMITATION(state: ManualAnalysisState, payload: IDelimitationConclusion) {
    Vue.set(state.delimitation, "analysis", payload);
  },
  SET_DELIMITATION_ERRORS(
    state: ManualAnalysisState,
    payload: IDelimitationGeoJsonErrors,
  ) {
    Vue.set(state.delimitation, "errors", payload);
  },
  SET_UNCHANGED_CHARTS(state: ManualAnalysisState, chartIdxs: number[]) {
    state.unchangedCharts = chartIdxs.sort((a, b) => a - b);
  },
  UPDATE_ANALYSIS_RESULT(state: ManualAnalysisState, analysisResult: IAnalysisResults) {
    const index = _getAnalysisIndex(state, analysisResult);

    if (index === -1) {
      state.changedAnalyses.push(analysisResult);
    } else {
      state.changedAnalyses[index] = analysisResult;
    }

    _removeUnchangedChart(state, analysisResult.stand);
  },
};

const _getAnalysisIndex = (
  state: ManualAnalysisState,
  analysisResult: IAnalysisResults,
): number => {
  return state.changedAnalyses.findIndex(
    (value) => value.stand === analysisResult.stand,
  );
};

const _removeUnchangedChart = (state: ManualAnalysisState, chartIdx: number) => {
  state.unchangedCharts = state.unchangedCharts.filter((idx) => idx !== chartIdx);
};

export default mutations;
