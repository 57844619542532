import axios, { AxiosResponse } from "axios";
import {
  LegalEntitiesPage,
  LegalEntity,
  NewRelationship,
  RelatedLegalEntitiesPage,
  RelatedLegalEntity,
  RelationshipsPage,
} from "./schemas/legalEntity";

const API_URL = process.env.VUE_APP_API_GTW;

class LegalEntityService {
  private apiURL: string;

  public constructor() {
    if (!API_URL) {
      throw new Error("API_URL is not defined");
    }

    this.apiURL = `${API_URL}/legalEntity`;
  }

  async internalCreateLegalEntityRelationships(
    ownerId: string,
    relationships: NewRelationship[],
  ): Promise<RelationshipsPage> {
    const response: AxiosResponse<RelationshipsPage> = await axios.post(
      `${this.apiURL}/internal/legalEntity`,
      relationships,
      { params: { ownerId } },
    );

    return response.data;
  }

  async internalFetchAllLegalEntities(): Promise<LegalEntitiesPage> {
    const endpoint = `${this.apiURL}/internal/legalEntity`;
    const response: AxiosResponse<LegalEntitiesPage> = await axios.get(endpoint);

    return response.data;
  }

  async internalFetchLegalEntityById(id: string): Promise<LegalEntity> {
    const endpoint = `${this.apiURL}/internal/legalEntity/${id}`;
    const response: AxiosResponse<LegalEntity> = await axios.get(endpoint);

    return response.data;
  }

  async internalFetchRelatedLegalEntity(relationshipFilters: {
    nationalRegistration: string;
    ownerId: string;
  }): Promise<RelatedLegalEntity> {
    const response: AxiosResponse<RelatedLegalEntitiesPage> = await axios.get(
      `${this.apiURL}/internal/legalEntity`,
      { params: { ...relationshipFilters } },
    );

    return response.data.relatedLegalEntities[0];
  }
}

export const legalEntityService = new LegalEntityService();
