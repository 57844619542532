// Enums exports
export enum Eligibility {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  NOT_APPROVED = "NOT_APPROVED",
  DISREGARDED = "DISREGARDED",
  // Frontend only
  EXPIRED = "EXPIRED",
}

export enum EligibilityTranslation {
  APPROVED = "Elegível",
  DISREGARDED = "Desconsiderado",
  NOT_APPROVED = "Não elegível",
  PENDING = "Em análise",
  // Front-end only status
  EXPIRED = "Vencido",
}

export enum RelationshipTime {
  NOT_INFORMED = "NOT_INFORMED",
  NEW = "NEW",
  CONSOLIDATED = "CONSOLIDATED",
  NOT_DEFINED = "NOT_DEFINED",
}

export enum RelationshipTimeTranslation {
  CONSOLIDATED = "Mais de 2 anos",
  NEW = "Novo ou até 2 anos",
  NOT_DEFINED = "Não definido",
  NOT_INFORMED = "Indisponível",
}

export enum RefusalReason {
  SINTEGRA_NOT_ENABLED = "SINTEGRA_NOT_ENABLED",
  INVALID_ECONOMIC_ACTIVITY = "INVALID_ECONOMIC_ACTIVITY",
  SHOW_RESTRICTION = "SHOW_RESTRICTION",
  PROTEST_OR_APPOINTMENT_PROTECTION_AGENCIES = "PROTEST_OR_APPOINTMENT_PROTECTION_AGENCIES",
  RESTRICTION_SLAVE_LABOR = "RESTRICTION_SLAVE_LABOR",
  SOCIO_ENVIRONMENTAL_RESTRICTION_IBAMA = "SOCIO_ENVIRONMENTAL_RESTRICTION_IBAMA",
  DOES_NOT_HAVE_REGISTRATIONS_ACCEPTED_IN_THE_OPERATION = "DOES_NOT_HAVE_REGISTRATIONS_ACCEPTED_IN_THE_OPERATION",
  OTHERS = "OTHERS",
}

export enum RefusalReasonTranslation {
  SINTEGRA_NOT_ENABLED = "Sintegra não habilitado",
  INVALID_ECONOMIC_ACTIVITY = "CNAE inválido",
  SHOW_RESTRICTION = "Apresenta restrição",
  PROTEST_OR_APPOINTMENT_PROTECTION_AGENCIES = "Apresenta restrição, protesto ou apontamento nos órgãos de proteção",
  RESTRICTION_SLAVE_LABOR = "Restrição trabalho escravo",
  SOCIO_ENVIRONMENTAL_RESTRICTION_IBAMA = "Restrição socioambiental - Ibama",
  DOES_NOT_HAVE_REGISTRATIONS_ACCEPTED_IN_THE_OPERATION = "Não possui critérios cadastrais aceitos na operação.",
  OTHERS = "Outros",
}

export interface DebtorAnalysisRequest {
  id: string;
  requesterId: string;
  debtorId: string;
  rejectedResultId?: string;
  reanalysisReason?: string;
  creationTime: string;
  cancellationTime?: string;
}

export interface DebtorAnalysisResult {
  id: string;
  requestId: string;
  relationshipTime: RelationshipTime;
  eligibility: Eligibility;
  refusalReason?: RefusalReason;
  creationTime: string;
  updateTime: string;
}

export interface DebtorAnalysis extends DebtorAnalysisRequest {
  analysisResult?: DebtorAnalysisResult;
}

export interface FetchDebtorAnalysisResponse {
  total: number;
  debtorAnalyses: DebtorAnalysis[];
}
