import axios from "axios";
import { IMonitoringOperation } from "@/@types/operation";

const API = process.env.VUE_APP_MONITORING_SERVICE_API;

const operationsService = {
  async getOperationById(operationId: number): Promise<IMonitoringOperation> {
    const endpoint = `${API}/internal/operations/${operationId}`;
    const response = await axios.get<IMonitoringOperation>(endpoint);

    return response.data;
  },
};

export default operationsService;
