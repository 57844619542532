import {
  Farm,
  FarmRegistrationTask,
  TaskPayload,
  TaskStatus,
} from "@/services/farms/schemas";
import farmsService, { TaskListingParams } from "@/services/farms";
import { ActionTree } from "vuex";
import { FarmRegistrationMutations } from "@/store/farmRegistration/mutations";
import { FarmRegistrationState } from "@/store/farmRegistration/state";
import { RootState } from "@/@types/vuex";

export const actions: ActionTree<FarmRegistrationState, RootState> = {
  async fetchManyFarmsById(_, ids: string[]): Promise<Farm[]> {
    const farms = await farmsService.fetchManyFarmsById(ids);

    return farms;
  },
  async fetchPendingTasks({ commit }, params?: Omit<TaskListingParams, "status">) {
    const tasks = await farmsService.fetchTasksByStatus({
      from: params?.from,
      status: TaskStatus.PENDING,
      until: params?.until,
    });

    commit(FarmRegistrationMutations.SET_PENDING_TASKS, tasks);
  },
  async fetchRegisteredTasks({ commit }, params?: Omit<TaskListingParams, "status">) {
    const tasks = await farmsService.fetchTasksByStatus({
      from: params?.from,
      status: TaskStatus.REGISTERED,
      until: params?.until,
    });

    commit(FarmRegistrationMutations.SET_REGISTERED_TASKS, tasks);
  },
  async fetchTaskById(_, id: string): Promise<FarmRegistrationTask> {
    const task = await farmsService.fetchTaskById(id);

    return task;
  },
  async saveTask(_, data: TaskPayload): Promise<void> {
    await farmsService.saveTask(data);
  },
};
