import { ActionTree } from "vuex";
import debtorAnalysesService from "@/services/debtorAnalyses";
import { DebtorAnalysis } from "@/@types/debtorAnalyses";
import { RootState } from "@/@types/vuex";

export const actions: ActionTree<{}, RootState> = {
  async fetchDebtorAnalysesByClientId(_, clientId: string): Promise<DebtorAnalysis[]> {
    const response = await debtorAnalysesService.internalFetchAnalysesByClientId(
      clientId,
    );

    return response.debtorAnalyses;
  },
  async fetchDebtorAnalysesByClientIdList(
    _,
    clientIds: string[],
  ): Promise<Record<string, DebtorAnalysis[]>> {
    const promises = clientIds.map((id) =>
      debtorAnalysesService.internalFetchAnalysesByClientId(id),
    );

    const analysesResponses = await Promise.all(promises);

    const analysesByClientMap: Record<string, DebtorAnalysis[]> = {};
    for (const response of analysesResponses) {
      if (response.total > 0) {
        analysesByClientMap[response.debtorAnalyses[0].requesterId] =
          response.debtorAnalyses;
      }
    }

    return analysesByClientMap;
  },
};
