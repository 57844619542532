import { StringKeyMap } from "@/@types/utils";

type Grouped<T> = StringKeyMap<T[]>;

type Identified<K extends string, M> = { [k in K]: string } & M;

export const groupBy = <K extends string, T>(
  key: K,
  array: Identified<K, T>[],
): Grouped<T> => {
  return array.reduce((hashMap: Grouped<T>, value) => {
    (hashMap[value[key]] = hashMap[value[key]] || []).push(value);
    return hashMap;
  }, {});
};

export const arrayToHashMap = <K extends string, T>(
  key: K,
  array: Identified<K, T>[],
): StringKeyMap<T> => {
  return array?.reduce((map: StringKeyMap<T>, value) => {
    map[value[key]] = value;
    return map;
  }, {});
};
