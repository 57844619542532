const FarmsRegister = () =>
  import(/* webpackChunkName: "FarmsRegister" */ "@/views/FarmsRegister.vue");
const FarmOrdersListPage = () =>
  import(
    /* webpackChunkName: "FarmOrders" */ "@/views/farmsRegister/OrdersList/Page.vue"
  );
const FarmOrderPage = () =>
  import(/* webpackChunkName: "FarmOrder" */ "@/views/farmsRegister/Order/Page.vue");

export enum FarmsRoute {
  FARM_ORDERS_LIST = "FarmOrdersList",
  FARM_ORDER = "FarmOrder",
}

export default [
  {
    children: [
      {
        component: FarmOrdersListPage,
        meta: { requiresAuth: true },
        name: FarmsRoute.FARM_ORDERS_LIST,
        path: "orders",
      },
      {
        component: FarmOrderPage,
        meta: { requiresAuth: true },
        name: FarmsRoute.FARM_ORDER,
        path: "orders/:orderId",
      },
    ],
    component: FarmsRegister,
    meta: { requiresAuth: true },
    path: "/farms",
  },
];
