import { FileState, RootState } from "@/@types/vuex";

import { GetterTree } from "vuex";

const getters: GetterTree<FileState, RootState> = {
  fileById: (state: FileState, id: string) => {
    return state.filesInfo[id];
  },
  filesByIds: (state: FileState, ids: string[]) =>
    ids?.map((id) => state.filesInfo[id]),
};

export default getters;
