import { PromiseStatus } from "@/constants/shared/promise";

export const fulfilledPromise = <T>({ status }: PromiseSettledResult<T>) => {
  return status === PromiseStatus.FULFILLED;
};

export const getFulfilledPromiseValues = <T>(promises: PromiseSettledResult<T>[]) => {
  const fulfilled = promises.filter(fulfilledPromise) as PromiseFulfilledResult<T>[];
  return fulfilled.map(({ value }) => value);
};
