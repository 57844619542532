// Material color pallet generated with http://mcg.mbitson.com/

import { VuetifyTheme, VuetifyThemeItem } from "vuetify";
import colors from "vuetify/es5/util/colors";

const accentPallet: VuetifyThemeItem = {
  base: "#265987",
  darken1: "#22517f",
  darken2: "#1c4874",
  darken3: "#173e6a",
  darken4: "#0d2e57",
  lighten1: "#477299",
  lighten2: "#678bab",
  lighten3: "#93acc3",
  lighten4: "#becddb",
  lighten5: "#e5ebf1",
};

const warningPallet: VuetifyThemeItem = {
  base: "#f0c324",
  darken1: "#eebd20",
  darken2: "#ecb51b",
  darken3: "#e9ae16",
  darken4: "#e5a10d",
  lighten1: "#f2cc45",
  lighten2: "#f5d566",
  lighten3: "#f8e192",
  lighten4: "#fbedbd",
  lighten5: "#fdf8e5",
};

const errorPallet: VuetifyThemeItem = {
  base: "#d71f1f",
  darken1: "#d31b1b",
  darken2: "#cd1717",
  darken3: "#c71212",
  darken4: "#be0a0a",
  lighten1: "#dd4141",
  lighten2: "#e36262",
  lighten3: "#eb8f8f",
  lighten4: "#f3bcbc",
  lighten5: "#fae4e4",
};

const successPallet: VuetifyThemeItem = {
  base: "#21a945",
  darken1: "#1da23e",
  darken2: "#189836",
  darken3: "#148f2e",
  darken4: "#0b7e1f",
  lighten1: "#42b661",
  lighten2: "#64c37d",
  lighten3: "#90d4a2",
  lighten4: "#bce5c7",
  lighten5: "#e4f5e9",
};

export const theme: VuetifyTheme = {
  accent: accentPallet,
  error: errorPallet,
  info: colors.blueGrey,
  primary: colors.blueGrey.darken2,
  secondary: accentPallet,
  success: successPallet,
  warning: warningPallet,
};
