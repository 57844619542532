import { enumCssColors } from "@/utils/color";
import { IResult } from "@/@types/manualAnalysis/analysis";

// https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.1#/ResultsType
export enum ResultsType {
  CLOUD = "CLOUD",
  CROP_TYPE = "CROP_TYPE",
  PLANTING = "PLANTING",
  SENESCENCE = "SENESCENCE",
  IMMEDIATE_HARVEST = "IMMEDIATE_HARVEST",
  HARVESTING = "HARVESTING",
  HARVESTED = "HARVESTED",
  RISK = "RISK",
  ABANDONED_AREA = "ABANDONED_AREA",
}

export const sortResults = (
  results: IResult[],
  sortFn = (res1: IResult, res2: IResult) =>
    getPosResultType(res1.type) - getPosResultType(res2.type) ||
    res1.percentage - res2.percentage,
) => results.filter((result) => result.type !== ResultsType.CLOUD).sort(sortFn);

export const getPosResultType = (type: ResultsType) =>
  Object.keys(ResultsType).indexOf(type);

export enum ResultsTypeTranslate {
  CLOUD = "Nuvem",
  CROP_TYPE = "Cultura",
  PLANTING = "Plantio",
  SENESCENCE = "Senescência",
  IMMEDIATE_HARVEST = "Colheita imediata",
  HARVESTING = "Colhendo",
  HARVESTED = "Colhido",
  RISK = "Risco",
  ABANDONED_AREA = "Área abandonada",
}

export enum ResultsTypeColor {
  CLOUD = "#CCCCCC",
  CROP_TYPE = "#391085",
  PLANTING = "#C2F084",
  SENESCENCE = "#FFDC69",
  IMMEDIATE_HARVEST = "#FD9C00",
  HARVESTING = "#E55B20",
  HARVESTED = "#951504",
  RISK = "#B37A7A",
  ABANDONED_AREA = "#092B00",
}

export const CSS_STYLE_ANALYSIS_RESULT_TYPE_COLORS: string = enumCssColors(
  ResultsType,
  ResultsTypeColor,
  "result-type",
);
