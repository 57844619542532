import actions from "@/store/login/actions";
import getters from "@/store/login/getters";
import mutations from "@/store/login/mutations";
import state from "@/store/login/state";

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
