var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "nav" },
    [
      _c(
        "div",
        { staticClass: "nav__container" },
        [
          _c(
            "v-toolbar",
            { staticClass: "nav__toolbar", attrs: { app: "", flat: "" } },
            [
              _c(
                "v-toolbar-title",
                {
                  staticClass: "text-uppercase grey--text",
                  attrs: { "data-testid": "app-navbar__title" },
                },
                [
                  _c("span", { staticClass: "font-weight-light" }, [
                    _vm._v(_vm._s(_vm.lightTitle)),
                  ]),
                ]
              ),
              _c("v-spacer"),
              _vm._t("logout"),
            ],
            2
          ),
          _c("v-progress-linear", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "nav__progress-bar",
            attrs: {
              indeterminate: "",
              height: "6",
              color: "primary",
              "data-testid": "app-navbar__loading",
            },
          }),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "js-drawer",
          attrs: {
            app: "",
            "mini-variant": _vm.mini,
            "mini-variant-width": 56,
            permanent: "",
          },
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-tile",
                [
                  _c(
                    "v-list-tile-action",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", flat: "", color: _vm.iconColor },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleToggleDrawer.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c("v-icon", [
                            _vm._v(_vm._s(_vm.mini ? "menu" : "close")),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [
                      !_vm.mini
                        ? _c("v-list-tile-content", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/terramagna_logo.svg"),
                              },
                            }),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.filteredItemsByUserRoles, function (item) {
                return _c(
                  "v-list-tile",
                  {
                    key: item.name,
                    style: _vm.getItemStyle(item),
                    attrs: {
                      "data-testid": item.dataTestId,
                      to: {
                        path: item.to,
                        query: _vm.$route.query,
                      },
                      exact: "",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleRetractDrawer.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "v-list-tile-action",
                      { attrs: { title: _vm.mini ? item.name : "" } },
                      [
                        _c(
                          "v-icon",
                          { attrs: { flat: "", color: item.color } },
                          [_vm._v(" " + _vm._s(item.icon) + " ")]
                        ),
                      ],
                      1
                    ),
                    _c("v-list-tile-content", [
                      _vm._v(" " + _vm._s(item.name) + " "),
                    ]),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }