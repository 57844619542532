import { z } from "zod";

export enum TaskStatus {
  PENDING = "PENDING",
  REGISTERED = "REGISTERED",
}

export enum UsageCondition {
  OWNED = "OWNED",
}

export enum TranslatedUsageCondition {
  OWNED = "Própria",
}

export enum InfoSource {
  SIGEF = "SIGEF",
  USER = "USER",
}

export enum AllowedFileType {
  KML = "KML",
}

export const MultiPolygonSchema = z.object({
  coordinates: z.number().array().array().array().array().length(1),
  type: z.literal("MultiPolygon"),
});

export const NewGeometrySchema = z.object({
  datum: z.string().optional(),
  ext: z.nativeEnum(AllowedFileType),
  perimeter: MultiPolygonSchema,
});

export const FarmRegistrationTaskSchema = z.object({
  clientLegalEntityId: z.string().uuid(),
  conclusionTime: z.coerce.date().optional(),
  creationTime: z.coerce.date(),
  creatorId: z.string().uuid(),
  externalId: z.string().uuid(),
  id: z.string().uuid(),
  internalRegistrarId: z.string().uuid().optional(),
  producerLegalEntityId: z.string().uuid(),
  registeredFarmIds: z.string().uuid().array(),
  serialId: z.number(),
  updateTime: z.coerce.date().optional(),
});

export const MetadataSchema = z.object({
  car: z
    .object({
      number: z.string(),
      status: z.string(),
    })
    .optional(),
  qrCode: z.string().optional(),
});

export const FarmSchema = z.object({
  area: z.number(),
  creationTime: z.coerce.date(),
  creatorId: z.string().uuid(),
  cultivableArea: z.coerce.number().optional(),
  id: z.string().uuid(),
  infoSource: z.nativeEnum(InfoSource),
  metadata: MetadataSchema.optional(),
  name: z.string(),
  perimeter: MultiPolygonSchema,
  registry: z.string(),
  updateTime: z.coerce.date().optional(),
  updaterId: z.string().uuid().optional(),
  usageCondition: z.nativeEnum(UsageCondition),
});

export const FarmChangesetSchema = FarmSchema.merge(
  z.object({
    geometryData: NewGeometrySchema.or(MultiPolygonSchema),
  }),
);

export const NewFarmSchema = z.object({
  geometryData: NewGeometrySchema,
  infoSource: z.nativeEnum(InfoSource),
  metadata: MetadataSchema.optional(),
  name: z.string(),
  producerLegalEntityId: z.string().uuid(),
  registry: z.string(),
  usageCondition: z.nativeEnum(UsageCondition),
});

export const TaskPayloadSchema = z.object({
  created: NewFarmSchema.array(),
  deleted: z.string().uuid().array(),
  id: z.string().uuid(),
  updated: FarmChangesetSchema.array(),
});

export const StandsSchema = z.object({
  creatorId: z.string().uuid(),
  farmId: z.string().uuid(),
  geometry: MultiPolygonSchema,
  label: z.number(),
});

export const FarmGeometriesSchema = z.object({
  stands: StandsSchema.array().optional(),
});

export type FarmGeometries = z.infer<typeof FarmGeometriesSchema>;
export type Stand = z.infer<typeof StandsSchema>;
export type FarmRegistrationTask = z.infer<typeof FarmRegistrationTaskSchema>;
export type Farm = z.infer<typeof FarmSchema>;
export type FarmMetadata = z.infer<typeof MetadataSchema>;
export type NewFarmPayload = z.infer<typeof NewFarmSchema>;
export type MultiPolygon = z.infer<typeof MultiPolygonSchema>;
export type Changeset = z.infer<typeof FarmChangesetSchema>;
export type TaskPayload = z.infer<typeof TaskPayloadSchema>;
