import { CropType, CropTypeTranslate } from "../shared/crop";

// https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.7#/TaskStatus
export enum TaskStatus {
  ASSIGNED = "ASSIGNED",
  CREATED = "CREATED",
  DONE = "DONE",
  REVIEWED = "REVIEWED",
  STARTED = "STARTED",
  UNASSIGNED = "UNASSIGNED",
  CANCELLED = "CANCELLED",
}

export enum TaskStatusTranslate {
  ASSIGNED = "Atribuída",
  CREATED = "Indisponível",
  DONE = "Finalizada",
  REVIEWED = "Revisada",
  STARTED = "Iniciada",
  UNASSIGNED = "Não atribuída",
  CANCELLED = "Cancelada",
}

// https://app.swaggerhub.com/apis/TerraMagna/manual-analyses/0.1#/TaskType
// This enum is ordered according to task timeline + priority
export enum TaskType {
  DELIMITATION = "DELIMITATION",
  ONBOARDING = "ONBOARDING",
  PLANTING = "PLANTING",
  CROP_TYPE = "CROP_TYPE",
  PRE_HARVEST = "PRE_HARVEST",
  SENESCENCE = "SENESCENCE",
  IMMEDIATE_HARVEST = "IMMEDIATE_HARVEST",
  HARVESTING = "HARVESTING",
  DELIMITATION_REVIEW = "DELIMITATION_REVIEW",
}

// This enum is ordered according to task timeline + priority
export enum TaskTypeTranslate {
  DELIMITATION = "Análise preliminar",
  ONBOARDING = "Onboarding",
  PLANTING = "Plantio",
  CROP_TYPE = "Análise de cultura",
  PRE_HARVEST = "Pré-colheita",
  SENESCENCE = "Senescência",
  IMMEDIATE_HARVEST = "Colheita imediata",
  HARVESTING = "Colhendo",
  DELIMITATION_REVIEW = "Revisão de geometria",
}

export enum TaskPriority {
  LOWEST = "LOWEST",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  HIGHEST = "HIGHEST",
}

export enum TaskPriorityTranslate {
  LOWEST = "Baixíssimo",
  LOW = "Baixo",
  MEDIUM = "Médio",
  HIGH = "Alto",
  HIGHEST = "Altíssimo",
}

export enum TaskPrioritySortable {
  LOWEST = 4,
  LOW = 3,
  MEDIUM = 2,
  HIGH = 1,
  HIGHEST = 0,
}

export enum ChartEvent {
  UPDATE_CROP_TYPE_AND_RESULTS = "update-crop-type-and-results",
  UPDATE_CROP_TYPE = "update-chart-crop-type",
  UPDATE_RESULTS = "update-chart-results",
}

export const MANUAL_ANALYSIS_SNACKBAR_SHOW_TIME: number = 8_000;

export const FARM_ID_TO_SERIAL_ID_MAP = "storedSerialId";

export const SEMI_PERENNIAL_CROPS = [
  CropType.BRACHIARIA,
  CropType.CASSAVA,
  CropType.SUGARCANE,
];
export const PERENNIAL_CROPS = [CropType.COFFEE, CropType.EUCALYPTUS];
export const SEMI_AND_PERENNIAL_CROPS = [...SEMI_PERENNIAL_CROPS, ...PERENNIAL_CROPS];

export const SEMI_PERENNIAL_CROPS_TRANSLATED = [
  CropTypeTranslate.BRACHIARIA,
  CropTypeTranslate.CASSAVA,
  CropTypeTranslate.SUGARCANE,
];
export const PERENNIAL_CROPS_TRANSLATED = [
  CropTypeTranslate.COFFEE,
  CropTypeTranslate.EUCALYPTUS,
];
export const SEMI_AND_PERENNIAL_CROPS_TRANSLATED = [
  ...SEMI_PERENNIAL_CROPS_TRANSLATED,
  ...PERENNIAL_CROPS_TRANSLATED,
];
