import actions from "@/store/manual-analysis-panel/actions";
import getters from "@/store/manual-analysis-panel/getters";
import mutations from "@/store/manual-analysis-panel/mutations";
import state from "@/store/manual-analysis-panel/state";

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
