














import AppNavbar from "@/components/AppNavbar.vue";
import { LoginRoute } from "@/router";
import { mapActions } from "vuex";
import Vue from "vue";

export default Vue.extend({
  components: {
    AppNavbar,
  },
  computed: {
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    isLoggedIn() {
      return this.$store.getters["login/isLoggedIn"];
    },
  },
  methods: {
    ...mapActions({
      logoutUser: "login/logout",
    }),
    async logout() {
      await this.logoutUser();
      this.$router.push({ name: LoginRoute.LOGIN });
    },
  },
  name: "Home",
});
