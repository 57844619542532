import {
  Farm,
  FarmGeometries,
  type FarmRegistrationTask,
  FarmRegistrationTaskSchema,
  FarmSchema,
  TaskPayload,
  type TaskStatus,
} from "@/services/farms/schemas";
import axios from "axios";

const API_URL = `${process.env.VUE_APP_API_GTW}/farms`;

interface FarmRegistrationTasksPage {
  tasks: FarmRegistrationTask[];
}

interface FarmsPage {
  total: number;
  farms: Farm[];
}

export interface TaskListingParams {
  from?: string;
  status: TaskStatus;
  until?: string;
}

class FarmsService {
  public async fetchTasksByStatus({
    from,
    status,
    until,
  }: TaskListingParams): Promise<Map<string, FarmRegistrationTask>> {
    const endpoint = `${API_URL}/internal/tasks`;
    const { data } = await axios.get<FarmRegistrationTasksPage>(endpoint, {
      params: {
        from,
        status,
        until,
      },
    });
    const tasks = FarmRegistrationTaskSchema.array().parse(data.tasks);

    return new Map(tasks.map((task) => [task.id, task]));
  }

  public async fetchTaskById(id: string): Promise<FarmRegistrationTask> {
    const endpoint = `${API_URL}/internal/tasks/${id}`;
    const { data } = await axios.get<FarmRegistrationTask>(endpoint);
    const task = FarmRegistrationTaskSchema.parse(data);

    return task;
  }

  public async fetchManyFarmsById(ids: string[]): Promise<Farm[]> {
    const endpoint = `${API_URL}/internal/farms`;
    const params = new URLSearchParams();

    params.append("farmIds", ids.join(","));

    const { data } = await axios.get<FarmsPage>(endpoint, {
      params,
    });
    const farms = FarmSchema.array().parse(data.farms);

    return farms;
  }

  public async saveTask(data: TaskPayload): Promise<void> {
    const endpoint = `${API_URL}/internal/tasks`;

    await axios.post(endpoint, data);
  }

  public async fetchFarmStands(farmId: string, date?: Date): Promise<FarmGeometries> {
    const endpoint = `${API_URL}/internal/farms/${farmId}/geometries`;
    const params = new URLSearchParams();
    params.append("types", "STAND");

    if (date) params.append("deletedAfter", date.toISOString());
    const response = await axios.get<FarmGeometries>(endpoint, { params });

    return response.data;
  }
}

export default new FarmsService();
