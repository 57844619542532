import axios, { AxiosResponse } from "axios";
import { ICredentials, IUserWithToken } from "@/@types/login";

const loginService = {
  async me(): Promise<IUserWithToken> {
    const endpoint = `${process.env.VUE_APP_IAM_API}/users/me`;
    const response = await axios.get<IUserWithToken>(endpoint);

    return response.data;
  },
  async signIn(credentials: ICredentials): Promise<IUserWithToken> {
    const response: AxiosResponse<IUserWithToken> = await axios.post(
      `${process.env.VUE_APP_IAM_API}/users/login`,
      credentials,
    );

    return response.data;
  },
};

export default loginService;
