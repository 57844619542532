export enum SNACKBAR_COLOR {
  SUCCESS = "success",
  ERROR = "red darken-1 pa-3",
  WARNING = "warning darken-2",
  INFO = "info",
}

export enum SNACKBAR_POSITION {
  BOTTOM = "bottom",
  LEFT = "left",
  RIGHT = "right",
  TOP = "top",
}

export interface SnackbarPayload {
  color: SNACKBAR_COLOR;
  position: SNACKBAR_POSITION;
  text: string;
  show: boolean;
  timeout?: number;
}
