import Vuex, { ActionTree, GetterTree, MutationTree } from "vuex";

import analyst from "@/store/analyst";
import client from "@/store/client";
import { debtorAnalyses } from "@/store/debtorAnalyses";
import farmRegistrationStore from "@/store/farmRegistration";
import files from "@/store/files";
import imageService from "@/store/images";
import { legalEntity } from "@/store/legalEntity";
import login from "@/store/login";
import manualAnalysis from "@/store/manual-analysis";
import manualAnalysisPanel from "@/store/manual-analysis-panel";
import monitoring from "@/store/monitoring";
import onboarding from "@/store/onboarding";
import operation from "@/store/operation";
import { prefin } from "@/store/prefin";
import { RootState } from "@/@types/vuex";
import user from "@/store/user";
import Vue from "vue";

Vue.use(Vuex);

const state: RootState = {
  countPendingRequests: 0,
};
const getters: GetterTree<RootState, RootState> = {
  isLoading: (rootState: RootState): boolean => rootState.countPendingRequests > 0,
};
const actions: ActionTree<RootState, RootState> = {};
const mutations: MutationTree<RootState> = {
  LOADED(rootState: RootState) {
    if (rootState.countPendingRequests !== 0) {
      rootState.countPendingRequests--;
    }
  },
  LOADING(rootState: RootState) {
    rootState.countPendingRequests++;
  },
};

export default new Vuex.Store<RootState>({
  actions,
  getters,
  modules: {
    analyst,
    client,
    debtorAnalyses,
    farmRegistrationStore,
    files,
    imageService,
    legalEntity,
    login,
    manualAnalysis,
    manualAnalysisPanel,
    monitoring,
    onboarding,
    operation,
    prefin,
    user,
  },
  mutations,
  state,
});
