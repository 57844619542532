export const fillObject = <T extends string, S>(keys: Array<T>, defaultValue: S) => {
  const fillReducer = (acc: Record<T, S>, key: T) => {
    acc[key] = defaultValue;
    return acc;
  };

  return keys.reduce(fillReducer, {} as Record<T, S>);
};

export const isObjectEmpty = (x: object): boolean => {
  for (const _ in x) return false;
  return true;
};

export const removeEmptyStringValuesFromObject = <T extends object>(
  obj: T,
): Partial<T> => {
  const cleanObj: Partial<T> = {};
  for (const [k, v] of Object.entries(obj)) if (v !== "") cleanObj[k as keyof T] = v;
  return cleanObj;
};

export const getKeyByValue = <T extends object>(
  obj: T,
  value: unknown,
): keyof T | undefined => {
  return Object.keys(obj).find((key) => (obj as any)[key] === value) as keyof T;
};
