// Front-end RequestType enum
export const enum RequestType {
  PRE_FIN = "PRE_FIN",
  OPERATION = "OPERATION",
}

export enum RequestTypeTranslate {
  OPERATION = "Operação",
  PRE_FIN = "Pré financiamento",
}

export enum OnboardingFieldName {
  documentType = "Tipo de documento",
  documentNumber = "Código do documento",
  dueDate = "Data de vencimento",
  season = "Safra - Ano",
  cropType = "Cultura financiada",
  financedArea = "Área total a ser financiada (ha)",
  amount = "Quantidade total a ser recebida",
  currency = "Unidade dos recebíveis",
  producerIdentityNumber = "CPF ou CNPJ do produtor",
  producerName = "Nome do produtor",
}

export const DEFAULT_EMPTY_SUMMARY = { byTab: {}, total: 0 };
