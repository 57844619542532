// The type of `E` where `E` is a enum class.
//
// Used when we need to pass the enum class itself as a value.
export type EnumType<E> = Record<keyof E, any>;

// The type of a translation enum class.
export type Translation<E> = Record<keyof E, string>;

export const translateEnum = <E>(
  raw: keyof E,
  translation: Translation<E>,
  errorMessage: string = "BAD_RAW_VALUE",
): string => {
  if (!raw) return "";

  const translated = translation[raw];
  if (!translated) throw new Error(`${errorMessage} for ${String(raw)}`);
  return translated;
};
