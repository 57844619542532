import { IFarm, IFarmGeometriesWithStands } from "@/@types/monitoring";
import { MonitoringState, RootState } from "@/@types/vuex";

import { ActionTree } from "vuex";
import { IMonitoringOperation } from "@/@types/operation";
import { MonitoringMutation } from "./mutations";
import monitoringService from "@/services/monitoring";

const actions: ActionTree<MonitoringState, RootState> = {
  async getFarmById(_, farmId: number): Promise<IFarm> {
    return await monitoringService.getFarmById(farmId);
  },
  async getGeometriesByFarmId(
    { commit },
    farmId: number,
  ): Promise<IFarmGeometriesWithStands> {
    const geometries = await monitoringService.getGeometriesByFarmId(farmId);
    commit(MonitoringMutation.SET_GEOMETRIES, geometries);
    return geometries;
  },
  async getOperationById(_, operationId: number): Promise<IMonitoringOperation> {
    return await monitoringService.getOperationById(operationId);
  },
};

export default actions;
