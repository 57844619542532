import { ISeason } from "@/@types/onboarding/shared";

export const generateClientSideId = <T>(array: T[]): string => {
  const length = array.length;
  if (!length) {
    return "1";
  }
  return (length + 1).toString();
};

export enum Masks {
  CNPJ_MASK = "##.###.###/####-##",
  CPF_MASK = "###.###.###-##",
}

export const toKebabCase = (value: string): string => {
  return value.toLowerCase().replace(/_/g, "-");
};

export const pluralize = (
  amount: number,
  singleTerm: string,
  pluralTerm?: string,
): string => {
  return amount > 1 ? pluralTerm || `${singleTerm}s` : singleTerm;
};

export const arrayToString = (terms: string[]): string => {
  if (!terms.length) return "";
  if (terms.length === 1) return terms[0];

  const init = [...terms].splice(0, terms.length - 1);
  const last = terms[terms.length - 1];

  return `${init.join(", ")} e ${last}`;
};

export const wordCompare = (first: string, last: string): number => {
  return first.toLowerCase().localeCompare(last.toLowerCase());
};

export const replaceMessageValues = (message: string, values: string[]) => {
  return values.reduce((message, value, index, values) => {
    const replaceKey = values.length === 1 ? "{VALUE}" : `{VALUE${index + 1}}`;
    return message.replace(replaceKey, value);
  }, message);
};

export const seasonStringToSeason = (
  seasonString?: string,
  separator = ";",
): ISeason | undefined => {
  const [crop, year] = (seasonString || "").split(separator).map((str) => str.trim());
  if (!crop || !year) return undefined;
  return { crop, year: parseInt(year) } as ISeason;
};

export const seasonToSeasonString = (season?: ISeason | null): string | undefined => {
  if (!season?.crop || !season?.year) return undefined;
  return `${season.crop};${season.year}`;
};

// TODO: Change defaults to NULL_STRING and DASH_SEPARATOR_STRING when we solve Jest circular import issue
export const joinWithSeparator = (
  values: string[],
  emptyValue = "―",
  separator = " - ",
): string => {
  const filteredValues = values.filter(Boolean);
  if (!filteredValues.length) return emptyValue;
  return filteredValues.join(`${separator}`);
};

export const normalizeString = (str: string | undefined) => {
  if (!str) {
    return "";
  }

  return str
    .toLowerCase()
    .normalize()
    .replace(/[\u0300-\u036f]/g, "");
};

export const normalizeStringNfd = (str: string | undefined) => {
  if (!str) {
    return "";
  }

  return str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
};
