import actions from "@/store/client/actions";
import getters from "@/store/client/getters";
import mutations from "@/store/client/mutations";
import state from "@/store/client/state";

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
