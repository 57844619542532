import actions from "@/store/monitoring/actions";
import getters from "@/store/monitoring/getters";
import mutations from "@/store/monitoring/mutations";
import state from "@/store/monitoring/state";

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
