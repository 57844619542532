




















import { SNACKBAR_COLOR, SNACKBAR_POSITION, SnackbarPayload } from "@/@types/snackbar";

import EventBus from "@/buses";
import Vue from "vue";

const DEFAULT_SNACKBAR_SHOW_TIME = 4_000;

interface ISnackbarState {
  text?: string;
  top?: boolean;
  left?: boolean;
  bottom?: boolean;
  right?: boolean;
  multiLine?: boolean;
  vertical?: boolean;
  timeout?: number;
  color: string;
  closable?: boolean;
  visible?: boolean;
}

export default Vue.extend({
  computed: {
    snackbarShowTime(timer: number = DEFAULT_SNACKBAR_SHOW_TIME): number {
      return timer;
    },
  },
  data() {
    return {
      DEFAULT_SNACKBAR_SHOW_TIME,
      snackbar: {
        closable: true,
        color: SNACKBAR_COLOR.INFO,
        text: "",
        visible: false,
      } as ISnackbarState,
    };
  },
  methods: {
    showSnackBar(
      text: string,
      color: SNACKBAR_COLOR,
      timeout: number = DEFAULT_SNACKBAR_SHOW_TIME,
      position = SNACKBAR_POSITION.BOTTOM,
      visible = true,
    ) {
      this.snackbar = {
        bottom: position === SNACKBAR_POSITION.BOTTOM,
        color,
        left: position === SNACKBAR_POSITION.LEFT,
        right: position === SNACKBAR_POSITION.RIGHT,
        text,
        timeout,
        top: position === SNACKBAR_POSITION.TOP,
        visible,
      };
    },
  },
  mounted() {
    EventBus.$on(
      "SHOW_SNACKBAR",
      ({ color, position, show, text, timeout }: SnackbarPayload) =>
        this.showSnackBar(text, color, timeout, position, show),
    );
    EventBus.$on("SHOW_ERROR_SNACKBAR", (text: string, timeout?: number) =>
      this.showSnackBar(text, SNACKBAR_COLOR.ERROR, timeout),
    );
    EventBus.$on("SHOW_SUCCESS_SNACKBAR", (text: string, timeout?: number) =>
      this.showSnackBar(text, SNACKBAR_COLOR.SUCCESS, timeout),
    );
    EventBus.$on("SHOW_WARNING_SNACKBAR", (text: string, timeout?: number) =>
      this.showSnackBar(text, SNACKBAR_COLOR.WARNING, timeout),
    );
    EventBus.$on("SHOW_INFO_SNACKBAR", (text: string, timeout?: number) =>
      this.showSnackBar(text, SNACKBAR_COLOR.INFO, timeout),
    );
  },
  name: "AppSnackbar",
});
