var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      staticClass: "app-snackbar",
      attrs: {
        color: _vm.snackbar.color,
        timeout: _vm.snackbar.timeout,
        "multi-line": _vm.snackbar.multiLine,
        bottom: _vm.snackbar.bottom,
        left: _vm.snackbar.left,
        right: _vm.snackbar.right,
        top: _vm.snackbar.top,
        vertical: _vm.snackbar.vertical,
      },
      model: {
        value: _vm.snackbar.visible,
        callback: function ($$v) {
          _vm.$set(_vm.snackbar, "visible", $$v)
        },
        expression: "snackbar.visible",
      },
    },
    [
      _vm._v(" " + _vm._s(_vm.snackbar.text) + " "),
      _c(
        "v-btn",
        {
          attrs: { text: "", small: "", flat: "", title: "Fechar" },
          on: {
            click: function ($event) {
              _vm.snackbar.visible = false
            },
          },
        },
        [_c("v-icon", [_vm._v("close")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }