import { enumCssColors, enumCssColorsRgb } from "@/utils/color";

export enum ChartType {
  LINE = "line",
  BAR = "bar",
  HORIZONTAL_BAR = "horizontalBar",
  RADAR = "radar",
  DOUGHNUT = "doughnut",
  POLARAREA = "polarArea",
  BUBBLE = "bubble",
  PIE = "pie",
  SCATTER = "scatter",
}

export enum TooltipPosition {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
  TOP = "top",
  BOTTOM = "bottom",
}

export enum ChartAddons {
  LOADING_CHART_WARNING = "LOADING_CHART_WARNING",
  LOAD_ERROR_CHART_WARNING = "LOAD_ERROR_CHART_WARNING",
}

export enum ChartAddonsColors {
  LOADING_CHART_WARNING = "#CCCCCC",
  LOAD_ERROR_CHART_WARNING = "#265987",
}

export const CSS_STYLE_CHART_WARNING_COLORS: string =
  enumCssColors(ChartAddons, ChartAddonsColors, "chart-addon") +
  enumCssColorsRgb(ChartAddons, ChartAddonsColors, "chart-addon");

export enum GenericChartCustomColors {
  NO_COLOR = "",
  BLUE = "blue",
}
