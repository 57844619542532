import { actions } from "@/store/farmRegistration/actions";
import { getters } from "@/store/farmRegistration/getters";
import { mutations } from "@/store/farmRegistration/mutations";
import { state } from "@/store/farmRegistration/state";

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
