import {
  FileMetadataPayload,
  FilesMetadataPayload,
  IFile,
  IFileDownload,
  IFileDownloadZip,
} from "@/@types/files";
import { FileState, RootState } from "@/@types/vuex";

import { ActionTree } from "vuex";
import { FileMutation } from "./mutations";
import fileService from "@/services/files";

const actions: ActionTree<FileState, RootState> = {
  async downloadBlobFilesById(
    { dispatch },
    filesPayload: FilesMetadataPayload,
  ): Promise<IFileDownloadZip[]> {
    const files: IFile[] = await dispatch("getFilesInfoByClientId", filesPayload);

    return Promise.all(
      files.map(async (file) => {
        const content: Blob = await dispatch("downloadFileBlobByClientId", {
          clientId: filesPayload.clientId,
          file,
        });

        const [fileName, extension] = file.name.split(".");
        return { content, extension, fileName };
      }),
    );
  },
  downloadFile({}, file: IFile): Promise<IFileDownload> {
    return fileService.downloadFile(file);
  },
  async downloadFileBlobByClientId(
    {},
    args: {
      file: IFile;
      clientId?: string;
    },
  ): Promise<Blob> {
    return fileService.downloadFileBlobByClientId(args.file, args.clientId);
  },
  async downloadFilesZip(
    {},
    payload: {
      filename: string;
      filesId: string[];
      clientId: string;
    },
  ): Promise<IFileDownload> {
    return fileService.downloadFilesZip(
      payload.filename,
      payload.filesId,
      payload.clientId,
    );
  },
  async getFileInfo({ commit, state }, fileId: string): Promise<IFile> {
    const file = state.filesInfo[fileId];
    if (file) return file;

    const requestedFile = await fileService.getFileInfo(fileId);
    commit(FileMutation.ADD_FILES_INFO, [requestedFile]);
    return requestedFile;
  },
  async getFileInfoByClientId(
    { commit, state },
    filesPayload: FileMetadataPayload,
  ): Promise<IFile> {
    const file = state.filesInfo[filesPayload.fileId];
    if (file) return file;

    const requestedFile = await fileService.getFileInfoByClientId(filesPayload);
    commit(FileMutation.ADD_FILES_INFO, [requestedFile]);
    return requestedFile;
  },
  async getFilesInfo({ commit, state }, fileIds: string[]): Promise<IFile[]> {
    const fileIdsToRequest = fileIds.filter((id) => !state.filesInfo[id]);
    const newFilesInfo = await fileService.getFilesInfo(fileIdsToRequest);
    commit(FileMutation.ADD_FILES_INFO, newFilesInfo);

    return fileIds.map((id) => state.filesInfo[id]).filter(Boolean);
  },
  async getFilesInfoByClientId(
    { commit, state },
    filesPayload: FilesMetadataPayload,
  ): Promise<IFile[]> {
    const { fileIds: requestedFileIds } = filesPayload;

    const fileIds = requestedFileIds.filter((id) => !state.filesInfo[id]);
    const filesToRequest = { ...filesPayload, fileIds };
    const newFilesInfo = await fileService.getFilesInfoByClientId(filesToRequest);
    commit(FileMutation.ADD_FILES_INFO, newFilesInfo);

    return requestedFileIds.map((id) => state.filesInfo[id]).filter(Boolean);
  },
};

export default actions;
