import axios, { AxiosResponse } from "axios";
import { INewUser, IUser, IUserPatch } from "@/@types/user";
import { PartialBy } from "@/@types/utils";

const IAM_API_URL = `${process.env.VUE_APP_IAM_API}`;
const usersService = {
  async createUser(user: INewUser): Promise<IUser> {
    const response: AxiosResponse<IUser> = await axios.post(
      `${IAM_API_URL}/users`,
      user,
    );
    return response.data;
  },
  async deleteUser(userId: string): Promise<void> {
    await axios.delete(`${IAM_API_URL}/users/${userId}`);
  },
  async editUser(userId: string, patch: IUserPatch): Promise<IUser> {
    const data: PartialBy<IUserPatch, "id"> = patch;
    delete data["id"];
    const response: AxiosResponse<IUser> = await axios.patch(
      `${IAM_API_URL}/users/${userId}`,
      patch,
    );
    return response.data;
  },
  async getAllUsers(): Promise<IUser[]> {
    const response: AxiosResponse<{ users: IUser[] }> = await axios.get(
      `${IAM_API_URL}/internal/users`,
    );
    return response.data.users;
  },
  async getTmUsers(): Promise<IUser[]> {
    const response: AxiosResponse<{ users: IUser[] }> = await axios.get(
      `${IAM_API_URL}/users`,
    );
    return response.data.users;
  },
};

export default usersService;
