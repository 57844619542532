const localStorageInWindow = (): boolean => "localStorage" in window;

export const localStorageGet = (key: string): string | null => {
  if (!localStorageInWindow()) return null;
  return window.localStorage.getItem(key);
};

export const localStorageSet = (key: string, value: string): boolean => {
  if (!localStorageInWindow()) return false;
  window.localStorage.setItem(key, value);
  return true;
};

export const localStorageRemove = (key: string): boolean => {
  if (!localStorageInWindow()) return false;
  window.localStorage.removeItem(key);
  return true;
};

export default {
  get: localStorageGet,
  remove: localStorageRemove,
  set: localStorageSet,
};
