import * as Sentry from "@sentry/vue";
import { localStorageGet } from "@/utils/localStorage";
import { Role } from "@/constants/shared/role";
import { User } from "@sentry/vue";

class NoUser extends Error {
  message = "No user data was found";
}

interface Division {
  id: string;
  name: string;
  users_count: number;
}

const LOCAL_STORAGE_KEY = "user_data";

export type ICurrentUserData = Readonly<
  User & { token: string; id: string; client_id: string; email: string }
>;

export class CurrentUser {
  private static _instance: CurrentUser | undefined = undefined;

  public static load(): CurrentUser {
    if (CurrentUser._instance) return CurrentUser._instance;
    const userDataLocalStorage = localStorageGet(LOCAL_STORAGE_KEY);

    if (userDataLocalStorage === null) {
      throw NoUser;
    } else {
      const userData = JSON.parse(userDataLocalStorage);

      CurrentUser._instance = new CurrentUser(
        userData.token,
        userData.email,
        userData.name,
        userData.roles,
        userData.id,
        userData.clientId,
        userData.division,
      );

      Sentry.setUser({
        email: userData.email,
        id: userData.id,
        name: userData.name,
      });

      return CurrentUser._instance;
    }
  }

  constructor(
    private _token: string,
    private _email: string,
    private _name: string,
    private _roles: Role[],
    private _id: string,
    private _clientId: string,
    private _division?: Division,
  ) {}

  get id(): string {
    return this._id;
  }

  get token(): string {
    return this._token;
  }

  get name(): string {
    return this._name;
  }

  get clientId(): string {
    return this._clientId;
  }

  get email(): string {
    return this._email;
  }

  get division(): Division | undefined {
    return this._division;
  }

  get hasDivision(): boolean {
    return !!this._division;
  }

  get isAdmin(): boolean {
    // When a user doesn't have a division we assume that he is an administrator.
    return !this._division;
  }

  /**
   * This function validates if a user has at least one of the given roles.
   *
   * @param roles Roles that make the user allowed to access a given resource
   * @returns `true` if the user has at least one of the given roles
   */
  public isAllowed(roles: Role[]): boolean {
    if (!roles.length) return true;
    return roles.some((role) => this._roles.includes(role));
  }

  public static async save(userData: ICurrentUserData) {
    CurrentUser._instance = new CurrentUser(
      userData.token,
      userData.email,
      userData.name,
      userData.roles,
      userData.id,
      userData.client_id,
      userData.division,
    );

    Sentry.setUser({
      email: userData.email,
      id: userData.id,
      name: userData.name,
    });
  }
}
