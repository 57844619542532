import {
  IDelimitationConclusion,
  IDelimitationGeoJsonErrors,
  IExecutionTaskPayload,
} from "@/@types/manualAnalysis/analysis";
import { ManualAnalysisState, RootState } from "@/@types/vuex";
import { GetterTree } from "vuex";
import { PaginationItemsSize } from "@/constants/pagination";

const getters: GetterTree<ManualAnalysisState, RootState> = {
  delimitationAnalysis: (state: ManualAnalysisState): IDelimitationConclusion => {
    return state.delimitation.analysis;
  },
  delimitationErrors: (state: ManualAnalysisState): IDelimitationGeoJsonErrors => {
    return state.delimitation.errors;
  },
  getChangedAnalysis: (
    state: ManualAnalysisState,
  ): Pick<IExecutionTaskPayload, "changedAnalyses"> => {
    const { changedAnalyses } = state;
    return { changedAnalyses };
  },
  getListItemsSize: (state: ManualAnalysisState): PaginationItemsSize => {
    return state.chartListItemsSize;
  },
  getUnchangedCharts: (state: ManualAnalysisState): number[] => {
    return state.unchangedCharts;
  },
};

export default getters;
