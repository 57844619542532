export enum RefusalReason {
  DUPLICATED_REQUEST = "DUPLICATED_REQUEST",
  REGISTRATION_ERROR = "REGISTRATION_ERROR",
  UNIFIED_REQUEST = "UNIFIED_REQUEST",
}

const REFUSAL_REASON_TRANSLATIONS: Record<RefusalReason, string> = {
  DUPLICATED_REQUEST: "Pedido duplicado",
  REGISTRATION_ERROR: "Erro de cadastro",
  UNIFIED_REQUEST: "Pedido unificado",
};

export function translateRefusalReson(reason: RefusalReason): string {
  return REFUSAL_REASON_TRANSLATIONS[reason];
}
