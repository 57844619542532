import {
  LegalEntity,
  NewRelationship,
  RelatedLegalEntity,
} from "@/services/legalEntity/schemas/legalEntity";
import { ActionTree } from "vuex";
import { LegalEntityMutation } from "./mutations";
import { legalEntityService } from "@/services/legalEntity";
import { LegalEntityState } from "@/store/legalEntity/state";
import { RootState } from "@/@types/vuex";

export const actions: ActionTree<LegalEntityState, RootState> = {
  async createLegalEntityRelationships(
    { commit },
    payload: { ownerId: string; relationships: NewRelationship[] },
  ): Promise<RelatedLegalEntity[]> {
    const createdRelationships =
      await legalEntityService.internalCreateLegalEntityRelationships(
        payload.ownerId,
        payload.relationships,
      );

    commit(LegalEntityMutation.STORE_ENTITIES, createdRelationships.relationships);
    return createdRelationships.relationships;
  },

  async fetchAllLegalEntities({ commit, state }): Promise<Map<string, LegalEntity>> {
    if (state.entities.size === 0) {
      const { legalEntities } =
        await legalEntityService.internalFetchAllLegalEntities();
      commit(LegalEntityMutation.STORE_ENTITIES, legalEntities);
      return state.entities;
    }

    return state.entities;
  },

  async getEntityById({ commit, state }, id: string): Promise<LegalEntity | undefined> {
    if (state.entities.get(id)) return state.entities.get(id);

    const entity = await legalEntityService.internalFetchLegalEntityById(id);
    commit(LegalEntityMutation.STORE_ENTITIES, [entity]);

    return state.entities.get(id);
  },

  async getRelatedLegalEntity(
    {},
    relationshipFilter: { nationalRegistration: string; ownerId: string },
  ): Promise<RelatedLegalEntity | undefined> {
    const relatedLegalEntity = await legalEntityService.internalFetchRelatedLegalEntity(
      relationshipFilter,
    );

    return relatedLegalEntity;
  },
};
