export enum CurrencyType {
  REAL = "REAL",
  USD = "USD",
  SACK = "SACK",
  ARROBA = "ARROBA",
  KILOGRAM = "KILOGRAM",
  TONNE = "TONNE",
  CUBIC_METER = "CUBIC_METER",
  PERCENTAGE = "PERCENTAGE",
  DECIMAL = "DECIMAL",
}

export enum CurrencyTypeTranslate {
  USD = "Dólar",
  REAL = "Real",
  ARROBA = "Arroba",
  KILOGRAM = "Quilograma",
  SACK = "Saca",
  TONNE = "Tonelada",
  CUBIC_METER = "Metro cúbico",
  PERCENTAGE = "Porcentagem",
  DECIMAL = "Decimal",
}

export enum FinancialCurrency {
  USD = CurrencyType.USD,
  REAL = CurrencyType.REAL,
}

export enum PhysicalCurrency {
  ARROBA = CurrencyType.ARROBA,
  KILOGRAM = CurrencyType.KILOGRAM,
  SACK = CurrencyType.SACK,
  TONNE = CurrencyType.TONNE,
  CUBIC_METER = CurrencyType.CUBIC_METER,
}

export enum DocumentType {
  CPR = "CPR",
  CPRF = "CPRF",
  // CCB = "CCB", For now this type of document is registered in batch mode via scripts.
}

// In a Timeline, the SAFRINHA happens before SAFRA of the same year
export enum SeasonCropType {
  SAFRINHA = "SAFRINHA",
  SAFRA = "SAFRA",
}

export enum SeasonCropTypeTranslate {
  SAFRINHA = "Safrinha",
  SAFRA = "Safra",
}
