import axios from "axios";
import { FetchDebtorAnalysisResponse } from "@/@types/debtorAnalyses";

const API = process.env.VUE_APP_DEBTOR_ANALYSES_API as string;

const debtorAnalysesService = {
  async internalFetchAnalysesByClientId(
    clientId: string,
  ): Promise<FetchDebtorAnalysisResponse> {
    const url = `${API}/internal/analyses`;

    const response = await axios.get(url, { params: { clientId } });
    return response.data;
  },
};

export default debtorAnalysesService;
