// These imports have to specific to prevent circular import issues
import { EnumType, translateEnum, Translation } from "./translate";
import { toKebabCase } from "./string";

export const enumCssColors = <C>(
  rawEnum: EnumType<C>,
  colorsEnum: Translation<C>,
  classPrefix: string,
): string => {
  return Object.keys(rawEnum)
    .map((key) => {
      const className = `--${classPrefix}-color-${toKebabCase(key)}`;
      const hexColor = translateEnum(
        key as keyof C,
        colorsEnum,
        "COLOR_ENUM_KEY_MISMATCH",
      );

      return `${className}: ${hexColor};`;
    })
    .join("");
};

export const enumCssColorsRgb = <C>(
  rawEnum: EnumType<C>,
  colorsEnum: Translation<C>,
  classPrefix: string,
): string => {
  return Object.keys(rawEnum)
    .map((key) => {
      const className = `--${classPrefix}-color-${toKebabCase(key)}-rgb`;
      const hexColor = translateEnum(
        key as keyof C,
        colorsEnum,
        "COLOR_ENUM_KEY_MISMATCH",
      );
      const rgbColor = hexColorToRgbValue(hexColor);

      return `${className}: ${rgbColor};`;
    })
    .join("");
};

// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export const hexColorToRgbValue = (hexColor: string): string | null => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex: RegExp = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const hex = hexColor.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result: RegExpExecArray | null =
    /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16,
      )}`
    : null;
};
