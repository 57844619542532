import { IAreas, IFarm, IFarmGeometries, ILocation } from "@/@types/monitoring";
import { GeometryType } from "@/@types/geoJson";
import { IDebtor } from "@/@types/operation";
import { MonitoringState } from "@/@types/vuex";
import { State } from "@/constants/shared/location";

const owner_name: IDebtor = {
  cpf_cnpj: "",
  name: "",
};

const location: ILocation = {
  municipality: "",
  state: State.AL,
};

const areas: IAreas = {
  available: 0,
  funded_area: 0,
  other_uses: 0,
  reserve: 0,
};

const farm: IFarm = {
  areas: areas,
  cartography_id: "",
  created_at: "",
  id: 0,
  location: location,
  name: "",
  operation_id: 0,
  owner_name: owner_name,
  pretty_name: "",
  registry: "",
  std_name: "",
  updated_at: "",
};

const farmGeometries: IFarmGeometries = {
  perimeter: {
    coordinates: [],
    type: GeometryType.MULTI_POLYGON,
  },
};

export default {
  farm,
  farmGeometries,
} as MonitoringState;
