import { CropType } from "@/constants/shared/crop";
import { DevelopmentStage } from "@/constants/shared/developmentStage";

export enum FeatureType {
  FEATURE_COLLECTION = "FeatureCollection",
  FEATURE = "Feature",
}

export enum GeometryType {
  POINT = "Point",
  LINE = "Line",
  LINE_STRING = "LineString",
  POLYGON = "Polygon",
  MULTI_POINT = "MultiPoint",
  MULTI_LINE_STRING = "MultiLineString",
  MULTI_POLYGON = "MultiPolygon",
  GEOMETRY_COLLECTION = "GeometryCollection",
}

export type Point = [number, number, number?];
export type Polygon = Point[];
export type MultiPolygon = Polygon[];
export type FeatureProperties = any | null;

export type Geometry = {
  readonly type: GeometryType;
  readonly coordinates: MultiPolygon[] | MultiPolygon | Polygon | Point;
};

// https://app.swaggerhub.com/apis/TerraMagna/monitoring-service/v3.2#/Feature
export type Feature<PROPERTIES_TYPE = any | null> = {
  readonly id: number;
  readonly type: FeatureType.FEATURE;
  readonly geometry: Geometry;
  readonly properties: PROPERTIES_TYPE;
};

// https://app.swaggerhub.com/apis/TerraMagna/monitoring-service/v3.2#/FeatureCollection
export type FeatureCollection<FEATURE_TYPE = Feature> = {
  readonly name?: string;
  readonly features: FEATURE_TYPE[];
  readonly type: FeatureType.FEATURE_COLLECTION;
};

export type GeometryCollection = {
  readonly type: GeometryType.GEOMETRY_COLLECTION;
  readonly geometries: Geometry[];
};

export interface IDevelopmentMapFeatureProperties {
  readonly stand_id: string;
  readonly vegetative_state: DevelopmentStage;
  readonly crop_identified: CropType;
  readonly total_area: number;
}

export interface IDevelopmentMapFeature
  extends Feature<IDevelopmentMapFeatureProperties> {}

export interface IMapBoxFeatureProperties {
  readonly description: string;
}

export interface IMapBoxFeature extends Feature<IMapBoxFeatureProperties> {}

// https://app.swaggerhub.com/apis/TerraMagna/monitoring-service/v3.2#/GeometryCollection
export interface IGeometryCollection extends GeometryCollection {
  readonly coordinates?: MultiPolygon[] | MultiPolygon | Polygon | Point;
}

export interface ICentroid {
  readonly coordinates: [number, number];
  readonly type: GeometryType.POINT;
}
