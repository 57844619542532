import {
  ButtonType,
  ButtonTypeIconTranslation,
  ButtonTypeTooltipTranslation,
} from "@/constants/shared/button";
import { CropType, CropTypeTranslate } from "@/constants/shared/crop";
import {
  CurrencyType,
  CurrencyTypeTranslate,
  SeasonCropType,
  SeasonCropTypeTranslate,
} from "@/constants/shared/financial";
import {
  DateOption,
  DateOptionTranslate,
} from "@/constants/manual-analysis-panel/filterParams";
import {
  Eligibility,
  EligibilityTranslation,
  RefusalReason,
  RefusalReasonTranslation,
  RelationshipTime,
  RelationshipTimeTranslation,
} from "@/@types/debtorAnalyses";
import {
  GeoJsonErrors,
  GeoJsonErrorsTranslate,
} from "@/constants/shared/perimeterIdentification";
import {
  GeoJsonFileName,
  RefusalType,
  RefusalTypeTranslate,
} from "@/constants/manual-analysis/delimitation";
import { ImageColor, ImageColorTranslate } from "@/constants/manual-analysis/image";
import {
  OperationCancelReason,
  OperationCancelReasonTranslate,
  PreFinCancelReason,
  PreFinCancelReasonTranslate,
} from "@/constants/shared/cancelReason";
import {
  PerimeterIdentification,
  PerimeterIdentificationTranslate,
} from "@/constants/shared/perimeterIdentification";
import {
  RequestStatus,
  RequestStatusTranslate,
} from "@/constants/shared/requestStatus";
import { ResultsType, ResultsTypeTranslate } from "@/constants/shared/resultsType";
import { RiskType, RiskTypeTranslate } from "@/constants/shared/riskType";
import { Role, RoleTranslate } from "@/constants/shared/role";
import {
  TaskPriority,
  TaskPriorityTranslate,
  TaskStatus,
  TaskStatusTranslate,
  TaskType,
  TaskTypeTranslate,
} from "@/constants/manual-analysis/shared";
import { FarmGeometryKeys } from "@/constants/manual-analysis/delimitation";
import { replaceMessageValues } from "./string";
import { translateEnum } from "./translate";

export const cropTypeTranslate = (raw: CropType): string => {
  return translateEnum(raw, CropTypeTranslate, "BAD_CROP_TYPE");
};

export const seasonCropTypeTranslate = (raw: SeasonCropType): string => {
  return translateEnum(raw, SeasonCropTypeTranslate, "BAD_SEASON_CROP_TYPE");
};

export const currencyTypeTranslate = (raw: CurrencyType): string => {
  return translateEnum(raw, CurrencyTypeTranslate, "BAD_CURRENCY_TYPE");
};

export const imageColorTranslate = (raw: ImageColor): string => {
  return translateEnum(raw, ImageColorTranslate, "BAD_IMAGE_COLOR");
};

export const dateOptionTranslate = (raw: DateOption): string => {
  return translateEnum(raw, DateOptionTranslate, "BAD_DATE_OPTION");
};

export const taskTypeTranslate = (raw: TaskType): string => {
  return translateEnum(raw, TaskTypeTranslate, "BAD_TASK_TYPE");
};

export const perimeterIdentificationTranslate = (
  raw: PerimeterIdentification,
): string => {
  return translateEnum(
    raw,
    PerimeterIdentificationTranslate,
    "BAD_PERIMETER_IDENTIFICATION",
  );
};

export const resultsTypeTranslate = (raw: ResultsType): string => {
  return translateEnum(raw, ResultsTypeTranslate, "BAD_RESULTS_TYPE_STAGE");
};

export const risksTypeTranslate = (raw: RiskType): string => {
  return translateEnum(raw, RiskTypeTranslate, "BAD_RESULTS_TYPE_STAGE");
};

export const requestStatusTranslate = (raw: RequestStatus): string => {
  return translateEnum(raw, RequestStatusTranslate, "BAD_REQUEST_STATUS");
};

export const refusalTypeTranslate = (raw: RefusalType): string => {
  return translateEnum(raw, RefusalTypeTranslate, "BAD_REFUSAL_TYPE");
};

export const geoJsonErrorsTranslate = (raw: GeoJsonErrors): string => {
  return translateEnum(raw, GeoJsonErrorsTranslate, "BAD_GEOJSON_ERROR");
};

export const taskStatusTranslate = (raw: TaskStatus): string => {
  return translateEnum(raw, TaskStatusTranslate, "BAD_TASK_STATUS_ERROR");
};

export const buttonIconTranslate = (raw: ButtonType): string => {
  return translateEnum(raw, ButtonTypeIconTranslation, "BAD_BUTTON_TYPE_FOR_ICON");
};

export const buttonTooltipTranslate = (raw: ButtonType): string => {
  return translateEnum(
    raw,
    ButtonTypeTooltipTranslation,
    "BAD_BUTTON_TYPE_FOR_TOOLTIP",
  );
};

export const farmGeometryFileNameTranslate = (raw: FarmGeometryKeys): string => {
  type KeyOfFarmGeometryKeys = keyof typeof FarmGeometryKeys;

  const farmKeys = Object.keys(FarmGeometryKeys) as KeyOfFarmGeometryKeys[];
  const geojsonKey = farmKeys.find((key) => FarmGeometryKeys[key] === raw);

  const errorMessage = "BAD_FARM_GEOMETRY_KEY_ERROR";
  if (!geojsonKey) throw new Error(errorMessage);
  return translateEnum(geojsonKey, GeoJsonFileName, errorMessage);
};

export const STAND_GEOJSON_ERROR_MESSAGE = `Talhão {VALUE1} - {VALUE2};`;

export const standsGeoJsonErrorsTranslate = (stands: GeoJsonErrors[][]): string[] => {
  const standToError = (standErrors: GeoJsonErrors[], index: number) => {
    return standErrors.map((error) => {
      if (!error) return "";

      const values = [`${index + 1}`, `${geoJsonErrorsTranslate(error)}`];
      return replaceMessageValues(STAND_GEOJSON_ERROR_MESSAGE, values);
    });
  };

  return stands.flatMap(standToError).filter(Boolean);
};

export const taskPriorityTranslate = (raw: TaskPriority): TaskPriorityTranslate => {
  return translateEnum(
    raw,
    TaskPriorityTranslate,
    "BAD_TASK_PRIORITY_ERROR",
  ) as TaskPriorityTranslate;
};

export function operationCancelReasonTranslate(
  reason: OperationCancelReason,
): OperationCancelReasonTranslate {
  return translateEnum(
    reason,
    OperationCancelReasonTranslate,
    "BAD_OPERATION_CANCEL_REASON_ERROR",
  ) as OperationCancelReasonTranslate;
}

export function preFinCancelReasonTranslate(
  reason: PreFinCancelReason,
): PreFinCancelReasonTranslate {
  return translateEnum(
    reason,
    PreFinCancelReasonTranslate,
    "BAD_PRE_FIN_CANCEL_REASON_ERROR",
  ) as PreFinCancelReasonTranslate;
}

export const roleTranslate = (role: Role): string =>
  translateEnum(role, RoleTranslate, "BAD_ROLE");

export const debtorAnalysisEligibilityTranslate = (raw: Eligibility): string => {
  return translateEnum(raw, EligibilityTranslation, "BAD_DEBTOR_ANALYSIS_ELIGIBILITY");
};

export const debtorAnalysisRelationshipTimeTranslate = (
  raw: RelationshipTime,
): string => {
  return translateEnum(
    raw,
    RelationshipTimeTranslation,
    "BAD_DEBTOR_ANALYSIS_RELATIONSHIP_TIME",
  );
};

export const debtorAnalysisRefusalReasonTranslate = (raw: RefusalReason): string => {
  return translateEnum(
    raw,
    RefusalReasonTranslation,
    "BAD_DEBTOR_ANALYSIS_REFUSAL_REASON",
  );
};
