import {
  IAmplitudeService,
  IEventProperties,
  IEventPropertiesArgs,
  IUserProps,
} from "@/@types/amplitude";
import amplitude from "amplitude-js";

class AmplitudeService implements IAmplitudeService {
  private instance?: amplitude.AmplitudeClient = undefined;
  private userProperties?: IUserProps = undefined;
  private eventsQueue: string[] = [];

  constructor() {
    this._getAmplitudeInstance();
  }

  private _getAmplitudeInstance(): void {
    if (window.amplitude && window.amplitude.getInstance) {
      this.instance = window.amplitude.getInstance();
      this._setAmplitudeUserProperties();
      this.eventProcessQueue();
    } else {
      setTimeout(() => this._getAmplitudeInstance(), 1000);
    }
  }

  private _setAmplitudeUserProperties(): void {
    if (this.instance && this.userProperties)
      this.instance.setUserProperties(this.userProperties);
  }

  setUserProperties(userProperties: IUserProps): void {
    this.userProperties = userProperties;
    this._setAmplitudeUserProperties();
  }

  clearUserProperties(): void {
    if (this.instance) this.instance.clearUserProperties();
  }

  emitEvent(props: IEventPropertiesArgs): void {
    const { event, ...eventOptions } = props;
    this.logEvent(event, eventOptions);
  }

  logEvent(eventName: string, eventOptions?: IEventProperties): void {
    if (this.instance) {
      this.instance.logEvent(eventName, eventOptions);
    } else {
      this.eventsQueue.push(eventName);
    }
  }

  eventProcessQueue(): void {
    this.eventsQueue.forEach((event) => {
      this.logEvent(event);
    });
    this.eventsQueue = [];
  }
}

export default new AmplitudeService();
